import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCandidateUrlBuilder } from './index';
import { Candidate } from '../components/Candidates/types';

interface CopyToClipboard {
  copyCandidateToClipboard: (c: Candidate) => void;
  copyStringToClipboard: (s: string) => void;
}

export const useCopyToClipboard = (): CopyToClipboard => {
  const { composeSocialUrl, composeResumeFriendlyUrl } = useCandidateUrlBuilder();
  const { t } = useTranslation();

  const copyCandidateToClipboard = (cData: Candidate) => {
    if (cData) {
      const technologies = cData.technologies
        .filter(s => s.technologyId)
        .map(tech => {
          return `${tech.description} ${tech.levelName && `- ${tech.levelName}`} ${
            tech.experienceYears ? `- ${tech.experienceYears} ${t('years')}` : ''
          }`;
        })
        .join('\n');

      const languages = cData.languages.map(lang => {
        return `${lang.description} ${lang.levelName && `- ${lang.levelName}`}`;
      });

      const softSkills = Object.entries(cData.softSkills || {})
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${t(key)}: ${value}`)
        .join(', ');

      const info = [
        { label: t('name'), data: `${cData.firstName} ${cData.lastName}` },
        { label: t('countryOfResidence'), data: cData?.address?.countryName },
        { label: t('seniority'), data: cData.seniorityName },
        { label: t('experienceYears'), data: cData.yearsOfExperience },
        { label: t('mainRole'), data: cData.mainRoleName },
        { label: t('secondaryRole'), data: cData.secondaryRoleName },
        { label: t('technologies'), data: technologies },
        { label: t('email'), data: cData.email },
        { label: t('languages'), data: languages },
        { label: t('softSkills'), data: softSkills },
        { label: t('expectedSalary'), data: cData.expectedSalaryMin },
        {
          label: t('currentSalary'),
          data: cData.currentSalary && `${cData.currentSalary}${cData.currentSalaryCurrency}`,
        },
        {
          label: t('linkedinProfile'),
          data: cData.linkedInProfile && composeSocialUrl(cData.linkedInProfile, 'linkedin'),
        },
        { label: t('github'), data: cData.gitHubProfile && composeSocialUrl(cData.gitHubProfile, 'github') },
        { label: t('resumeExternalLink'), data: cData.resumeExternalLink },
        { label: t('resume'), data: cData.resumeFriendlyName && composeResumeFriendlyUrl(cData.resumeFriendlyName) },
        { label: t('comments'), data: cData.comments },
        { label: t('importantComments'), data: cData.importantComments },
      ];

      const clipboard = info.map(el => (el.data ? `${el.label}: ${el.data}` : null)).filter(el => el !== null);

      navigator.clipboard.writeText(clipboard.join('\n'));
    }

    message.info(t('candidateCopiedToClipboard'));
  };

  const copyStringToClipboard = (s: string) => {
    navigator.clipboard.writeText(s);
    message.info(t('copiedToClipboard'));
  };

  return { copyCandidateToClipboard, copyStringToClipboard };
};

import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { validateNumberInputKeyPress } from '../../utils/Input/InputFormatter';

import './css/NumberInput.css';

export type NumberInputProps = Omit<InputNumberProps<string | number>, 'step'>;

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ min, max, precision, className, placeholder, value, onChange, ...rest }, ref) => {
    return (
      <InputNumber
        placeholder={placeholder}
        ref={ref as any}
        min={min || 0}
        max={max || 99999}
        step={0}
        precision={precision || 0}
        onKeyDown={key => validateNumberInputKeyPress(key)}
        onChange={onChange}
        value={value}
        {...rest}
      />
    );
  },
);

export default NumberInput;

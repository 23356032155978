import { ReactElement } from 'react';
import { Select } from 'antd';
import { $enum } from 'ts-enum-util';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

type SelectOptionsBuilder = {
  build: <T extends Record<Extract<keyof T, string>, number>>(e: T) => Array<ReactElement>;
};

export const useSelectOptionsBuilder = (): SelectOptionsBuilder => {
  const { t } = useTranslation();

  const build = <T extends Record<Extract<keyof T, string>, number>>(e: T): Array<ReactElement> => {
    return $enum(e)
      .getEntries()
      .map(data => (
        <Option key={data[1]} value={data[1]}>
          {t(data[0].toString())}
        </Option>
      ));
  };

  return {
    build,
  };
};

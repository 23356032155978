import { useBaseAPIClient, APIRequestResult } from './index';
import { Parameter, CreateParameter, UpdateParameter } from '../../components/Parameters/types';

type ParametersAPIClient = {
  list: (type: string, filter?: string) => Promise<APIRequestResult<Array<Parameter>>>;
  listForTenant: (type: string, tenant: string, filter?: string) => Promise<APIRequestResult<Array<Parameter>>>;
  get: (type: string, id: number) => Promise<APIRequestResult<Parameter>>;
  create: (type: string, parameter: CreateParameter) => Promise<APIRequestResult<Parameter>>;
  update: (type: string, id: number, parameter: UpdateParameter) => Promise<APIRequestResult<Parameter>>;
  remove: (type: string, id: number) => Promise<APIRequestResult>;
};

export const useParametersAPIClient = (origin?: string): ParametersAPIClient => {
  const { postRequest, getRequest, deleteRequest, putRequest } = useBaseAPIClient({ origin });

  const list = (type: string, filter?: string): Promise<APIRequestResult<Array<Parameter>>> => {
    let url = `parameters/${type}`;

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<Parameter>>(url);
  };

  const listForTenant = (
    type: string,
    tenant: string,
    filter?: string,
  ): Promise<APIRequestResult<Array<Parameter>>> => {
    let url = `parameters/${type}`;

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    const requestConfig = {
      tenant,
    };

    return getRequest<Array<Parameter>>(url, requestConfig);
  };

  const get = (type: string, id: number): Promise<APIRequestResult<Parameter>> => {
    return getRequest<Parameter>(`parameters/${type}/${id}`);
  };

  const create = (type: string, parameter: CreateParameter): Promise<APIRequestResult<Parameter>> => {
    return postRequest<Parameter>(`parameters/${type}`, parameter);
  };

  const update = (type: string, id: number, parameter: UpdateParameter): Promise<APIRequestResult<Parameter>> => {
    return putRequest<Parameter>(`parameters/${type}/${id}`, parameter);
  };

  const remove = (type: string, id: number): Promise<APIRequestResult> => {
    return deleteRequest(`parameters/${type}/${id}`);
  };

  return {
    list,
    listForTenant,
    get,
    create,
    update,
    remove,
  };
};

import React, { useState, useContext, SetStateAction, Dispatch, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CandidatesStatusType } from '../components/Candidates/types';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useLoggly } from 'react-loggly-jslogger';
import { useAuth0 } from './auth/Auth0Provider';
import useRTMTenantHandler from './useRTMTenantHandler';

type SharedContextProps = {
  formHasChanges: boolean;
  setFormHasChanges: Dispatch<SetStateAction<boolean>>;
  globalFilter: string | undefined;
  setGlobalFilter: Dispatch<SetStateAction<string | undefined>>;
  enabledGlobalFilter: boolean;
  setEnabledGlobalFilter: Dispatch<SetStateAction<boolean>>;
  currentStatus: string;
  setCurrentStatus: Dispatch<SetStateAction<string>>;
  enabledLoadingOverlay: boolean;
  showOverlay: () => void;
  hideOverlay: () => void;
  previousRoute: string | undefined;
};

const sharedContext = React.createContext<SharedContextProps>({
  formHasChanges: false,
  setFormHasChanges: b => b,
  globalFilter: undefined,
  setGlobalFilter: b => b,
  enabledGlobalFilter: false,
  setEnabledGlobalFilter: b => b,
  currentStatus: '',
  setCurrentStatus: b => b,
  enabledLoadingOverlay: false,
  showOverlay: () => {},
  hideOverlay: () => {},
  previousRoute: undefined,
});

export const SharedContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { getTokenSilently, isAuthenticated } = useAuth0();
  const { error } = useLoggly();
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [currentStatus, setCurrentStatus] = useState<string>(CandidatesStatusType.all);
  const [enabledGlobalFilter, setEnabledGlobalFilter] = useState<boolean>(true);
  const [enabledLoadingOverlay, setEnabledLoadingOverlay] = useState<boolean>(false);
  const { handleRTMTenant } = useRTMTenantHandler();

  const location = useLocation();
  const [route, setRoute] = useState({
    to: '',
    from: '',
  });

  const hubConnection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_WEBSTARTED_SERVER_URL}/hubs/parameters`, {
      accessTokenFactory: () => getTokenSilently(),
    })
    .withAutomaticReconnect()
    .build();

  hubConnection.on('CleanParametersCache', args =>
    handleRTMTenant(args, data => {
      if (data) {
        data = data.toLowerCase();

        if (data === 'tenant-logo') {
          window.localStorage.removeItem(`${args.tenant}-logo-version`);
          return;
        }
        window.sessionStorage.removeItem(data.toLowerCase());
      }
    }),
  );

  useEffect(() => {
    if (!isAuthenticated) return;

    hubConnection.start().catch(err => error(err));
  }, [isAuthenticated]);

  useEffect(() => {
    setRoute(prev => ({ to: `${location.pathname}${location.search}`, from: prev.to }));
  }, [location]);

  const showOverlay = (): void => setEnabledLoadingOverlay(true);
  const hideOverlay = (): void => setEnabledLoadingOverlay(false);

  return (
    <sharedContext.Provider
      value={{
        formHasChanges,
        setFormHasChanges,
        globalFilter,
        setGlobalFilter,
        enabledGlobalFilter,
        setEnabledGlobalFilter,
        currentStatus,
        setCurrentStatus,
        enabledLoadingOverlay,
        showOverlay,
        hideOverlay,
        previousRoute: route.from,
      }}
    >
      {children}
    </sharedContext.Provider>
  );
};

export const useSharedContext = (): SharedContextProps => {
  const {
    formHasChanges,
    setFormHasChanges,
    globalFilter,
    setGlobalFilter,
    enabledGlobalFilter,
    setEnabledGlobalFilter,
    currentStatus,
    setCurrentStatus,
    enabledLoadingOverlay,
    showOverlay,
    hideOverlay,
    previousRoute,
  } = useContext(sharedContext);
  return {
    formHasChanges,
    setFormHasChanges,
    globalFilter,
    setGlobalFilter,
    enabledGlobalFilter,
    setEnabledGlobalFilter,
    currentStatus,
    setCurrentStatus,
    enabledLoadingOverlay,
    showOverlay,
    hideOverlay,
    previousRoute,
  };
};

import { ReactNode } from 'react';
import { Button, ButtonProps } from 'antd';
import './css/IconButton.css';

export interface IconButtonProps extends ButtonProps {
  icon?: ReactNode;
}

export const IconButton: React.FC<IconButtonProps> = props => {
  const { type, children, icon, className, ...rest } = props;
  return (
    <Button type={type ?? 'primary'} icon={icon} className={className ?? 'icon-button'} {...rest}>
      {children}
    </Button>
  );
};

export const PrimaryButton: React.FC<IconButtonProps> = props => {
  const { children, type, ...rest } = props;
  return (
    <IconButton type={'primary'} {...rest}>
      {children}
    </IconButton>
  );
};

export const DefaultButton: React.FC<IconButtonProps> = props => {
  const { children, type, ...rest } = props;
  return (
    <IconButton type={'default'} {...rest}>
      {children}
    </IconButton>
  );
};

export const SecondaryButton: React.FC<IconButtonProps> = props => {
  const { children, type, ...rest } = props;
  return (
    <IconButton type={'default'} className='secondary-button' {...rest}>
      {children}
    </IconButton>
  );
};

export const LinkButton: React.FC<IconButtonProps> = props => {
  const { children, type, ...rest } = props;
  return (
    <IconButton type={'link'} className='link-button' {...rest}>
      {children}
    </IconButton>
  );
};

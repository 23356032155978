import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { Auth0Provider } from './hooks/auth/Auth0Provider';

import CacheBuster from './utils/CacheBuster';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any): void => {
  // window.history.replaceState(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
  <CacheBuster>
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_CONFIG_DOMAIN}
        client_id={process.env.REACT_APP_AUTH_CONFIG_CLIENTID}
        redirect_uri={process.env.REACT_APP_AUTH_CONFIG_REDIRECTURL}
        accessType='offline'
        audience={process.env.REACT_APP_AUTH_CONFIG_AUDIENCE}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </Router>
  </CacheBuster>,
);

import React, { useCallback, useEffect, useState } from 'react';
import { Card, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDateFormatter, useErrorMessageHandler } from '../../hooks';
import { useVersionHistoryAPIClient } from '../../hooks/api';
import { EntityVersionHistory, ReferrerType } from './types';

import './css/VersionHistory.css';

interface CurrentVesionEntity {
  id: number;
  updatedByUserName: string;
}

interface VersionHistoryProps {
  referrer: CurrentVesionEntity;
  referrerType: ReferrerType;
  onVersionChangeTrigger?: boolean;
  showOverlay?: () => void;
  hideOverlay?: () => void;
}

export const VersionHistory: React.FC<VersionHistoryProps> = ({
  referrer,
  referrerType,
  onVersionChangeTrigger,
  hideOverlay,
  showOverlay,
}) => {
  const [versionHistory, setVersionHistory] = useState<Array<EntityVersionHistory>>();

  const { t } = useTranslation();
  const { toShortLocalDateTime } = useDateFormatter();
  const { errorMessageHandler } = useErrorMessageHandler();

  const versionHistoryAPIClient = useVersionHistoryAPIClient(referrerType);

  const getVersionsHistory = useCallback(async () => {
    const resVersionsData = await versionHistoryAPIClient.getVersionsHistory(referrer.id);

    if (resVersionsData.hasError) {
      errorMessageHandler(resVersionsData);
      hideOverlay && hideOverlay();
      return;
    }

    if (resVersionsData.data) {
      setVersionHistory(resVersionsData.data);
    }

    hideOverlay && hideOverlay();
  }, [referrer]);

  useEffect(() => {
    showOverlay && showOverlay();
    getVersionsHistory();
  }, [referrer, onVersionChangeTrigger]);

  const history = useCallback(() => {
    if (!versionHistory) return;

    const localVersionHistory = versionHistory.map(x => x);

    localVersionHistory.push({
      id: 0,
      entityId: referrer.id.toString(),
      userName: referrer.updatedByUserName,
      date: `${t('currentVersion')}`,
    });

    return localVersionHistory.reverse().map((version, i) => (
      <Timeline.Item
        key={version.id}
        className='container'
        label={
          <div className='date-container'>
            <a
              href={`/${referrerType}/${referrer.id}/version/${localVersionHistory.length - i}/view`}
              rel='noopener noreferrer'
              target='_blank'
              className='index'
            >
              {`v.${localVersionHistory.length - i}`}
            </a>
            <span className='date'>{version.id === 0 ? version.date : toShortLocalDateTime(version.date)}</span>
          </div>
        }
      >
        <div className='user-container'>
          <span className='user-name'>{version.userName}</span>
        </div>
      </Timeline.Item>
    ));
  }, [versionHistory]);

  return (
    <div id='VersionHistory'>
      <Card className='card' bordered={false}>
        <Timeline mode='left' className='timeline-container'>
          {history()}
        </Timeline>
      </Card>
    </div>
  );
};

export default React.memo(VersionHistory);

import { useBaseAPIClient, APIRequestResult } from './index';
import { EntityVersionHistory } from '../../components/Common/types';

type VersionHistoryAPIClient = {
  getVersionsHistory: (id: number) => Promise<APIRequestResult<Array<EntityVersionHistory>>>;
  getVersion: <T>(id: number, version: number) => Promise<APIRequestResult<T>>;
};

export const useVersionHistoryAPIClient = (baseUrl: string, origin?: string): VersionHistoryAPIClient => {
  const { getRequest } = useBaseAPIClient({ origin });

  const getVersionsHistory = (id: number): Promise<APIRequestResult<Array<EntityVersionHistory>>> =>
    getRequest<Array<EntityVersionHistory>>(`${baseUrl}/${id}/versions`);

  const getVersion = function getVersion<T>(id: number, version: number): Promise<APIRequestResult<T>> {
    return getRequest<T>(`${baseUrl}/${id}/version/${version}`);
  };

  return {
    getVersionsHistory,
    getVersion,
  };
};

import React, { useState, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { some } from 'lodash';
import { useLocationsAPIClient } from '../../hooks/api';
import { useErrorMessageHandler } from '../../hooks';
import { Timezone } from './types';

interface TimeZonePickerProps extends SelectProps<string> {
  onChange?(value?: string): void;
  value?: string;
  countryCode?: string;
}

export const TimeZonePicker: React.FC<TimeZonePickerProps> = ({ onChange, value, countryCode }) => {
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const [options, setOptions] = useState<Array<ReactElement>>([]);
  const [timeZoneValue, setTimeZoneValue] = useState<string | undefined>(value);

  const locationsAPIClient = useLocationsAPIClient();
  const { Option } = Select;

  const optionsBuilder = (timezones: Array<Timezone>): void => {
    const o = timezones.map(t => {
      return (
        <Option key={t.name} value={t.code}>
          GMT {t.code} {t.name}
        </Option>
      );
    });
    setOptions(o);
  };

  const buildTimezoneOptions = async (countryCode?: string) => {
    const res = await locationsAPIClient.timezones(countryCode);

    if (res.hasError) {
      errorMessageHandler(res);
      setOptions([]);
      return;
    }

    if (!res.data) {
      setOptions([]);
      return;
    }

    optionsBuilder(res.data);

    if (res.data.length === 1 && value !== res.data[0].code) {
      handleChange(res.data[0].code);
    } else {
      const keepValue = some(res.data, r => r.code === value);

      if (!keepValue && value) {
        handleChange(undefined);
      }
    }
  };

  const handleChange = (value: string | undefined) => {
    setTimeZoneValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    if (countryCode) {
      buildTimezoneOptions(countryCode);
    } else if (options.length > 0) {
      setTimeZoneValue(undefined);
      setOptions([]);
    }
  }, [countryCode]);

  return (
    <Select
      value={timeZoneValue}
      onChange={handleChange}
      allowClear={false}
      placeholder={`${t('select')} ${t('timeZone')}`}
    >
      {options}
    </Select>
  );
};

import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import TenantDisabled from './TenantDisabled';
import UserNotRegistered from './UserNotRegistered';
import React from 'react';

const NotAllowed: React.FC = () => {
  const { isUserRegistered, isTenantActive } = useAuth0();
  const navigate = useNavigate();
  if (!isUserRegistered()) {
    return <UserNotRegistered />;
  }

  if (!isTenantActive()) {
    return <TenantDisabled />;
  }

  navigate('/');
};

export default NotAllowed;

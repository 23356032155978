import React, { useState, useEffect } from 'react';
import { Divider, Input, message, Row, Col, Select, Radio, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDateFormatter, useErrorMessageHandler } from '../../hooks';
import { IconButton, SecondaryButton } from './IconButton';
import { ActivityType, ActivityVisibility, ReferrerType } from './types';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { Plans, Roles } from '../../hooks/auth/types';
import { useActivitiesAPIClient } from '../../hooks/api/ActivitiesAPIClient';
import { orderBy } from 'lodash';

import './css/Activities.css';

const { TextArea } = Input;

export type Activity = {
  id: number;
  createdByUserId: string;
  createdByUserName: string;
  type: number;
  typeName: string;
  typeFilter?: number;
  visibility: ActivityVisibility;
  visibilityName: string;
  createdAt: string;
  text: string;
};

export type ActivityCreate = {
  type: number;
  text: string;
  visibility: ActivityVisibility;
};

export type SupportedActivity = {
  type: ActivityType;
  allowPrivate?: boolean;
  allowInternal?: boolean;
};

type ActivitiesProps = {
  referrerId: number;
  referrerType: ReferrerType;
  showOnDrawer?: boolean;
  readOnly?: boolean;
  supportedTypes: Array<SupportedActivity>;
  typeFilter?: number;
  headerValue?: string;
  containerClassName?: string;
  onActivityAdded?: (id: number, activity: ActivityCreate) => void;
  onActivityInputHasChanges?: (hasChanges: boolean) => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
};

export const Activities: React.FC<ActivitiesProps> = ({
  referrerId,
  referrerType,
  showOnDrawer,
  readOnly,
  supportedTypes,
  headerValue,
  containerClassName,
  onActivityAdded,
  onActivityInputHasChanges,
  showOverlay,
  hideOverlay,
}) => {
  const [activityValue, setActivityValue] = useState<string>();
  const [showEmptyTextError, setShowEmptyTextError] = useState<boolean>(false);
  const [currentActivityType, setCurrentActivityType] = useState<number>(supportedTypes[0].type);
  const [activityFilter, setActivityFilter] = useState<number | null>(null);
  const [activities, setActivities] = useState<Array<Activity>>([]);

  const activitiesAPIClient = useActivitiesAPIClient(referrerType);

  const { t } = useTranslation();
  const { toShortLocalDateTime } = useDateFormatter();
  const { userIsInRole, getUserId, userIsInPlan } = useAuth0();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { Option } = Select;

  const radiowidth = 100 / supportedTypes.length;

  const onInputHasChanges = (hasChanges: boolean) => onActivityInputHasChanges && onActivityInputHasChanges(hasChanges);

  const fillActivities = async (id: number, activityId: number | null): Promise<void> => {
    const types: Array<number> = [];
    supportedTypes.forEach(supportActivity => {
      types.push(supportActivity.type);
    });

    const res = await activitiesAPIClient.getActivities(id, activityId ? [activityId] : types);

    if (res.hasError) {
      errorMessageHandler(res);
      return;
    }

    if (res.data) {
      setActivities(res.data);
    }
  };

  useEffect(() => {
    async function filterActivities() {
      showOverlay && showOverlay();
      await fillActivities(referrerId, activityFilter);
      hideOverlay && hideOverlay();
    }

    filterActivities();
  }, [activityFilter, referrerId]);

  const handleActivityChange = (activityId: number): void => {
    setActivities([]);
    setActivityFilter(activityId);
  };

  const isVisibilityAllowed = (visibility: ActivityVisibility): boolean => {
    if (
      visibility === ActivityVisibility.management &&
      !userIsInRole(Roles.administrator) &&
      !userIsInRole(Roles.recruiterManager)
    )
      return false;

    const currentType = supportedTypes.find(x => x.type === currentActivityType);

    if (!currentType) return false;

    if (visibility === ActivityVisibility.management) return currentType.allowInternal ?? false;

    return currentType.allowPrivate ?? false;
  };

  const addNewActivity = async (activityVisibility: ActivityVisibility): Promise<void> => {
    if (!activityValue) {
      setShowEmptyTextError(true);
      return;
    }

    showOverlay && showOverlay();
    const newActivity = {
      text: activityValue,
      type: currentActivityType,
      visibility: activityVisibility,
    };

    const res = await activitiesAPIClient.createActivity(referrerId, newActivity);

    if (res.hasError) {
      errorMessageHandler(res);
      hideOverlay && hideOverlay();
      onInputHasChanges(true);
      return;
    }

    onActivityAdded && onActivityAdded(referrerId, newActivity);

    if (res.data) {
      setActivities([res.data, ...activities]);
      message.success(`${t(ActivityType[currentActivityType])} ${t('added')}!`);
    }
    setActivityValue('');
    hideOverlay && hideOverlay();
    onInputHasChanges(false);
  };

  const updateActivityValue = (activityValue: string) => {
    setActivityValue(activityValue);
    setShowEmptyTextError(false);
    onInputHasChanges(true);
  };

  const emptyTextError = { borderColor: '#faad14' };

  const getOrderedActivitiesByType = () => {
    return orderBy(activities, [p => p.createdAt], ['desc']);
  };

  const getRenderItemByType = (a: Activity, index: number) => {
    const getMessageClassName = (prefix: string, a: Activity) => {
      if (a.createdByUserId === getUserId()) return `${prefix}-right`;

      return `${prefix}-left`;
    };

    if (
      activityFilter === ActivityType.message ||
      (!activityFilter && a.type === ActivityType.message && supportedTypes.length === 1)
    ) {
      return (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ margin: 0, padding: 0 }}>
            <Col span={24} className={getMessageClassName('name-activityType', a)}>
              {a.createdByUserName}
              {!activityFilter && supportedTypes.length > 1 && ` - ${a.typeName}${' '}`}
              {' - '}
              {toShortLocalDateTime(a.createdAt)}
            </Col>
          </Row>
          <Row justify={getMessageClassName('', a).includes('right') ? 'end' : 'start'}>
            <Col span={18} style={{ textAlign: 'left' }} className={`container ${getMessageClassName('container', a)}`}>
              {a.text}
            </Col>
          </Row>
        </>
      );
    }

    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='container'>
        {!userIsInPlan(Plans.silver) && (
          <Col span={14} className='name-activityType'>
            {a.createdByUserName}
            {supportedTypes.length > 1 && ` - ${a.typeName} `}
            {a.visibility !== ActivityVisibility.public && `(${a.visibilityName})`}
          </Col>
        )}
        {userIsInPlan(Plans.silver) && (
          <Col span={14} className='name-activityType'>
            {supportedTypes.length > 1 && `${a.typeName}`}
          </Col>
        )}
        <Col span={10} className='date'>
          {toShortLocalDateTime(a.createdAt)}
        </Col>
        <Col span={24} className='text'>
          {a.text}
        </Col>
        <Divider className='divider' />
      </Row>
    );
  };

  const Header: React.FC = () => {
    return (
      <div>
        {showOnDrawer && (
          <div>
            <h3>{t('activities')}</h3>
            <Divider className='header-divider' />
            <h3>{headerValue}</h3>
          </div>
        )}
      </div>
    );
  };

  let containerClass = containerClassName;

  if (!containerClass) {
    containerClass = showOnDrawer ? 'activities-list-container-drawer' : 'activities-list-container';
  }

  return (
    <div id='Activities' className='activities-body'>
      <Header />
      {!readOnly && (
        <div>
          {supportedTypes.length > 1 && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Radio.Group
                  buttonStyle='solid'
                  style={{ width: '100%' }}
                  defaultValue={currentActivityType}
                  onChange={e => {
                    setCurrentActivityType(e.target.value);
                  }}
                >
                  {supportedTypes.map(activityType => (
                    <Radio.Button style={{ width: `${radiowidth}%` }} value={activityType.type} key={activityType.type}>
                      {t(ActivityType[activityType.type])}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Col>
            </Row>
          )}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <TextArea
                style={showEmptyTextError ? emptyTextError : undefined}
                rows={4}
                defaultValue={activityValue}
                value={activityValue}
                onChange={e => {
                  updateActivityValue(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={showEmptyTextError ? 'error-message' : ''}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className='ant-form-explain' style={{ color: '#faad14', height: '2px' }}>
                {showEmptyTextError && t('textFieldCantBeEmpty')}
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='end' className='add-buttons-container'>
            <Col className='col-button'>
              <IconButton
                onClick={() => {
                  addNewActivity(ActivityVisibility.public);
                }}
              >
                {`${t('add')} ${t(ActivityType[currentActivityType])}`}
              </IconButton>
            </Col>

            {isVisibilityAllowed(ActivityVisibility.management) && (
              <Col className='col-button'>
                <SecondaryButton
                  onClick={() => {
                    addNewActivity(ActivityVisibility.management);
                  }}
                >
                  {`${t('add')} ${t(`${ActivityType[currentActivityType]}Management`)}`}
                </SecondaryButton>
              </Col>
            )}

            {isVisibilityAllowed(ActivityVisibility.private) && (
              <Col className='col-button'>
                <SecondaryButton
                  onClick={() => {
                    addNewActivity(ActivityVisibility.private);
                  }}
                >
                  {`${t('add')} ${t(`${ActivityType[currentActivityType]}Private`)}`}
                </SecondaryButton>
              </Col>
            )}
          </Row>
        </div>
      )}
      <div className={containerClass}>
        {supportedTypes.length > 1 && (
          <Select
            style={{ width: '120px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
            bordered={false}
            defaultValue={null}
            value={activityFilter}
            onChange={handleActivityChange}
          >
            <Option value={null} key={null}>
              {t('all')}
            </Option>
            {supportedTypes.map(activityType => (
              <Option key={activityType.type} value={activityType.type}>
                {t(`${ActivityType[activityType.type]}Filter`)}
              </Option>
            ))}
          </Select>
        )}
        <List
          className={showOnDrawer ? 'activities-list-drawer' : 'activities-list'}
          bordered={false}
          dataSource={getOrderedActivitiesByType()}
          renderItem={getRenderItemByType}
        />
      </div>
    </div>
  );
};

import React, { useCallback, Suspense } from 'react';
import { Routes, matchPath, useMatch, PathMatch, Route, Navigate, Params } from 'react-router-dom';
import { PAGES } from './routes';
import { Loading } from './components/Common';
import PrivateAppLayout from './components/AppLayout/PrivateAppLayout';
import AuthLayout from './components/Auth/AuthLayout';
import { useAuth0 } from './hooks/auth/Auth0Provider';
import TermsOfServicesModal from './components/Auth/TermsOfServicesModal';
import { AccessType, LayoutType } from './routerTypes';
import HomeLayout from './components/Home/Layout/HomeLayout';

type MainRouterProps = {
  isAuthenticated: boolean;
};
type RouteComponentProps = {
  params: Params<string> | undefined;
};

const MainRouter: React.FC<MainRouterProps> = props => {
  const { isAuthenticated } = props;
  const { pathname } = location;
  const { getUserAcceptedTermsOfService, isUserRegistered, isTenantActive } = useAuth0();

  const [pageRender] = PAGES.filter(el => {
    return matchPath(el.path, pathname);
  });
  let componentImportPath;

  if (pageRender) {
    componentImportPath = pageRender.componentImportPath;
  }
  const Component = componentImportPath as React.ComponentType<RouteComponentProps>;
  const matchRoute: PathMatch<string> | null = useMatch(pageRender ? pageRender.path : 'inexistent-route');

  const showTermsOfServiceModal = (): boolean => {
    if (pageRender?.routeAccessType === AccessType.public) return false;

    if (!isAuthenticated) return false;

    if (!isUserRegistered()) return false;

    if (!isTenantActive()) return false;

    if (getUserAcceptedTermsOfService()) return false;

    return true;
  };

  const LayoutRender = {
    [LayoutType.home]: HomeLayout,
  };

  const Layout = useCallback(
    props => {
      if (showTermsOfServiceModal()) {
        return <TermsOfServicesModal />;
      }

      if (props.pageRender?.layout) {
        const SelectedLayout = LayoutRender[props.pageRender?.layout];
        return <SelectedLayout>{props.children}</SelectedLayout>;
      }

      if (isAuthenticated) {
        return (
          <PrivateAppLayout hideLayout={props.pageRender?.hideLayout} hideSidebar={props.pageRender?.hideSidebar}>
            {props.children}
          </PrivateAppLayout>
        );
      }

      return <>{props.children}</>;
    },
    [isAuthenticated],
  );

  if (!matchRoute && !pageRender) {
    return <Navigate replace to='/' />;
  }

  return (
    <Layout pageRender={pageRender}>
      <Suspense fallback={<Loading withOverlay />}>
        <Routes>
          <Route element={<AuthLayout pageRender={pageRender} location={location} matchRoute={matchRoute} />}>
            <Route path={pathname} element={<Component params={matchRoute?.params} />} />
          </Route>
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default React.memo(MainRouter);

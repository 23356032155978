import React, { useEffect } from 'react';
import { Outlet, RouteProps, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import AccessDenied from './AccessDenied';
import { WSHRSplash } from '../Common';
import NotAllowed from './NotAllowed';

type PrivateRouteProps = {
  path: string;
  permissions?: Array<string>;
  roles?: Array<string>;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
};

export const PrivateRoute: React.FC<PrivateRouteProps & RouteProps> = props => {
  const { userHasAccess, isAuthenticated, loginWithRedirect, loading, isUserRegistered, isTenantActive, isUserActive } =
    useAuth0();
  const { path, permissions, roles, excludedRoles, plans, excludedPlans } = props;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fn = async () => {
      if (!loading && !isAuthenticated && location) {
        await loginWithRedirect({
          appState: { targetUrl: location.pathname },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path, location, loading]);

  const redirectUserNotAllowed = () => {
    if (!location.pathname.includes('not-allowed')) {
      navigate('/not-allowed');
    }
  };

  const renderComponent = () => {
    if (loading || !isAuthenticated) return <WSHRSplash />;

    if (!isUserRegistered() || !isTenantActive()) {
      redirectUserNotAllowed();
      return <NotAllowed />;
    }

    if (!isUserActive()) {
      return <AccessDenied />;
    }

    if (!userHasAccess(permissions, roles, excludedRoles, plans, excludedPlans)) {
      return <AccessDenied />;
    }

    return <Outlet />;
  };

  return renderComponent();
};

export default PrivateRoute;

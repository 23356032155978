import { useTranslation } from 'react-i18next';
import { validatePhoneInput } from '../utils/Input/InputFormatter';

export const useHandlePhoneFormatValidation = () => {
  const { t } = useTranslation();

  const handlePhoneFormatValidation = (rule, value) => {
    if (!value || validatePhoneInput(value)) return Promise.resolve();

    return Promise.reject(t('theInputIsNotValidPhone'));
  };

  return { handlePhoneFormatValidation };
};

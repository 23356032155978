import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { useSharedContext } from '../../hooks';
import UserProfile from '../Auth/UserProfile';
import { Notifications } from './index';
import { Pages, Modules, PermissionType, TargetType } from '../../routerTypes';
import { TenantIcon } from '../Common';
import CalendarSummary from './CalendarSummary';
import './css/Navbar.css';

export const NavBar: React.FC = () => {
  const { isAuthenticated, userHasPermissions, isTenantActive } = useAuth0();

  const NavBarBody: React.FC = () => {
    const { t } = useTranslation();
    const { Search } = Input;
    const location = useLocation();
    const navigate = useNavigate();
    const { setGlobalFilter, currentStatus, globalFilter, enabledGlobalFilter, setEnabledGlobalFilter } =
      useSharedContext();

    const [localCurrentFilter, setLocalCurrentFilter] = useState<string>();

    useEffect(() => {
      if (
        !location.pathname.toLowerCase().includes(Pages[Modules.jobOffers].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.companies].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.scheduledTasks].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.interviews].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.candidates].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.prospectCompanies].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.prospectClients].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.jobApplications].path) &&
        !location.pathname.toLowerCase().includes(Pages[Modules.talentNetworkList].path) &&
        !location.pathname.toLowerCase().includes(`${Pages[Modules.usersManagement].path}/users`)
      ) {
        setEnabledGlobalFilter(false);
        setGlobalFilter(undefined);
      }
    }, [location]);

    useEffect(() => {
      setLocalCurrentFilter(globalFilter);
    }, [globalFilter]);

    const handleSearch = (filter: string): void => {
      let baseUrl = `${Pages[Modules.candidates].path}/all`;

      if (location.pathname.toLowerCase().includes(Pages[Modules.jobOffers].path)) {
        baseUrl = Pages[Modules.jobOffers].path;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.jobApplications].path)) {
        baseUrl = Pages[Modules.jobApplications].path;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.prospectCompanies].path)) {
        baseUrl = `${Pages[Modules.prospectCompanies].path}/${currentStatus}`;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.prospectClients].path)) {
        baseUrl = `${Pages[Modules.prospectClients].path}/${currentStatus}`;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.companies].path)) {
        baseUrl = Pages[Modules.companies].path;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.candidates].path)) {
        baseUrl = `${Pages[Modules.candidates].path}/${currentStatus}`;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.scheduledTasks].path)) {
        baseUrl = `${Pages[Modules.scheduledTasks].path}/${currentStatus}`;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.interviews].path)) {
        baseUrl = `${Pages[Modules.interviews].path}/${currentStatus}`;
      } else if (location.pathname.toLowerCase().includes(Pages[Modules.talentNetworkList].path)) {
        baseUrl = Pages[Modules.talentNetworkList].path;
      } else if (location.pathname.toLowerCase().includes(`${Pages[Modules.usersManagement].path}/users`)) {
        baseUrl = `${Pages[Modules.usersManagement].path}/users`;
      }

      setGlobalFilter(filter);

      if (!filter) {
        navigate(baseUrl);
      } else {
        navigate(`${baseUrl}?filter=${encodeURIComponent(filter)}`);
      }
    };

    return (
      <div id='NavBar' key='NavBar'>
        <Row className='navBarRow' justify='end' align='middle' gutter={20}>
          <Col className='navBarRow search-container'>
            <Search
              disabled={!enabledGlobalFilter}
              value={localCurrentFilter}
              placeholder={t('search')}
              className='searchBar'
              onSearch={value => handleSearch(value)}
              onChange={e => setLocalCurrentFilter(e.target.value)}
              allowClear
            />
          </Col>
          <Col className='navBarRow notifications'>
            <Row align='middle' justify='end'>
              {userHasPermissions(`${PermissionType.read}:${TargetType.interviews}`) && (
                <Col>
                  <CalendarSummary />
                </Col>
              )}
              <Col style={{ paddingRight: '16px' }}>
                <Notifications />
              </Col>
              <Col>
                <UserProfile />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const UnauthorizedUserOrDisabledTenantNavBarBody: React.FC = () => {
    return (
      <div id='NavBar' key='NavBar'>
        <Row className='navBarRow'>
          <Col xs={0} sm={0} md={0} lg={1}>
            <div className='logo-container'>
              <Button
                type='link'
                style={{ display: 'contents' }}
                title={`WebstartedHR - v${process.env.REACT_APP_VERSION}`}
              >
                <TenantIcon />
              </Button>
            </div>
          </Col>
          <Col xs={21} sm={21} md={21} lg={22} className='gutter-row navBarRow' />
          <Col xs={3} sm={3} md={3} lg={1} className='gutter-row navBarRow'>
            <div className='profile'>
              <UserProfile />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return isAuthenticated && userHasPermissions() && isTenantActive() ? (
    <NavBarBody />
  ) : (
    <UnauthorizedUserOrDisabledTenantNavBarBody />
  );
};

export default NavBar;

import { useBaseAPIClient, APIRequestResult } from './BaseAPIClient';
import { KeyValuePair } from '../../components/Common/types';
import { ScheduledTask, NewScheduledTask, ParentType, PriorityLevel } from '../../components/ScheduledTasks/types';

type ScheduleTaskAPIClient = {
  create: (newScheduledTask: NewScheduledTask) => Promise<APIRequestResult<ScheduledTask>>;
  list: (
    page: number,
    pageSize: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  listByStatus: (
    status: number,
    page: number,
    pageSize: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  listOverdue: (
    page: number,
    pageSize: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  listOverview: (
    page: number,
    pageSize: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  count: (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  countByStatus: (
    status: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  countOverdue: (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  countOverview: (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  get: (scheduledTaskId: number) => Promise<APIRequestResult<ScheduledTask>>;
  listByParent: (
    parentType: number,
    parentId: number,
    page: number,
    pageSize: number,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  countByParent: (parentType: number, parentId: number) => Promise<APIRequestResult<number>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getSoonToBeDue: (
    page: number,
    pageSize: number,
    parentType?: ParentType,
    parentId?: number,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ScheduledTask>>>;
  countSoonToBeDue: (
    parentType?: ParentType,
    parentId?: number,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  remove: (scheduledTaskId: number) => Promise<APIRequestResult>;
  update: (scheduledTaskId: number, scheduledTask: ScheduledTask) => Promise<APIRequestResult<ScheduledTask>>;
};

export const useScheduledTaskAPIClient = (origin?: string): ScheduleTaskAPIClient => {
  const { getRequest, postRequest, putRequest, deleteRequest, buildPaginatedUrl } = useBaseAPIClient({ origin });

  const buildFilterUrl = (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
    parentId?: number,
  ): string => {
    let url = '';

    if (parentType) {
      url = `${url}&parentType=${encodeURIComponent(parentType)}`;
    }

    if (parentId) {
      url = `${url}&parentId=${encodeURIComponent(parentId)}`;
    }

    if (taskTypeId) {
      url = `${url}&taskTypeId=${encodeURIComponent(taskTypeId)}`;
    }

    if (priorityLevel) {
      url = `${url}&priorityLevel=${encodeURIComponent(priorityLevel)}`;
    }

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return url;
  };

  const create = (newScheduledTask: NewScheduledTask): Promise<APIRequestResult<ScheduledTask>> =>
    postRequest<ScheduledTask>('scheduled-tasks', newScheduledTask);

  const listByStatus = (
    status: number,
    page: number,
    pageSize = 30,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> => {
    const url = `${buildPaginatedUrl(`scheduled-tasks/status/${status}`, page, pageSize)}${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
    )}`;

    return getRequest(url);
  };

  const list = (
    page: number,
    pageSize = 30,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> => {
    const url = `${buildPaginatedUrl('scheduled-tasks', page, pageSize)}${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
    )}`;

    return getRequest(url);
  };

  const listOverdue = (
    page: number,
    pageSize = 30,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> => {
    const url = `${buildPaginatedUrl('scheduled-tasks/overdue', page, pageSize)}${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
    )}`;

    return getRequest(url);
  };

  const countByStatus = (
    status: number,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const url = `scheduled-tasks/status/${status}/count?${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
    )}`;

    return getRequest(url);
  };

  const count = (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const url = `scheduled-tasks/count?${buildFilterUrl(parentType, taskTypeId, priorityLevel, filter)}`;

    return getRequest(url);
  };

  const countOverdue = (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const url = `scheduled-tasks/overdue/count?${buildFilterUrl(parentType, taskTypeId, priorityLevel, filter)}`;

    return getRequest(url);
  };

  const countOverview = (
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const url = `scheduled-tasks/overview/count?${buildFilterUrl(parentType, taskTypeId, priorityLevel, filter)}`;

    return getRequest(url);
  };

  const listOverview = (
    page: number,
    pageSize = 30,
    parentType?: ParentType,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> => {
    const url = `${buildPaginatedUrl('scheduled-tasks/overview', page, pageSize)}${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
    )}`;

    return getRequest(url);
  };

  const get = (scheduledTaskId: number): Promise<APIRequestResult<ScheduledTask>> =>
    getRequest<ScheduledTask>(`scheduled-tasks/${scheduledTaskId}`);

  const getSoonToBeDue = (
    page: number,
    pageSize = 30,
    parentType?: ParentType,
    parentId?: number,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> => {
    const url = `${buildPaginatedUrl('scheduled-tasks/overview', page, pageSize)}${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
      parentId,
    )}`;

    return getRequest(url);
  };

  const countSoonToBeDue = (
    parentType?: ParentType,
    parentId?: number,
    taskTypeId?: number,
    priorityLevel?: PriorityLevel,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const url = `scheduled-tasks/overview/count?${buildFilterUrl(
      parentType,
      taskTypeId,
      priorityLevel,
      filter,
      parentId,
    )}`;

    return getRequest(url);
  };

  const listByParent = (
    parentType: number,
    parentId: number,
    page: number,
    pageSize = 30,
  ): Promise<APIRequestResult<Array<ScheduledTask>>> =>
    getRequest<Array<ScheduledTask>>(
      buildPaginatedUrl(`scheduled-tasks/parent/${parentType}/id/${parentId}`, page, pageSize),
    );

  const countByParent = (parentType: number, parentId: number): Promise<APIRequestResult<number>> =>
    getRequest<number>(`scheduled-tasks/parent/${parentType}/id/${parentId}/count`);

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('scheduled-tasks/statuses');

  const remove = (scheduledTaskId: number): Promise<APIRequestResult> =>
    deleteRequest(`scheduled-tasks/${scheduledTaskId}`);

  const update = (scheduledTaskId: number, scheduledTask: ScheduledTask): Promise<APIRequestResult<ScheduledTask>> =>
    putRequest<ScheduledTask>(`scheduled-tasks/${scheduledTaskId}`, scheduledTask);

  return {
    create,
    list,
    listByStatus,
    listOverdue,
    listOverview,
    count,
    countByStatus,
    countOverdue,
    countOverview,
    get,
    listByParent,
    countByParent,
    getStatuses,
    getSoonToBeDue,
    countSoonToBeDue,
    remove,
    update,
  };
};

import { APIRequestResult, useBaseAPIClient } from './index';
import { CandidatesStats, ProspectCompaniesStats, ProspectClientsStats } from '../../components/Stats/types';
import { PeriodFilter } from '../../components/Common/types';

type StatsAPIClient = {
  getStatusByCreated: (period: PeriodFilter) => Promise<APIRequestResult<CandidatesStats>>;
  getStatusByUpdated: (period: PeriodFilter) => Promise<APIRequestResult<CandidatesStats>>;
  getOwnersStatusByUpdated: (period: PeriodFilter) => Promise<APIRequestResult<CandidatesStats>>;
  getOwnersStatusByGroup: (groupId: number) => Promise<APIRequestResult<CandidatesStats>>;
  getContactActivityStatus: (period: PeriodFilter) => Promise<APIRequestResult<CandidatesStats>>;
  getProspectCompaniesStatusByCreated: (period: PeriodFilter) => Promise<APIRequestResult<ProspectCompaniesStats>>;
  getProspectCompaniesStatusByUpdated: (period: PeriodFilter) => Promise<APIRequestResult<ProspectCompaniesStats>>;
  getProspectClientsStatusByCreated: (period: PeriodFilter) => Promise<APIRequestResult<ProspectClientsStats>>;
  getProspectClientsStatusByUpdated: (period: PeriodFilter) => Promise<APIRequestResult<ProspectClientsStats>>;
};

export const useStatsAPIClient = (origin?: string): StatsAPIClient => {
  const { getRequest, buildPeriodQuery } = useBaseAPIClient({ origin });

  const getStatusByCreated = (period: PeriodFilter): Promise<APIRequestResult<CandidatesStats>> =>
    getRequest<CandidatesStats>(`stats/candidates/status/created/count?${buildPeriodQuery(period)}`);

  const getStatusByUpdated = (period: PeriodFilter): Promise<APIRequestResult<CandidatesStats>> =>
    getRequest<CandidatesStats>(`stats/candidates/status/updated/count?${buildPeriodQuery(period)}`);

  const getOwnersStatusByUpdated = (period: PeriodFilter): Promise<APIRequestResult<CandidatesStats>> =>
    getRequest<CandidatesStats>(`stats/candidates/owners/status/updated/count?${buildPeriodQuery(period)}`);

  const getContactActivityStatus = (period: PeriodFilter): Promise<APIRequestResult<CandidatesStats>> =>
    getRequest<CandidatesStats>(`stats/candidates/activites/contact/status/count?${buildPeriodQuery(period)}`);

  const getOwnersStatusByGroup = (groupId: number): Promise<APIRequestResult<CandidatesStats>> =>
    getRequest<CandidatesStats>(`stats/candidates/group/${groupId}/owners/status/updated/count`);

  const getProspectCompaniesStatusByCreated = (
    period: PeriodFilter,
  ): Promise<APIRequestResult<ProspectCompaniesStats>> =>
    getRequest<ProspectCompaniesStats>(`stats/prospect-companies/status/created/count?${buildPeriodQuery(period)}`);

  const getProspectCompaniesStatusByUpdated = (
    period: PeriodFilter,
  ): Promise<APIRequestResult<ProspectCompaniesStats>> =>
    getRequest<ProspectCompaniesStats>(`stats/prospect-companies/status/updated/count?${buildPeriodQuery(period)}`);

  const getProspectClientsStatusByCreated = (period: PeriodFilter): Promise<APIRequestResult<ProspectClientsStats>> =>
    getRequest<ProspectClientsStats>(`stats/prospect-clients/status/created/count?${buildPeriodQuery(period)}`);

  const getProspectClientsStatusByUpdated = (period: PeriodFilter): Promise<APIRequestResult<ProspectClientsStats>> =>
    getRequest<ProspectClientsStats>(`stats/prospect-clients/status/updated/count?${buildPeriodQuery(period)}`);

  return {
    getStatusByCreated,
    getStatusByUpdated,
    getOwnersStatusByUpdated,
    getOwnersStatusByGroup,
    getContactActivityStatus,
    getProspectCompaniesStatusByCreated,
    getProspectCompaniesStatusByUpdated,
    getProspectClientsStatusByCreated,
    getProspectClientsStatusByUpdated,
  };
};

export const DefaultDrawer = {
  DRAWER_WIDTH: 'min(100vw, 640px)',
  DRAWER_LARGE_WIDTH: 'min(100vw, 1000px)',
  DRAWER_EXTRA_LARGE_WIDTH: 'min(100vw, 1200px)',
};

export const Colors = {
  red: '#FF0000',
  darkRed: '#D32828',
  lightBlue: '#3396b7',
  blue: '#1e8ca8',
  darkBlue: '#45729a',
  defaultBlue: '#2199bd',
  lightGreen: '#4db277',
  green: '#30be6b',
  darkGreen: '#0b7b3a',
  yellow: '#dea600',
  darkYellow: '#ca9905',
  orange: '#ce6e29',
  darkOrange: '#d35f0c',
  lightGrey: '#BDBDBD ',
  grey: '#828282 ',
  white: '#FFFFFF',
  badgeBlue: '#108ee9',
  lightGray: '#A4A4A4',
  black: '#1F1F1F',
};

export const PageDefaultValues = {
  defaultInitialScroll: 400,
  defaultPageValue: 30,
};

interface ScrollToFirstErrorDefaults {
  behavior: 'auto' | 'smooth';
  block: 'start' | 'center' | 'end' | 'nearest';
  inline: 'start' | 'center' | 'end' | 'nearest';
}

export const scrollToFirstErrorDefaults: ScrollToFirstErrorDefaults = {
  behavior: 'smooth',
  block: 'center',
  inline: 'center',
};

import React from 'react';
import { ButtonProps } from 'antd';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { IconButton } from '../Common';
import './css/PrivateButton.css';

export interface PrivateButtonProps extends ButtonProps {
  permissions?: Array<string>;
  roles?: Array<string>;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
}

export const PrivateButton: React.FC<PrivateButtonProps> = props => {
  const { userHasAccess } = useAuth0();
  const { onClick, permissions, roles, excludedRoles, plans, excludedPlans, children, ...rest } = props;

  if (!userHasAccess(permissions, roles, excludedRoles, plans, excludedPlans)) {
    return null;
  }

  return (
    <IconButton id='PrivateButton' onClick={onClick} {...rest}>
      {children}
    </IconButton>
  );
};

export default PrivateButton;

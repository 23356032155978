import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/TextEditor.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'code'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
];

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
};

export const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  //State to identify the first change
  const [firstChange, setFirstChange] = useState(true);

  //This function is used to sanitize the input text by removing extra spaces and converting HTML entities to plain text.
  const cleanText = (text?: string): string | void => {
    if (!text) return;
    text = text.replace(/\s+/g, ' ').trim();
    const temporal = document.createElement('div');
    temporal.innerHTML = text;
    return temporal.textContent || temporal.innerText || '';
  };

  // The 'handleEditorChange' function is triggered when the editor content changes.
  // We check if it's the first change event. If so, we compare the sanitized content with the initial value to prevent unnecessary updates.
  // Once the first change occurs, we set 'firstChange' to false to skip the initial comparison in subsequent changes.
  // Finally, if there's a change in the content and an 'onChange' callback is provided, we call it with the updated content.
  const handleEditorChange = (content: string): void => {
    if (firstChange) {
      if (cleanText(content) === cleanText(value)) return;
      setFirstChange(false);
    }
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <ReactQuill
      id='TextEditor'
      theme='snow'
      value={value || ''}
      modules={modules}
      formats={formats}
      onChange={handleEditorChange}
      placeholder={placeholder}
    />
  );
};

export default TextEditor;

import React, { useCallback, useEffect, useState } from 'react';
import { Card, Tag, Timeline } from 'antd';
import { useDateFormatter, useErrorMessageHandler } from '../../hooks';
import { useStatusHistoryAPIClient } from '../../hooks/api';
import { EntityStatusHistory, ReferrerType } from './types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { Plans } from '../../hooks/auth/types';
import './css/StatusHistory.css';

interface StatusHistoryProps {
  referrerId: number;
  referrerType: ReferrerType;
  onStatusChangeTrigger?: boolean;
  showNotes?: boolean;
  showOverlay?: () => void;
  hideOverlay?: () => void;
}

export const StatusHistory: React.FC<StatusHistoryProps> = ({
  referrerId,
  referrerType,
  onStatusChangeTrigger,
  showNotes,
  showOverlay,
  hideOverlay,
}) => {
  const { toShortLocalDateTime, capitalizeFirstLetter } = useDateFormatter();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { userIsInPlan } = useAuth0();
  const { t } = useTranslation();

  const [statusesHistory, setStatusesHistory] = useState<Array<EntityStatusHistory>>([]);

  const statusHistoryAPIClient = useStatusHistoryAPIClient(referrerType);

  const GetNumberOfDaysBetweenTwoDates = ({ firstDate, secondDate }): JSX.Element => {
    return (
      <span className='days-text'>
        {capitalizeFirstLetter(t('days'))}:
        <p className='days'>
          {dayjs(secondDate ? secondDate : dayjs(), 'YYYY-MM-DD').diff(dayjs(firstDate, 'YYYY-MM-DD'), 'days')}
        </p>
      </span>
    );
  };

  const getStatusHistory = useCallback(async () => {
    const res = await statusHistoryAPIClient.getStatusHistory(referrerId);

    if (res.hasError) {
      errorMessageHandler(res);
      hideOverlay && hideOverlay();
      return;
    }

    if (res.data) {
      setStatusesHistory(res.data);
    }

    hideOverlay && hideOverlay();
  }, [referrerId]);

  useEffect(() => {
    showOverlay && showOverlay();
    getStatusHistory();
  }, [referrerId, onStatusChangeTrigger]);

  const history = useCallback(() => {
    return statusesHistory.map((statusChange, index) => (
      <Timeline.Item
        className='container'
        color={!index ? 'green' : 'blue'}
        key={statusChange.id}
        label={
          <div className='status-change-date'>
            <span>{toShortLocalDateTime(statusChange.createdAt)}</span>
            <GetNumberOfDaysBetweenTwoDates
              firstDate={statusChange.createdAt}
              secondDate={statusesHistory[index - 1]?.createdAt}
            />
          </div>
        }
      >
        <Tag color={index ? '#2db7f5' : '#4EC129'}>{statusChange.statusName}</Tag>
        {!userIsInPlan(Plans.silver) && <span className='created-byUserName'>{statusChange.createdByUserName}</span>}
        {showNotes && statusChange.notes && <span className='notes'>{` - ${statusChange.notes}`}</span>}
      </Timeline.Item>
    ));
  }, [statusesHistory]);

  return (
    <>
      {!isEmpty(statusesHistory) && (
        <div id='StatusHistory'>
          <Card className='card' bordered={false}>
            <Timeline mode='left'>{history()}</Timeline>
          </Card>
        </div>
      )}
    </>
  );
};

export default React.memo(StatusHistory);

import { Divider, Menu, Modal } from 'antd';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

type UpSellMenuItemProps = {
  icon: ReactNode;
  title: ReactNode;
};

const UpSellMenuItem: React.FC<UpSellMenuItemProps> = ({ title, icon }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleCancelModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Menu.Item onClick={() => setOpenModal(true)} className='upSell' key='prospect clients' icon={icon}>
        {title}
      </Menu.Item>
      <Modal width={600} footer={null} open={openModal} title={t('upgradeYourPlan')} onCancel={handleCancelModal}>
        <Divider style={{ margin: '12px 0' }} />
        <p>{t('upgradeYourPlanFirstMessage')}</p>
        <p style={{ margin: 0 }}>
          {t('upgradeYourPlanSecondMessage')}{' '}
          <a href='mailto:support@webstartedhr.com' style={{ color: '#28b6e3' }}>
            support@webstartedhr.com
          </a>
          .
        </p>
      </Modal>
    </>
  );
};

export default UpSellMenuItem;

import { CandidatePrivateProfile } from '../../components/Candidates/PrivateProfile/types';
import { Candidate } from '../../components/Candidates/types';
import { APIRequestResult, useBaseAPIClient } from './BaseAPIClient';

type TalentNetworkAPIClient = {
  get: () => Promise<APIRequestResult<CandidatePrivateProfile>>;
  acceptTermsOfService: () => Promise<APIRequestResult>;
  update: (candidate: CandidatePrivateProfile) => Promise<APIRequestResult<CandidatePrivateProfile>>;
  getDataFromResume: (file: File | Blob,  tenant: string, reCaptchaToken: string) => Promise<APIRequestResult<Candidate>>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
};

export const useTalentNetworkAPIClient = (): TalentNetworkAPIClient => {
  const { getRequest, putRequest, uploadFileRequest } = useBaseAPIClient();
  const get = (): Promise<APIRequestResult<CandidatePrivateProfile>> =>
    getRequest<CandidatePrivateProfile>(`talent-network`);

  const update = (candidate: CandidatePrivateProfile): Promise<APIRequestResult<CandidatePrivateProfile>> =>
    putRequest<CandidatePrivateProfile>(`talent-network`, candidate);

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> =>
    uploadFileRequest(`talent-network/file`, file);

  const acceptTermsOfService = (): Promise<APIRequestResult> => {
    const url = 'talent-network/accept-terms-of-service';

    return putRequest(url);
  };

  const getDataFromResume = (file: File | Blob, tenant: string, reCaptchaToken: string): Promise<APIRequestResult<Candidate>> => {
      const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
      };

  return uploadFileRequest(`talent-network/public/extract`, file, config);}

  return {
    get,
    acceptTermsOfService,
    getDataFromResume,
    update,
    uploadFile,
  };
};

import { useBaseAPIClient, APIRequestResult } from './index';
import { Country, Region, City, Timezone } from '../../components/Common';

type UsersAPIClient = {
  countries: (filter?: string) => Promise<APIRequestResult<Array<Country>>>;
  regions: (countryCode: string, filter?: string) => Promise<APIRequestResult<Array<Region>>>;
  cities: (countryCode: string, regionName: string, filter?: string) => Promise<APIRequestResult<Array<City>>>;
  timezones: (countryCode?: string) => Promise<APIRequestResult<Array<Timezone>>>;
};

export const useLocationsAPIClient = (origin?: string): UsersAPIClient => {
  const { getRequest } = useBaseAPIClient({ origin });

  const countries = (filter?: string): Promise<APIRequestResult<Array<Country>>> => {
    let url = `locations/countries`;
    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<Country>>(url);
  };

  const regions = (countryCode: string, filter?: string): Promise<APIRequestResult<Array<Region>>> => {
    let url = `locations/country/${countryCode}/regions`;
    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<Region>>(url);
  };

  const cities = (countryCode: string, regionName: string, filter?: string): Promise<APIRequestResult<Array<City>>> => {
    let url = `locations/country/${countryCode}/region/${encodeURIComponent(regionName)}/cities`;
    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<City>>(url);
  };

  const timezones = (countryCode?: string): Promise<APIRequestResult<Array<Timezone>>> => {
    const url = countryCode ? `locations/country/${countryCode}/timezones` : '/locations/timezones';

    return getRequest<Array<Timezone>>(url);
  };

  return {
    countries,
    regions,
    cities,
    timezones,
  };
};

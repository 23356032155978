import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type DialogBuilder = {
  deleteDialog: (title: string, content: string | React.ReactElement, onOk?: () => void, onCancel?: () => void) => void;
  confirmDialog: (
    title: string,
    content: string | React.ReactElement,
    onOk?: () => void,
    onCancel?: () => void,
  ) => void;
  infoDialog: (title: string, content: string | React.ReactElement, onOk?: () => void) => void;
};

export const useDialogBuilder = (): DialogBuilder => {
  const { t } = useTranslation();
  const { confirm, info } = Modal;

  const deleteDialog = (
    title: string,
    content: string | React.ReactElement,
    onOk?: () => void,
    onCancel?: () => void,
  ): void => {
    confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        onOk && onOk();
      },
      onCancel() {
        onCancel && onCancel();
      },
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
    });
  };

  const confirmDialog = (
    title: string,
    content: string | React.ReactElement,
    onOk?: () => void,
    onCancel?: () => void,
  ): void => {
    confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        onOk && onOk();
      },
      onCancel() {
        onCancel && onCancel();
      },
      okText: t('yes'),
      okType: 'primary',
      cancelText: t('no'),
    });
  };

  const infoDialog = (title: string, content: string | React.ReactElement, onOk?: () => void): void => {
    info({
      title,
      content,
      icon: <InfoCircleOutlined />,
      centered: true,
      onOk() {
        onOk && onOk();
      },
      okText: t('ok'),
      okType: 'primary',
    });
  };

  return {
    deleteDialog,
    confirmDialog,
    infoDialog,
  };
};

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';

type PrivateLinkProps = {
  to: string;
  children: ReactElement;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  permissions?: Array<string>;
  roles?: Array<string>;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
};

export const PrivateLink: React.FC<PrivateLinkProps> = props => {
  const { userHasAccess } = useAuth0();
  const { to, permissions, roles, excludedRoles, plans, excludedPlans, children, onClick, ...rest } = props;

  if (!userHasAccess(permissions, roles, excludedRoles, plans, excludedPlans)) {
    return null;
  }

  return (
    <Link to={to} onClick={onClick} {...rest}>
      {children}
    </Link>
  );
};

export default PrivateLink;

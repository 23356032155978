/* eslint-disable no-console */
import { useState, useEffect, ReactElement } from 'react';
import packageJson from '../../package.json';
import { WSHRSplash } from '../components/Common';

// Fix for broken node types
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Global {}
  }
}
interface ICustomGlobal extends NodeJS.Global {
  appVersion: string;
}

interface ICacheBusterProps {
  children: ReactElement;
}

declare let global: ICustomGlobal;

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA: string, versionB: string): boolean => {
  const versionsA = versionA.replace('-', '.').split(/\./g);
  const versionsB = versionB.replace('-', '.').split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function CacheBuster({ children }: ICacheBusterProps): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(false);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        for (const name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion: string = meta.version;
        const currentVersion: string = global.appVersion;
        const shouldForceRefresh: boolean = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version of WebstartedHR App - ${latestVersion}. Should force refresh`);
          setIsLatestVersion(false);
          setLoading(false);
        } else {
          console.log(
            `You already have the latest version of WebstartedHR App - ${latestVersion}. No cache refresh needed.`,
          );
          setIsLatestVersion(true);
          setLoading(false);
        }
      });
  }, []);

  if (loading) {
    return <WSHRSplash />;
  }

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
    return <WSHRSplash />;
  }

  return children;
}

export default CacheBuster;

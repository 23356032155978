import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import { message, Row, Col, Form, Input, Divider, Modal, List, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Assignation, ReferrerType } from './types';
import { APIRequestResult } from '../../hooks/api';
import { useUsersInfo } from '../../hooks/UseUsersInfo';
import { useDateFormatter, useErrorMessageHandler } from '../../hooks';
import { useTypeGuards } from '../../utils/TypeGuards';
import { IconButton, SearchInput } from '.';
import { useAssignationsAPIClient } from '../../hooks/api/AssignationsAPIClient';
import { scrollToFirstErrorDefaults } from '../../constants/DefaultTheme';

import './css/AssignTo.css';

type AssignToProps = {
  id: number;
  referrerType: ReferrerType;
  title: string;
  onAssigned?: (id: number, assignations: Array<Assignation>) => void;
  onUnassigned?: (id: number, assignedUserId: string) => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
};

export const AssignTo: React.FC<AssignToProps> = ({
  id,
  referrerType,
  title,
  onAssigned,
  onUnassigned,
  showOverlay,
  hideOverlay,
}) => {
  const { t } = useTranslation();
  const { getOtherUserSearchInputList } = useUsersInfo();
  const { toLocalDateTime } = useDateFormatter();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { TextArea } = Input;
  const { Option } = Select;
  const { confirm } = Modal;
  const { isNotNullOrUndefined } = useTypeGuards();

  const [usersAssignedTo, setUsersAssignedTo] = useState<Array<Assignation>>([]);

  const assignationsAPIClient = useAssignationsAPIClient(referrerType);

  const getUsersAssignedToList = useCallback(async (): Promise<void> => {
    const res = await assignationsAPIClient.getAssignationsById(id);

    if (res.hasError) {
      errorMessageHandler(res);
      return;
    }

    if (!res.data) return;

    if (res.data) {
      setUsersAssignedTo(res.data);
    }
  }, [id]);

  const fillAssignments = async (): Promise<void> => {
    showOverlay && showOverlay();

    await getUsersAssignedToList();

    hideOverlay && hideOverlay();
  };

  useEffect(() => {
    fillAssignments();
  }, []);

  const onAssign = useCallback(
    async (assignment): Promise<void> => {
      if (!assignment) return;

      showOverlay && showOverlay();

      if (assignment && assignment.assignedUserIds.length > 0) {
        const results = await Promise.all<APIRequestResult<Assignation>>(
          assignment.assignedUserIds.map((assignedUserId: string) =>
            assignationsAPIClient.assign(id, {
              assignedUserId,
              reason: assignment.reason,
              notes: assignment.notes,
            }),
          ),
        );

        const errorResults = results.filter(r => {
          return r.hasError;
        });

        const assignations = results.map(r => r?.data).filter(isNotNullOrUndefined);

        if (isEmpty(assignations) && !isEmpty(errorResults)) {
          errorMessageHandler(errorResults[0]);
          hideOverlay && hideOverlay();
          return;
        }

        message.success(t('assignedToMessage'));

        if (onAssigned && assignations) {
          onAssigned(id, assignations);
        }

        setUsersAssignedTo([...usersAssignedTo, ...assignations]);

        hideOverlay && hideOverlay();
      }
    },
    [usersAssignedTo],
  );

  const onConfirmUnassign = useCallback(
    async (assignedUserId: string): Promise<void> => {
      if (!id) return;

      showOverlay && showOverlay();

      const res = await assignationsAPIClient.unassign(id, assignedUserId);

      if (res.hasError) {
        errorMessageHandler(res);
        hideOverlay && hideOverlay();
        return;
      }

      message.success(`${title} ${t('unassigned')}!`);
      setUsersAssignedTo(usersAssignedTo.filter(e => e.assignedUserId !== assignedUserId));

      onUnassigned && onUnassigned(id, assignedUserId);

      hideOverlay && hideOverlay();
    },
    [usersAssignedTo],
  );

  const onUnassign = useCallback(
    (id: string, title: string): void => {
      confirm({
        title: t('confirmUnassign'),
        content: `${t('confirmUnassignMessage')} ${title}?`,
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk() {
          onConfirmUnassign(id);
        },
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
      });
    },
    [usersAssignedTo],
  );

  const getAssignmentForm = (): ReactElement => {
    return (
      <div id='AssignTo' className='assignments-body'>
        <div>
          <Form
            name='nest-messages'
            layout='vertical'
            onFinish={onAssign}
            scrollToFirstError={scrollToFirstErrorDefaults}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label={t('assignTo')}
                  name='assignedUserIds'
                  rules={[{ required: true, message: `${t('pleaseSelect')} ${t('assignTo')}!` }]}
                >
                  <SearchInput placeholder={t('userEmail')} fetch={getOtherUserSearchInputList} mode='multiple' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label={t('assignmentReason')} name='reason'>
                  <Select>
                    <Option key={t('assignmentByInterview')}>{t('assignmentByInterview')}</Option>
                    <Option key={t('manualAssignment')}>{t('manualAssignment')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label={t('notes')} name='notes'>
                  <TextArea style={{ width: '100%' }} maxLength={750} rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <IconButton type='primary' htmlType='submit' className='assignButton icon-button'>
                  {t('assign')}
                </IconButton>
              </Col>
            </Row>
          </Form>
        </div>
        <div className='assignments-list-container'>
          <List
            className='assignments-list-drawer'
            bordered={false}
            dataSource={usersAssignedTo}
            renderItem={(a): ReactElement => (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='container'>
                <Col span={14} className='title'>{`${t('assignedTo')} ${a.assignedUserId}`}</Col>
                <Col span={10} className='date'>
                  {toLocalDateTime(a.createdAt)}
                </Col>
                <Col span={24} className='text'>{`${t('assignedBy')}: ${a.createdByUserName}`}</Col>
                {a.reason && <Col span={24} className='text'>{`${t('reason')}: ${a.reason}`}</Col>}
                {a.notes && <Col span={10} className='text'>{`${t('notes')}: ${a.notes}`}</Col>}
                <Col span={24} className='button'>
                  <span
                    className='unassign-button'
                    onClick={(): void => {
                      onUnassign(a.assignedUserId, title);
                    }}
                  >
                    {t('unassign')}
                  </span>
                </Col>
                <Divider className='divider' />
              </Row>
            )}
          />
        </div>
      </div>
    );
  };

  return <>{getAssignmentForm()}</>;
};

export default React.memo(AssignTo);

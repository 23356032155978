import React, { CSSProperties } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type CheckboxForFormItemProps = {
  value?: boolean;
  onChange?: (e: boolean) => void;
  text?: string;
  style?: CSSProperties;
};

export const CheckboxForFormItem: React.FC<CheckboxForFormItemProps> = ({ value, text, onChange, style, ...rest }) => {
  const handleChange = (e: CheckboxChangeEvent): void => {
    onChange && onChange(e.target.checked);
  };

  return (
    <Checkbox defaultChecked={value} onChange={handleChange} style={style} {...rest}>
      {text}
    </Checkbox>
  );
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSharedContext } from '../../hooks';

import './css/GoBackButton.css';

type GoBackButtonsProps = {
  text?: string;
  path?: string;
  ignore?: Array<string>;
};

export const GoBackArrow: React.FC<GoBackButtonsProps> = ({ text, path, ignore }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { previousRoute } = useSharedContext();

  const getBackPath = (): string | undefined => {
    if (previousRoute && path && ignore && ignore.some(route => previousRoute.includes(route))) return path;

    if (previousRoute && path && previousRoute.includes(path)) return previousRoute;

    return path;
  };

  const goBack = (): void => {
    const backPath = getBackPath();
    backPath ? navigate(backPath) : navigate(-1);
  };

  return (
    <div id='GoBack-button'>
      <div className='goBack-button'>
        <span onClick={goBack}>
          <ArrowLeftOutlined /> {text ?? t('backToList')}
        </span>
      </div>
    </div>
  );
};

export default GoBackArrow;

import React from 'react';
import { Menu, MenuItemProps } from 'antd';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import UpSellMenuItem from '../Common/UpSellMenuItem';

export interface PrivateMenuItemProps extends MenuItemProps {
  permissions?: Array<string>;
  roles?: Array<string>;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
  showUpSell?: boolean;
  webstartedTenant?: boolean;
}

export const PrivateMenuItem: React.FC<PrivateMenuItemProps> = props => {
  const { userHasAccess, userIsInPlan } = useAuth0();
  const {
    onClick,
    permissions,
    roles,
    excludedRoles,
    plans,
    excludedPlans,
    children,
    showUpSell,
    title,
    icon,
    webstartedTenant = false,
    ...rest
  } = props;

  if (!userHasAccess(permissions, roles, excludedRoles, plans, excludedPlans, webstartedTenant)) {
    if (showUpSell && userIsInPlan(excludedPlans)) {
      return <UpSellMenuItem title={children} icon={icon} />;
    }
    return null;
  }

  return (
    <Menu.Item
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...rest}
      title={title}
      icon={icon}
    >
      {children}
    </Menu.Item>
  );
};
export default PrivateMenuItem;

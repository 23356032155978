export const SessionStorageKeys = {
  CANDIDATES_STAGES: 'candidates-stages',
  CANDIDATES_STATUSES: 'candidates-statuses',
  FLAGS: 'flags',
  COMPANY_STATUSES: 'company-statuses',
  EXCLUDED_COMPANIES: 'excluded-companies',
  EXCLUDED_PROSPECT_COMPANIES: 'excluded-prospect-companies',
  EXECUTIVE_COMPANIES: 'executive-companies',
  PROSPECT_COMPANY_STATUSES: 'prospect-company-statuses',
  PROSPECT_CLIENT_STATUSES: 'prospect-client-statuses',
  PROSPECT_CLIENT_MEETING_STATUSES: 'prospect-client-meeting-statuses',
  INTERVIEW_STATUSES: 'interview-statuses',
  JOB_APPLICATION_STATUSES: 'job-application-statuses',
  JOB_OFFER_STATUSES: 'job-offer-statuses',
  GROUP_STATUSES: 'group-statuses',
  MOST_USED_TECHS: 'most-used-techs',
  COUNTRIES: 'countries',
  SCHEDULED_TASK_STATUSES: 'scheduled-task-statuses',
  TECHNOLOGIES: 'technologies',
  CODE_CHALLENGES_STATUSES: 'code-challenges-statuses',
};

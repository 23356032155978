import { useEffect } from 'react';
import { useExpSessionStorage, useErrorMessageHandler } from '.';
import { APIRequestResult } from './api';

export function useArraySessionStorage<T>(
  func: () => Promise<APIRequestResult<Array<T>>>,
  key: string,
  ttl?: number,
): [Array<T>, boolean] {
  const [storedValue, setValue, initialized] = useExpSessionStorage<Array<T>>(key, ttl ?? 60, []);
  const { errorMessageHandler } = useErrorMessageHandler();

  useEffect(() => {
    async function loadData() {
      if (!storedValue.length) {
        const res = await func();

        if (res.hasError) {
          errorMessageHandler(res);
          return;
        }

        if (res.data) {
          setValue(res.data);
        }
      }
    }
    loadData();
  }, [key]);

  return [storedValue, initialized];
}

import React, { useState, ReactElement, CSSProperties, ReactNode } from 'react';
import { Button, Col, Layout, Popover, Row } from 'antd';
import { NavBar, SiderMenu } from '../Menu';
import LoadingOverlay from '@speedy4all/react-loading-overlay';
import { useSharedContext, useViewport } from '../../hooks';
import { Loading } from '../Common';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { MenuOutlined } from '@ant-design/icons';
import './PrivateAppLayout.css';

const styles: { [key: string]: CSSProperties } = {
  sider: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100vh',
    position: 'sticky',
    top: 0,
    left: 0,
    backgroundColor: '#333333',
    zIndex: 1000,
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
    backgroundColor: '#333333',
    overflowX: 'hidden',
  },
  popoverColumn: {
    paddingLeft: '15px',
  },
  popoverButton: {
    color: 'white',
    backgroundColor: '#333333',
  },
  content: {
    margin: '64px 0px 0px 0px',
    width: '100%',
  },
  contentNotAllowed: {
    padding: '64px 0px 0px 0px',
    width: '100%',
    height: '100%',
  },
  nonLayoutContent: {
    margin: '0px',
    width: '100%',
  },
};

const LocalSider = (): ReactElement => {
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={styles.sider}>
      <SiderMenu />
    </Sider>
  );
};

const LocalPopOver = (): ReactElement => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const content = <SiderMenu closeMenu={() => setOpenMenu(false)} />;

  return (
    <Popover placement='topLeft' open={openMenu} content={content} arrow={false} trigger='click' color='#333333'>
      <Button style={styles.popoverButton} onClick={() => setOpenMenu(!openMenu)}>
        <MenuOutlined id='MenuButton' />
      </Button>
    </Popover>
  );
};

type PrivateAppLayoutProps = {
  hideLayout?: boolean;
  hideSidebar?: boolean;
  children?: Array<ReactNode> | ReactNode;
};

const PrivateAppLayout: React.FC<PrivateAppLayoutProps> = ({ hideLayout, children, hideSidebar = false }) => {
  const { isTenantActive, isUserRegistered } = useAuth0();
  const { isDesktop } = useViewport();
  const { Content, Header } = Layout;

  const { enabledLoadingOverlay } = useSharedContext();

  if (hideLayout) {
    return (
      <LoadingOverlay active={enabledLoadingOverlay} spinner={<Loading />}>
        <div className='no-layout-content' id='content'>
          <Content style={styles.nonLayoutContent}>{children}</Content>
        </div>
      </LoadingOverlay>
    );
  }

  if (!isTenantActive() || !isUserRegistered()) {
    return (
      <>
        <Header style={styles.header}>
          <NavBar />
        </Header>
        <div className='content' id='content'>
          <Content style={styles.contentNotAllowed}>{children}</Content>
        </div>
      </>
    );
  }

  return (
    <Layout>
      {isDesktop() && !hideSidebar && <LocalSider />}
      <Header style={styles.header}>
        <Row style={{ display: 'flex', justifyContent: isDesktop() ? 'flex-end' : 'space-between' }}>
          {!isDesktop() && !hideSidebar && (
            <Col span={4} style={styles.popoverColumn}>
              <LocalPopOver />
            </Col>
          )}
          <Col xl={24} lg={24} md={20} sm={20} xs={20}>
            <NavBar />
          </Col>
        </Row>
      </Header>
      <LoadingOverlay active={enabledLoadingOverlay} spinner={<Loading />}>
        <div className='content' id='content'>
          <Content style={styles.content}>{children}</Content>
        </div>
      </LoadingOverlay>
    </Layout>
  );
};

export default React.memo(PrivateAppLayout);

import React, { CSSProperties } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

type LoadingForDrawerProps = {
  height?: string;
};

export const LoadingForDrawer: React.FC<LoadingForDrawerProps> = ({ height }) => {
  const style: CSSProperties = {
    display: 'grid',
    height: height || '85vh',
    margin: '0',
    placeItems: 'center center',
  };

  return (
    <div style={style}>
      <ClipLoader size={100} color='#56CCF2' />
    </div>
  );
};

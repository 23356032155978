import React from 'react';
import { APIRequestResult } from './api/BaseAPIClient';
import { useErrorMessageHandler } from '.';

interface UseListElementsDataType {
  (
    setListData: (value: React.SetStateAction<any[]>) => void,
    setPaginateEnd: React.Dispatch<React.SetStateAction<boolean>>,
    setSaving: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    pageSize: number,
  ): {
    getListElementsData: GetListElementsData;
    clearListElementsData: () => void;
  };
}

interface GetListElementsData {
  (getListAPIResponse: () => Promise<APIRequestResult<any[]>>): void;
}

export const useListElementsData: UseListElementsDataType = (
  setListData,
  setPaginateEnd,
  setSaving,
  setLoading,
  pageSize,
) => {
  const { errorMessageHandler } = useErrorMessageHandler();
  const getListElementsData: GetListElementsData = async getListAPIResponse => {
    setSaving(true);

    const res = await getListAPIResponse();

    if (res.hasError) {
      errorMessageHandler(res);
      setSaving(false);
      setLoading(false);
      return;
    }

    if (res.data) {
      const list = res.data;
      setListData(prevState => {
        return [...prevState, ...list];
      });
    }

    if (res.data && res.data.length < pageSize) {
      setPaginateEnd(true);
    }

    setSaving(false);
    setLoading(false);
  };

  const clearListElementsData = () => {
    setListData([]);
  };

  return {
    getListElementsData,
    clearListElementsData,
  };
};

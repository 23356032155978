import React, { useState, useEffect } from 'react';
import { Form, Col, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { CountriesInput, SearchInput, SearchInputData, TimeZonePicker } from '.';
import { useLocationsAPIClient } from '../../hooks/api';
import { useErrorMessageHandler } from '../../hooks';
import { Address } from './types';

interface AddressElement {
  address?: Address;
}
interface AdressInputProps {
  form: FormInstance<any>;
  data?: AddressElement;
  showTimeZonePicker?: boolean;
  countryCode?: string;
  resetFormTrigger?: boolean;
}

export const AddressLocationFormItems: React.FC<AdressInputProps> = ({
  data,
  form,
  showTimeZonePicker,
  countryCode,
  resetFormTrigger,
}) => {
  const [currentCountryCode, setCurrentCountryCode] = useState<string | undefined>(data?.address?.country);
  const [currentStateCode, setCurrentStateCode] = useState<string | undefined>(data?.address?.state);

  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();

  const locationsAPIClient = useLocationsAPIClient();

  useEffect(() => {
    if (resetFormTrigger !== undefined) {
      setCurrentCountryCode(countryCode ?? data?.address?.country);
      setCurrentStateCode(data?.address?.state);
    }
  }, [resetFormTrigger]);

  useEffect(() => {
    if (countryCode) {
      setCurrentCountryCode(countryCode);
    }
  }, [countryCode]);

  const onChangeCountryCode = v => {
    setCurrentCountryCode(v);
  };

  const onChangeState = v => {
    setCurrentStateCode(v);
  };

  const getRegionsInputData = async (c, filter?: string): Promise<Array<SearchInputData>> => {
    if (!c) return [];

    const res = await locationsAPIClient.regions(c, filter);

    if (res.hasError) {
      errorMessageHandler(res);
      return [];
    }

    if (!res.data) return [];

    return res.data.map(d => {
      return {
        value: d.name,
        text: d.name,
      };
    });
  };

  const getCitiesInputData = async (c, r, filter?: string): Promise<Array<SearchInputData>> => {
    if (!c || !r) return [];

    const res = await locationsAPIClient.cities(c, r, filter);

    if (res.hasError) {
      errorMessageHandler(res);
      return [];
    }

    if (!res.data) return [];

    return res.data.map(d => {
      return {
        value: d.name,
        text: d.name,
      };
    });
  };

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={showTimeZonePicker ? 6 : 8}>
        <Form.Item label={t('countryOfResidence')} name={['address', 'country']}>
          <CountriesInput onChange={onChangeCountryCode} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={showTimeZonePicker ? 6 : 8}>
        <Form.Item label={t('state')} name={['address', 'state']}>
          <SearchInput
            placeholder={t('search')}
            fetch={f => getRegionsInputData(form.getFieldValue(['address', 'country']), f)}
            onChange={onChangeState}
            initOn={currentCountryCode}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={showTimeZonePicker ? 6 : 8}>
        <Form.Item label={t('city')} name={['address', 'city']}>
          <SearchInput
            placeholder={t('search')}
            fetch={f =>
              getCitiesInputData(
                form.getFieldValue(['address', 'country']),
                form.getFieldValue(['address', 'state']),
                f,
              )
            }
            initOn={currentStateCode}
          />
        </Form.Item>
      </Col>
      {showTimeZonePicker && (
        <Col xs={24} sm={24} md={24} lg={6}>
          <Form.Item label={t('timeZone')} name='timezone'>
            <TimeZonePicker countryCode={form.getFieldValue(['address', 'country'])} />
          </Form.Item>
        </Col>
      )}
    </>
  );
};

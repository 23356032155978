import { Modules, Pages } from '../routerTypes';
import { useAuth0 } from './auth/Auth0Provider';

type CandidateUrlBuilder = {
  composeSocialUrl: (user: string, website: Website) => string;
  externalResumeUrl: (url: string) => string;
  composeResumeFriendlyUrl: (resumeFriendlyName: string) => string;
  composeLocalResumeFriendlyUrl: (resumeFriendlyName: string) => string;
  composeCompleteProfileUrl: (currentTenant: string, customAccessToken?: string) => string;
  composeJobApplicationReviewUrl: (currentTenant: string, customAccessToken?: string) => string;
  composeJobOfferApplyUrl: (currentTenant: string, jobOfferId: number) => string;
  composeWhatsAppContactUrl: (phone: string) => string;
};

const socialUrl = {
  GITHUB: 'github.com/',
  LINKEDIN: 'www.linkedin.com/in/',
  LINKEDINCOMPANY: 'www.linkedin.com/company/',
  INSTAGRAM: 'instagram.com/',
  FACEBOOK: 'facebook.com/',
  TWITTER: 'twitter.com/',
  BEHANCE: 'behance.net/',
};

type Website = 'github' | 'linkedin' | 'linkedinCompany' | 'instagram' | 'twitter' | 'facebook' | 'behance';

export const useCandidateUrlBuilder = (): CandidateUrlBuilder => {
  const { getCurrentUserTenant, getUserId } = useAuth0();

  const getUserIdPrefix = (): string => getUserId().split('@')[0];

  const externalResumeUrl = (url: string): string => {
    if (url.includes('http://') || url.includes('https://')) {
      return url;
    }
    return `${'https://'}${url}`;
  };

  const composeSocialUrl = (user: string, website: Website): string => {
    const baseUrl = {
      github: socialUrl.GITHUB,
      linkedin: socialUrl.LINKEDIN,
      linkedinCompany: socialUrl.LINKEDINCOMPANY,
      instagram: socialUrl.INSTAGRAM,
      facebook: socialUrl.FACEBOOK,
      twitter: socialUrl.TWITTER,
      behance: socialUrl.BEHANCE,
    };

    if (
      user?.includes(baseUrl.github) ||
      user?.includes(baseUrl.linkedin) ||
      user?.includes(baseUrl.linkedinCompany) ||
      user?.includes(baseUrl.instagram) ||
      user?.includes(baseUrl.facebook) ||
      user?.includes(baseUrl.twitter) ||
      user?.includes(baseUrl.behance)
    ) {
      return user;
    }

    return `https://${baseUrl[website]}${encodeURIComponent(user)}`;
  };

  const composeResumeFriendlyUrl = (resumeFriendlyName: string): string => {
    return `${window.location.origin}/downloads/${encodeURIComponent(
      getCurrentUserTenant(),
    )}/resume/${encodeURIComponent(resumeFriendlyName)}`;
  };

  const composeLocalResumeFriendlyUrl = (resumeFriendlyName: string): string => {
    return `${
      process.env.REACT_APP_WEBSTARTED_SERVER_URL
    }/downloads/${getCurrentUserTenant()}/resume/${encodeURIComponent(resumeFriendlyName)}`;
  };

  const composeCompleteProfileUrl = (currentTenant: string, customAccessToken?: string): string => {
    return `${window.location.origin}/${encodeURIComponent(currentTenant)}/completeprofile/${customAccessToken}`;
  };

  const composeJobApplicationReviewUrl = (currentTenant: string, customAccessToken?: string): string => {
    return `${window.location.origin}/${encodeURIComponent(currentTenant)}/review/${customAccessToken}`;
  };

  const composeJobOfferApplyUrl = (currentTenant: string, jobOfferId: number): string => {
    return `${window.location.origin}/${encodeURIComponent(currentTenant)}${
      Pages[Modules.candidates].path
    }/add/${jobOfferId}?ref=${getUserIdPrefix()}`;
  };

  const composeWhatsAppContactUrl = (phone: string): string => {
    return `https://api.whatsapp.com/send?phone=${encodeURIComponent(phone)}`;
  };

  return {
    composeSocialUrl,
    externalResumeUrl,
    composeResumeFriendlyUrl,
    composeLocalResumeFriendlyUrl,
    composeCompleteProfileUrl,
    composeJobApplicationReviewUrl,
    composeJobOfferApplyUrl,
    composeWhatsAppContactUrl,
  };
};

import React from 'react';
import { CirclePicker } from 'react-color';

type FormItemCirclePickerProps = {
  onChange?: (value?: string) => void;
  value?: string;
  width: string;
  circleSize: number;
  circleSpacing: number;
};

export const FormItemCirclePicker: React.FC<FormItemCirclePickerProps> = ({ value, onChange, ...rest }) => {
  const handleChange = (v): void => {
    onChange && onChange(v.hex);
  };

  return <CirclePicker color={value || undefined} onChange={handleChange} {...rest} />;
};

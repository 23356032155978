type TypeGuards = {
  isNotNullOrUndefined: <TValue>(value: TValue | null | undefined) => value is TValue;
};

export const useTypeGuards = (): TypeGuards => {
  const isNotNullOrUndefined = function isNotNullOrUndefined<TValue>(
    value: TValue | null | undefined,
  ): value is TValue {
    return value !== null && value !== undefined;
  };

  return { isNotNullOrUndefined };
};

import { useState } from 'react';
import { useLoggly } from 'react-loggly-jslogger';

export function useExpSessionStorage<T>(key: string, ttl: number, initialValue?: T): [T, (s: T) => void, boolean] {
  const { error } = useLoggly();

  let currentValue: T;
  let expiry: number;

  const [initialized, setInitialized] = useState(false);

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const now = new Date();

      // Keep the same data while the hook is alive
      if (currentValue && now.getTime() <= expiry) return currentValue;

      const expItem = window.sessionStorage.getItem(key.toLowerCase());
      if (!expItem) {
        setInitialized(false);
        return initialValue;
      }

      const item = JSON.parse(expItem);

      if (now.getTime() > item.expiry) {
        window.sessionStorage.removeItem(key.toLowerCase());
        setInitialized(false);
        return initialValue;
      }

      // Keep the same data while the hook is alive
      currentValue = item.value;
      expiry = item.expiry;

      setInitialized(true);

      return item.value;
    } catch (err) {
      error(err);
      throw err;
    }
  });

  const setValue = (value: T) => {
    try {
      const now = new Date();
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      currentValue = value;

      const item = {
        value,
        expiry: now.getTime() + ttl * 1000 * 60,
      };

      window.sessionStorage.setItem(key.toLowerCase(), JSON.stringify(item));

      setInitialized(true);
    } catch (err) {
      error(err);
    }
  };

  return [storedValue, setValue, initialized];
}

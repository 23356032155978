const permittedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Tab',
];

export const validateNumberInputKeyPress = (pressedKey: React.KeyboardEvent<HTMLInputElement>): string | void =>
  permittedKeys.find(e => e === pressedKey.key) ? pressedKey.key : pressedKey.preventDefault();

export const validatePhoneInput = (phone: string): boolean => {
  if (!phone) return false;

  const re = /^[+0-9-)(\s.]+$/gm;
  return re.test(phone);
};

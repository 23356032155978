import React from 'react';
import { Plans, Roles } from './hooks/auth/types';
import { Page, AccessType, Params, PermissionType, TargetType, Pages, Modules, LayoutType } from './routerTypes';

export const PAGES: Page[] = [
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/HomePage/')),
    path: '/',
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/HomePage/')),
    path: '/home',
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Pricing')),
    path: '/pricing',
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Features')),
    path: '/features',
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Resources')),
    path: '/resources',
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Resources/Detail')),
    path: `/resources/:${Params.resourceSlug}`,
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Auth/AuthLoad')),
    path: '/login',
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Auth/NotAllowed')),
    path: '/not-allowed',
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Registration')),
    path: '/registration',
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/Thanks')),
    path: '/registration/thanks',
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/TermsOfService')),
    path: '/terms-of-service',
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.candidates].path,
    excludedRoles: [Roles.companyAdministrator],
    redirectTo: `${Pages[Modules.candidates].path}/all`,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/Assignations')),
    path: `${Pages[Modules.candidates].path}/assignations`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.assign,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/ExternalAdd')),
    path: `/:${Params.tenant}${Pages[Modules.candidates].path}/add`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/ExternalAdd')),
    path: `/:${Params.tenant}${Pages[Modules.candidates].path}/add/:${Params.jobOfferId}`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/ExternalAdd/ApplySuccess')),
    path: `/apply-success`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/ExternalCheck')),
    path: `/:${Params.tenant}${Pages[Modules.candidates].path}/check`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/CompleteProfile')),
    path: `/:${Params.tenant}/completeprofile/:${Params.customAccessToken}`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/Add')),
    path: `${Pages[Modules.candidates].path}/add`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/List')),
    path: `${Pages[Modules.candidates].path}/:${Params.status}`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.candidates,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/Edit')),
    path: `${Pages[Modules.candidates].path}/:${Params.candidateId}/edit`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/Edit')),
    path: `/:${Params.tenant}${Pages[Modules.candidates].path}/:${Params.candidateId}/edit`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/View')),
    path: `${Pages[Modules.candidates].path}/:${Params.candidateId}/view`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    componentImportPath: React.lazy(() => import('./components/Candidates/PrivateProfile')),
    path: `talent/profile`,
    excludedRoles: [Roles.companyAdministrator],
    redirectTo: process.env.REACT_APP_TALENT_URL,
    hideSidebar: true,
    roles: [Roles.talentNetworkCandidate],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/TalentNetwork/List')),
    path: `talent-network`,
    excludedRoles: [Roles.companyAdministrator],
    roles: [Roles.administrator, Roles.recruiterManager, Roles.talentNetworkViewer],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Candidates/ViewVersion')),
    path: `${Pages[Modules.candidates].path}/:${Params.candidateId}/version/:${Params.version}/view`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/List')),
    path: Pages[Modules.jobOffers].path,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobOffers,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView')),
    path: `${Pages[Modules.companyView].path}/job-offers`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView')),
    path: `${Pages[Modules.companyView].path}/:${Params.companyId}/job-offers`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.administrator, Roles.recruiterManager, Roles.companyExecutive],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer')),
    path: `${Pages[Modules.companyView].path}/job-offers/:${Params.jobOfferId}`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer')),
    path: `${Pages[Modules.companyView].path}/:${Params.companyId}/job-offers/:${Params.jobOfferId}`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.administrator, Roles.recruiterManager, Roles.companyExecutive],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer/Detail')),
    path: `${Pages[Modules.companyView].path}/:${Params.companyId}/job-offers/:${Params.jobOfferId}/details`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.administrator, Roles.recruiterManager, Roles.companyExecutive],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer/Detail')),
    path: `${Pages[Modules.companyView].path}/job-offers/:${Params.jobOfferId}/details`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer/Candidate')),
    path: `${Pages[Modules.companyView].path}/:${Params.companyId}/job-offers/:${Params.jobOfferId}/job-application/:${
      Params.jobApplicationId
    }`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.administrator, Roles.recruiterManager, Roles.companyExecutive],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer/Candidate')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: `${Pages[Modules.companyView].path}/job-offers/:${Params.jobOfferId}/job-application/:${
      Params.jobApplicationId
    }`,
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/DeelIntegration')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: '/company/deel/token',
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Add')),
    path: `${Pages[Modules.jobOffers].path}/add`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Edit')),
    path: `${Pages[Modules.jobOffers].path}/:${Params.jobOfferId}/edit`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/View')),
    path: `${Pages[Modules.jobOffers].path}/:${Params.jobOfferId}/view`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Assignations')),
    path: `${Pages[Modules.jobOffers].path}/assignations`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.assign,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Companies/List')),
    path: Pages[Modules.companies].path,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.companies,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.companies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Companies/Add')),
    path: `${Pages[Modules.companies].path}/add`,
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.companies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Companies/Edit')),
    path: `${Pages[Modules.companies].path}/:${Params.companyId}/edit`,
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.companies,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.prospectCompanies].path,
    redirectTo: `${Pages[Modules.prospectCompanies].path}/all`,
    excludedPlans: [Plans.silver, Plans.gold],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/Add')),
    path: `${Pages[Modules.prospectCompanies].path}/add`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.prospectCompanies].path,
    redirectTo: `${Pages[Modules.prospectCompanies].path}/all`,
    excludedPlans: [Plans.silver, Plans.gold],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/List')),
    path: `${Pages[Modules.prospectCompanies].path}/:${Params.status}`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.prospectCompanies,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Companies/View')),
    path: `/${Pages[Modules.companies].path}/:${Params.companyId}/view`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.companies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/Edit')),
    path: `${Pages[Modules.prospectCompanies].path}/:${Params.prospectCompanyId}/edit`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.prospectClients].path,
    excludedPlans: [Plans.silver, Plans.gold],
    redirectTo: `${Pages[Modules.prospectClients].path}/all`,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectClients/Add')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: `${Pages[Modules.prospectClients].path}/add`,
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.prospectClients,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.prospectClients].path,
    excludedPlans: [Plans.silver, Plans.gold],
    redirectTo: `${Pages[Modules.prospectClients].path}/all`,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectClients/List')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: `${Pages[Modules.prospectClients].path}/:${Params.status}`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.prospectClients,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.prospectClients,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectClients/Edit')),
    path: `${Pages[Modules.prospectClients].path}/:${Params.prospectClientId}/edit`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.prospectClients,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectClients/Edit')),
    path: `/:${Params.tenant}${Pages[Modules.prospectClients].path}/:${Params.prospectClientId}/edit`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.prospectClients,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Parameters/Add')),
    path: Pages[Modules.parameters].path,
    roles: [Roles.administrator, Roles.recruiterManager],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Integrations')),
    path: Pages[Modules.integrations].path,
    roles: [Roles.administrator],
    excludedPlans: [Plans.silver],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Integrations/Slack')),
    path: `${Pages[Modules.integrations].path}/slack`,
    roles: [Roles.administrator],
    excludedPlans: [Plans.silver],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Integrations/Calendly')),
    path: `${Pages[Modules.integrations].path}/calendly`,
    roles: [Roles.administrator],
    excludedPlans: [Plans.silver],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement')),
    path: `${Pages[Modules.usersManagement].path}/:${Params.status}`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/TenantSettings')),
    path: Pages[Modules.settings].path,
    roles: [Roles.administrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement/Users/Add')),
    path: `${Pages[Modules.usersManagement].path}/users/add`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement/Users/Edit')),
    path: `${Pages[Modules.usersManagement].path}/users/:${Params.userId}/edit`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement/Users/View')),
    path: `${Pages[Modules.usersManagement].path}/users/:${Params.userId}/view`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement/Roles/View')),
    path: `${Pages[Modules.usersManagement].path}/roles/:${Params.roleId}/view`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/UsersManagement')),
    path: `${Pages[Modules.usersManagement].path}/users/add`,
    roles: [Roles.userAdministrator],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.interviews].path,
    excludedRoles: [Roles.companyAdministrator],
    redirectTo: `${Pages[Modules.interviews].path}/next`,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Interviews/Session')),
    excludedRoles: [Roles.companyAdministrator],
    path: `${Pages[Modules.interviews].path}/:${Params.interviewId}/session`,
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.interviews,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Interviews/NextAndPast')),
    excludedRoles: [Roles.companyAdministrator],
    path: `${Pages[Modules.interviews].path}/:${Params.status}`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.interviews,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.interviews,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ScheduledTasks/Assignations')),
    path: `${Pages[Modules.scheduledTasks].path}/assignations`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.assign,
        target: TargetType.scheduledTasks,
      },
    ],
  },
  {
    routeAccessType: AccessType.redirect,
    path: Pages[Modules.scheduledTasks].path,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    redirectTo: `${Pages[Modules.scheduledTasks].path}/overview`,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ScheduledTasks/Dashboard')),
    path: `${Pages[Modules.scheduledTasks].path}/:${Params.status}`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    path: Pages[Modules.jobApplications].path,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    path: `${Pages[Modules.jobApplications].path}/group/:${Params.groupId}`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    excludedPlans: [Plans.silver],
    path: `${Pages[Modules.jobApplications].path}/group/:${Params.groupId}/joboffer/:${Params.jobOfferId}`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    path: `${Pages[Modules.jobApplications].path}/joboffer/:${Params.jobOfferId}`,
    excludedRoles: [Roles.companyAdministrator],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/ViewVersion')),
    path: `${Pages[Modules.jobApplications].path}/:${Params.jobApplicationId}/version/:${Params.version}/view`,
    excludedRoles: [Roles.companyAdministrator],
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Review')),
    path: `/:${Params.tenant}/review/:${Params.customAccessToken}`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Stats')),
    excludedPlans: [Plans.silver],
    path: Pages[Modules.dashboard].path,
    roles: [
      Roles.recruiter,
      Roles.administrator,
      Roles.recruiterManager,
      Roles.salesAgent,
      Roles.externalRecruiterManager,
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectClients/Meetings')),
    path: `${Pages[Modules.prospectClients].path}/meetings/:${Params.status}`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.prospectClients,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.prospectClients,
      },
    ],
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Candidates/RedirectToResume')),
    path: `/downloads/:${Params.tenant}/resume/:${Params.resumeFriendlyName}`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Groups/index')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: Pages[Modules.groups].path,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.groups,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/Groups/DailyReport/index')),
    excludedPlans: [Plans.silver, Plans.gold],
    path: `${Pages[Modules.groups].path}/daily`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.groups,
      },
    ],
  },
  //--> Legacy routes <--
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/List')),
    path: '/joboffers',
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobOffers,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView')),
    path: '/company/joboffers',
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView')),
    path: `/company/:${Params.companyId}/joboffers`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.administrator, Roles.recruiterManager],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer')),
    path: `/company/joboffers/:${Params.jobOfferId}/view`,
    excludedPlans: [Plans.silver, Plans.gold],
    roles: [Roles.companyAdministrator],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/CompanyView/JobOffer')),
    path: `/company/:${Params.companyId}/joboffers/:${Params.jobOfferId}/view`,
    roles: [Roles.administrator, Roles.recruiterManager],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Add')),
    path: '/joboffers/add',
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Edit')),
    path: `/joboffers/:${Params.jobOfferId}/edit`,
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/View')),
    path: `/joboffers/:${Params.jobOfferId}/view`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Assignations')),
    path: '/joboffers/assignations',
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.assign,
        target: TargetType.jobOffers,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/List')),
    path: '/prospectcompanies',
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.prospectCompanies,
      },
      {
        permission: PermissionType.delete,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/Add')),
    path: '/prospectcompanies/add',
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.create,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/ProspectCompanies/Edit')),
    path: `/prospectcompanies/:${Params.prospectCompanyId}/edit`,
    excludedPlans: [Plans.silver, Plans.gold],
    permissions: [
      {
        permission: PermissionType.update,
        target: TargetType.prospectCompanies,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    path: `/jobapplications`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    excludedPlans: [Plans.silver],
    path: `/jobapplications/group/:${Params.groupId}`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    excludedPlans: [Plans.silver],
    path: `/jobapplications/group/:${Params.groupId}/joboffer/:${Params.jobOfferId}`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/Dashboard')),
    path: `/jobapplications/joboffer/:${Params.jobOfferId}`,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/JobApplications/ViewVersion')),
    path: `/jobapplications/:${Params.jobApplicationId}/version/:${Params.version}/view`,
    excludedPlans: [Plans.silver],
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.jobApplications,
      },
    ],
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/LinkedInExtension/')),
    path: `/linkedin-extension/v3`,
    hideLayout: true,
    permissions: [
      {
        permission: PermissionType.read,
        target: TargetType.candidates,
      },
    ],
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/ExternalRecruiterRegistration')),
    path: `/:${Params.tenant}/register-external-recruiter`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.public,
    hideLayout: true,
    componentImportPath: React.lazy(() => import('./components/RequestPlan')),
    path: `/request-plan`,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/ContactUs')),
    path: `/contact-us`,
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/Home/PrivacyPolicy')),
    path: `/privacy-policy`,
    hideLayout: true,
    layout: LayoutType.home,
  },
  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Public')),
    path: `/:${Params.tenant}/careers`,
    hideLayout: true,
  },

  {
    routeAccessType: AccessType.public,
    componentImportPath: React.lazy(() => import('./components/JobOffers/Public')),
    path: `/:${Params.tenant}/careers/:${Params.jobOfferId}`,
    hideLayout: true,
  },
  {
    routeAccessType: AccessType.private,
    componentImportPath: React.lazy(() => import('./components/WebstartedAI')),
    path: Pages[Modules.webstartedAI].path,
    roles: [Roles.administrator],
  },
];

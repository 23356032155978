import React from 'react';
import { Menu, SubMenuProps } from 'antd';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import UpSellMenuItem from '../Common/UpSellMenuItem';

const { SubMenu } = Menu;

export interface PrivateSubMenuProps extends SubMenuProps {
  permissions?: Array<string>;
  roles?: Array<string>;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
  showUpSell?: boolean;
  onClick?: () => void;
}

export const PrivateSubMenu: React.FC<PrivateSubMenuProps> = props => {
  const { userHasAccess, userIsInPlan } = useAuth0();
  const {
    permissions,
    roles,
    excludedRoles,
    plans,
    excludedPlans,
    children,
    showUpSell,
    icon,
    title,
    onClick,
    ...rest
  } = props;

  if (!userHasAccess(permissions, roles, excludedRoles, plans, excludedPlans)) {
    if (showUpSell && userIsInPlan(excludedPlans)) {
      return <UpSellMenuItem title={title} icon={icon} />;
    }
    return null;
  }

  return (
    <SubMenu onTitleClick={onClick} icon={icon} title={title} {...rest}>
      {children}
    </SubMenu>
  );
};

export default PrivateSubMenu;

import React, { useEffect, useState } from 'react';
import { Upload, message, Tooltip, Avatar, Button, Modal } from 'antd';
import { CloudUploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import LoadingOverlay from '@speedy4all/react-loading-overlay';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { APIRequestResult } from '../../hooks/api';
import { useErrorMessageHandler } from '../../hooks';
import './css/AvatarUpload.css';

type AvatarUploadProps = {
  value?: string | null;
  title?: string;
  size?: number;
  showRemove?: boolean;
  onUploadImage: (file: Blob) => Promise<APIRequestResult<string>>;
  onChange?: (value?: string) => void;
};

const AvatarUploadLoader = styled(LoadingOverlay)`
  background: rgba(0, 0, 0, 0);
  .AvatarUploadLoader_overlay {
    background: rgba(0, 0, 0, 0);
  }
`;

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  value,
  title,
  size,
  showRemove = true,
  onUploadImage,
  onChange,
}) => {
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const [imageUrl, setImageUrl] = useState<string | undefined | null>(value);
  const [uploading, setUploading] = useState<boolean>(false);
  const { confirm } = Modal;

  const beforeUpload = (file: RcFile): boolean => {
    const isLt1M = file.size / 1024 / 1024 < 1.1;

    if (!isLt1M) {
      message.error(`${t('imageSizeMessage')}!`);
      return false;
    }

    return true;
  };

  const getBase64 = (img: string | Blob | RcFile, callback: (url: string) => void): void => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.result) {
        callback(reader.result.toString());
      }
    });
    reader.readAsDataURL(img as Blob);
  };

  const handleRemove = (): void => {
    confirm({
      title: t('confirmDelete'),
      content: `${t('confirmDeleteImageMessage')}?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        setImageUrl(undefined);
        onChange && onChange();
      },
      okText: t('yes'),
      cancelText: t('no'),
    });
  };

  const customRequest = async (options): Promise<void> => {
    setUploading(true);

    const { file } = options;

    if (!file) {
      setUploading(false);
      return;
    }

    getBase64(file, url => {
      setImageUrl(url);
    });

    const res = await onUploadImage(file as Blob);

    if (res.hasError) {
      errorMessageHandler(res);
      setUploading(false);
      onChange && onChange();
      return;
    }

    if (res.data) {
      onChange && onChange(res.data);
    }

    if (options.onSuccess) {
      options.onSuccess(res.data, new XMLHttpRequest());
    }

    setUploading(false);
  };

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  return (
    <div id='AvatarUpload'>
      {imageUrl ? (
        <div className='image-uploader'>
          <AvatarUploadLoader active={uploading} classNamePrefix='AvatarUploadLoader_'>
            <Avatar size={size ? size : 200} src={imageUrl} />
          </AvatarUploadLoader>
          <div style={{ height: '5px' }}>
            {imageUrl && !uploading && showRemove && (
              <Button type='link' danger onClick={handleRemove}>
                {t('removeAvatar')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className='image-uploader'>
          <Upload
            name='imageUploader'
            accept='image/*'
            listType='text'
            showUploadList={false}
            multiple={false}
            beforeUpload={beforeUpload}
            customRequest={options => {
              customRequest(options);
            }}
          >
            <Tooltip title={title ?? t('uploadImage')} placement='top'>
              <div className='image-uploader-ctrl' style={{ width: size, height: size }}>
                <div className='uploader-container'>
                  <CloudUploadOutlined style={{ fontSize: 26 }} />
                  <p style={{ marginBottom: 0, marginTop: '5px' }}>{title ?? t('uploadImage')}</p>
                </div>
              </div>
            </Tooltip>
          </Upload>
        </div>
      )}
    </div>
  );
};

import { Icon } from '@ant-design/compatible';
import { ThemeType } from '@ant-design/compatible/lib/icon';

export interface CustomIconComponentProps {
  width: string | number;
  height: string | number;
  fill: string;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
  spin?: boolean;
  rotate?: number;
  ['aria-hidden']?: React.AriaAttributes['aria-hidden'];
}

interface CoreIconProps {
  tabIndex?: number;
  className?: string;
  theme?: ThemeType;
  title?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  twoToneColor?: string;
  viewBox?: string;
  spin?: boolean;
  rotate?: number;
  style?: React.CSSProperties;
  role?: string;
}
export interface IconProps extends CoreIconProps {
  type?: string;
  component?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
}

const CustomIcon = Icon as unknown as React.ElementType<IconProps>;

export default CustomIcon;

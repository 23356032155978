import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Row, Col, Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import { Assignation } from './types';
import { APIRequestResult } from '../../hooks/api';
import { useErrorMessageHandler } from '../../hooks';
import { useTypeGuards } from '../../utils/TypeGuards';
import { useUsersInfo } from '../../hooks/UseUsersInfo';
import { IconButton, SearchInput } from '.';
import { useAssignationsAPIClient } from '../../hooks/api/AssignationsAPIClient';

import './css/AssignTo.css';

type AssignManyToProps = {
  ids: Array<number>;
  referrerType: string;
  title: string;
  clearSelection?: () => void;
  onAssignations?: (a: Array<Assignation>) => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
};

export const AssignManyTo: React.FC<AssignManyToProps> = ({
  ids,
  referrerType,
  title,
  clearSelection,
  onAssignations,
  showOverlay,
  hideOverlay,
}) => {
  const { t } = useTranslation();
  const { isNotNullOrUndefined } = useTypeGuards();
  const { TextArea } = Input;
  const { getOtherUserSearchInputList } = useUsersInfo();

  const { errorMessageHandler } = useErrorMessageHandler();

  const assignationsAPIClient = useAssignationsAPIClient(referrerType);

  const onMultipleAssign = useCallback(async assignment => {
    showOverlay && showOverlay();
    const results = await Promise.all<APIRequestResult<Assignation>>(
      ids.map(id => assignationsAPIClient.assign(id, assignment)),
    );

    const errorResults = results.filter(r => {
      return r.hasError;
    });

    const assignations = results.map(r => r?.data).filter(isNotNullOrUndefined);

    if (isEmpty(assignations) && !isEmpty(errorResults)) {
      errorMessageHandler(errorResults[0]);
      hideOverlay && hideOverlay();
      return;
    }

    message.success(t('assignedToMessage'));

    if (clearSelection) {
      clearSelection();
    }

    if (onAssignations && assignations) {
      onAssignations(assignations);
    }

    hideOverlay && hideOverlay();
  }, []);

  const getAssignmentForm = (): ReactElement => {
    return (
      <div id='AssignTo' className='assignments-body'>
        <div>
          <Form name='nest-messages' layout='vertical' onFinish={onMultipleAssign} autoComplete='off'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label={t('assignTo')}
                  name='assignedUserId'
                  rules={[{ required: true, message: `${t('pleaseInput')} ${t('assignTo')}!` }]}
                >
                  <SearchInput placeholder={t('userEmail')} fetch={getOtherUserSearchInputList} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label={t('assignmentReason')} name='reason'>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label={t('notes')} name='notes'>
                  <TextArea style={{ width: '100%' }} rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <IconButton style={{ width: '9rem' }} htmlType='submit' className='assignButton'>
                  {t('assign')}
                </IconButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <h2>{title}</h2>
      </div>
      {getAssignmentForm()}
    </>
  );
};

export default React.memo(AssignManyTo);

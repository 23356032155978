import { message } from 'antd';
import { SearchInputData } from '../components/Common';
import { useUsersAPIClient } from './api';
import { useAuth0 } from './auth/Auth0Provider';

type UsersInfo = {
  getUserSearchInputList: (filter?: string) => Promise<Array<SearchInputData>>;
  getUsersByRoleSearchInputList: (roleId: string, filter?: string) => Promise<Array<SearchInputData>>;
  getOtherUserSearchInputList: (filter?: string) => Promise<Array<SearchInputData>>;
};

export const useUsersInfo = (): UsersInfo => {
  const usersAPIClient = useUsersAPIClient();
  const { getUserId } = useAuth0();

  const getUserSearchInputList = async (filter?: string): Promise<Array<SearchInputData>> => {
    const res = await usersAPIClient.list(filter);

    if (res.hasError) {
      message.error(`Ups! ${res.errorMessage}!`);
      return [];
    }

    if (!res.data) return [];

    return res.data.map(d => {
      return {
        value: d.email,
        text: `${d.fullName} (${d.email})`,
      };
    });
  };

  const getUsersByRoleSearchInputList = async (roleId: string, filter?: string): Promise<Array<SearchInputData>> => {
    const res = await usersAPIClient.listByRole(roleId, filter);

    if (res.hasError) {
      message.error(`Ups! ${res.errorMessage}!`);
      return [];
    }

    if (!res.data) return [];

    return res.data.map(d => {
      return {
        value: d.email,
        text: `${d.fullName} (${d.email})`,
      };
    });
  };

  const getOtherUserSearchInputList = async (filter?: string): Promise<Array<SearchInputData>> => {
    return (await getUserSearchInputList(filter)).filter(x => x.value !== getUserId());
  };

  return {
    getUserSearchInputList,
    getOtherUserSearchInputList,
    getUsersByRoleSearchInputList,
  };
};

import { useBaseAPIClient, APIRequestResult } from './index';
import {
  ProspectCompany,
  ProspectCompanyListItem,
  ProspectCompanyCreate,
  AdvancedSearchFilters,
  ProspectCompanyLinkedIn,
  ProspectCompanyLinkedInUpdate,
} from '../../components/ProspectCompanies/types';
import { KeyValuePair } from '../../components/Common';
import { TableSort } from '../../components/Common/types';
import { useTranslation } from 'react-i18next';

type ProspectCompaniesAPIClient = {
  get: (id: number) => Promise<APIRequestResult<ProspectCompany>>;
  create: (company: ProspectCompanyCreate) => Promise<APIRequestResult<ProspectCompany>>;
  update: (id: number, company: ProspectCompany) => Promise<APIRequestResult<ProspectCompany>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<ProspectCompanyListItem>>>;
  poolList: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<ProspectCompanyListItem>>>;
  addImage: (imageURI) => Promise<APIRequestResult>;
  asKeyValues: (filter?: string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  excluded: () => Promise<
    APIRequestResult<
      Array<{
        age: number;
        name: string;
        linkedInProfile: string;
        linkedInCompanyId: string;
      }>
    >
  >;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  poolCount: (filter?: string) => Promise<APIRequestResult<number>>;
  remove: (id: number) => Promise<APIRequestResult>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (id: number, status: number) => Promise<APIRequestResult>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  promoteCompany: (id: number) => Promise<APIRequestResult<void>>;
  pin: (prospectCompanyId: number) => Promise<APIRequestResult>;
  unpin: (prospectCompanyId: number) => Promise<APIRequestResult>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectCompanyListItem>>>;
  poolSearch: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectCompanyListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  poolSearchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  setMainContact: (prospectClientId: number, prospectCompanyId: number) => Promise<APIRequestResult>;
  contact: (
    prospectCompanyId: number,
    activityType: 'prospectCompanyInterested' | 'prospectCompanyNotInterested',
  ) => Promise<APIRequestResult>;
  hasPriority: (prospectCompanyId: number) => Promise<APIRequestResult>;
  updateCompanyLinkedInId: (
    prospectCompanyId: number,
    companyLinkedInId: number | string,
  ) => Promise<APIRequestResult<void>>;
  getByCompanyLinkedInId: (companyLinkedInId: string) => Promise<APIRequestResult<ProspectCompanyLinkedIn>>;
  getByCompanyLinkedInProfile: (companyLinkedInProfile: string) => Promise<APIRequestResult<ProspectCompanyLinkedIn>>;
  updateImportantComments: (prospectClientId: number, value: { value: string }) => Promise<APIRequestResult<void>>;
  updateProspectCompanyFromLinkedInExtension: (
    prospectCompanyId: number,
    value: ProspectCompanyLinkedInUpdate,
  ) => Promise<APIRequestResult<ProspectCompany>>;
};

export const useProspectCompaniesAPIClient = (origin?: string): ProspectCompaniesAPIClient => {
  const { t } = useTranslation();
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    buildSortUrl,
    uploadFileRequest,
    getFormatedToDate,
    getFormatedFromDate,
  } = useBaseAPIClient({ origin });

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<ProspectCompanyListItem>>> => {
    let url = buildPaginatedUrl('prospect-companies', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<ProspectCompanyListItem>>(url);
  };

  const poolList = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<ProspectCompanyListItem>>> => {
    let url = buildPaginatedUrl('prospect-companies/status/pool', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<ProspectCompanyListItem>>(url);
  };

  const asKeyValues = (filter?: string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'prospect-companies/askeyvalues';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-companies/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<number>(url);
  };

  const poolCount = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-companies/status/pool/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<number>(url);
  };

  const excluded = (): Promise<
    APIRequestResult<
      Array<{
        age: number;
        name: string;
        linkedInProfile: string;
        linkedInCompanyId: string;
      }>
    >
  > => {
    return getRequest<
      Array<{
        age: number;
        name: string;
        linkedInProfile: string;
        linkedInCompanyId: string;
      }>
    >(`prospect-companies/excluded`);
  };

  const get = (id: number): Promise<APIRequestResult<ProspectCompany>> => {
    return getRequest<ProspectCompany>(`prospect-companies/${id}`);
  };

  const create = (company: ProspectCompanyCreate): Promise<APIRequestResult<ProspectCompany>> => {
    return postRequest<ProspectCompany>('prospect-companies', company);
  };

  const remove = (id: number): Promise<APIRequestResult> => {
    return deleteRequest(`prospect-companies/${id}`);
  };

  const update = (id: number, company: ProspectCompany): Promise<APIRequestResult<ProspectCompany>> => {
    return putRequest<ProspectCompany>(`prospect-companies/${id}`, company);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>('prospect-companies/statuses');
  };

  const updateStatus = (id: number, status: number): Promise<APIRequestResult> => {
    return putRequest(`prospect-companies/${id}/status/${status}`);
  };

  const updateCompanyLinkedInId = (
    prospectCompanyId: number,
    companyLinkedInId: number | string,
  ): Promise<APIRequestResult<void>> => {
    return putRequest<void>(`prospect-companies/${prospectCompanyId}/linkedin-company-id/${companyLinkedInId}`);
  };

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> => {
    return uploadFileRequest(`prospect-companies/file`, file);
  };

  const pin = (prospectClientId: number): Promise<APIRequestResult> =>
    postRequest(`prospect-companies/${prospectClientId}/pin`);

  const unpin = (prospectClientId: number): Promise<APIRequestResult> =>
    postRequest(`prospect-companies/${prospectClientId}/unpin`);

  const addImage = (imageURI): Promise<APIRequestResult> => postRequest('prospect-companies/file/uri', { imageURI });

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectCompanyListItem>>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtFrom: getFormatedFromDate(advancedSearchFilters.createdAtFrom),
      createdAtTo: getFormatedToDate(advancedSearchFilters.createdAtTo),
      updatedAtFrom: getFormatedFromDate(advancedSearchFilters.updatedAtFrom),
      updatedAtTo: getFormatedToDate(advancedSearchFilters.updatedAtTo),
      ownedAtFrom: getFormatedFromDate(advancedSearchFilters.ownedAtFrom),
      ownedAtTo: getFormatedToDate(advancedSearchFilters.ownedAtTo),
    };
    const url = buildPaginatedUrl('prospect-companies/search', page, pageSize);

    return postRequest<Array<ProspectCompanyListItem>>(url, filters);
  };

  const poolSearch = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectCompanyListItem>>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtFrom: getFormatedFromDate(advancedSearchFilters.createdAtFrom),
      createdAtTo: getFormatedToDate(advancedSearchFilters.createdAtTo),
      updatedAtFrom: getFormatedFromDate(advancedSearchFilters.updatedAtFrom),
      updatedAtTo: getFormatedToDate(advancedSearchFilters.updatedAtTo),
      ownedAtFrom: getFormatedFromDate(advancedSearchFilters.ownedAtFrom),
      ownedAtTo: getFormatedToDate(advancedSearchFilters.ownedAtTo),
    };
    const url = buildPaginatedUrl('prospect-companies/search/status/pool', page, pageSize);

    return postRequest<Array<ProspectCompanyListItem>>(url, filters);
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtFrom: getFormatedFromDate(advancedSearchFilters.createdAtFrom),
      createdAtTo: getFormatedToDate(advancedSearchFilters.createdAtTo),
      updatedAtFrom: getFormatedFromDate(advancedSearchFilters.updatedAtFrom),
      updatedAtTo: getFormatedToDate(advancedSearchFilters.updatedAtTo),
      ownedAtFrom: getFormatedFromDate(advancedSearchFilters.ownedAtFrom),
      ownedAtTo: getFormatedToDate(advancedSearchFilters.ownedAtTo),
    };
    return postRequest<number>('prospect-companies/search/count', filters);
  };

  const poolSearchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtFrom: getFormatedFromDate(advancedSearchFilters.createdAtFrom),
      createdAtTo: getFormatedToDate(advancedSearchFilters.createdAtTo),
      updatedAtFrom: getFormatedFromDate(advancedSearchFilters.updatedAtFrom),
      updatedAtTo: getFormatedToDate(advancedSearchFilters.updatedAtTo),
      ownedAtFrom: getFormatedFromDate(advancedSearchFilters.ownedAtFrom),
      ownedAtTo: getFormatedToDate(advancedSearchFilters.ownedAtTo),
    };
    return postRequest<number>('prospect-companies/search/status/pool/count', filters);
  };

  const setMainContact = (prospectClientId: number, prospectCompanyId: number): Promise<APIRequestResult> =>
    putRequest(`/prospect-companies/${prospectCompanyId}/main-contact/${prospectClientId}`);

  const contact = (
    prospectCompanyId: number,
    activityType: 'prospectCompanyInterested' | 'prospectCompanyNotInterested',
  ): Promise<APIRequestResult> => {
    const payload = {
      text: t(activityType),
      type: 4,
    };

    return postRequest(`/prospect-companies/${prospectCompanyId}/activities`, payload);
  };

  const hasPriority = (prospectCompanyId: number): Promise<APIRequestResult> => {
    return postRequest(`/prospect-companies/${prospectCompanyId}/has-priority`);
  };

  const getByCompanyLinkedInId = (linkedInCompanyId: string): Promise<APIRequestResult<ProspectCompanyLinkedIn>> => {
    return getRequest<ProspectCompanyLinkedIn>(`/prospect-companies/linkedin-company-id/${linkedInCompanyId}`);
  };

  const getByCompanyLinkedInProfile = (
    linkedInCompanyProfile: string,
  ): Promise<APIRequestResult<ProspectCompanyLinkedIn>> => {
    return getRequest<ProspectCompanyLinkedIn>(`/prospect-companies/linkedin-profile/${linkedInCompanyProfile}`);
  };

  const updateImportantComments = (
    prospectCompanyId: number,
    value: { value: string },
  ): Promise<APIRequestResult<void>> => {
    return putRequest(`prospect-companies/${prospectCompanyId}/important-comments`, value);
  };

  const updateProspectCompanyFromLinkedInExtension = (
    prospectCompanyId: number,
    value: ProspectCompanyLinkedInUpdate,
  ): Promise<APIRequestResult<ProspectCompany>> =>
    putRequest(`prospect-companies/${prospectCompanyId}/linked-in-extension`, value);

  const promoteCompany = (id: number): Promise<APIRequestResult<void>> =>
    postRequest(`prospect-companies/${id}/promote`);

  return {
    list,
    poolList,
    count,
    poolCount,
    get,
    asKeyValues,
    create,
    remove,
    update,
    excluded,
    getStatuses,
    updateStatus,
    uploadFile,
    pin,
    unpin,
    search,
    searchCount,
    poolSearch,
    poolSearchCount,
    promoteCompany,
    setMainContact,
    contact,
    hasPriority,
    getByCompanyLinkedInId,
    getByCompanyLinkedInProfile,
    addImage,
    updateCompanyLinkedInId,
    updateImportantComments,
    updateProspectCompanyFromLinkedInExtension,
  };
};

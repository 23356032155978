import { useBaseAPIClient, APIRequestResult } from './index';
import { User } from '../../components/Common';

type UsersAPIClient = {
  acceptTermsOfService: () => Promise<APIRequestResult>;
  list: (filter?: string) => Promise<APIRequestResult<Array<User>>>;
  listByRole: (roleId: string, filter?: string) => Promise<APIRequestResult<Array<User>>>;
};

export const useUsersAPIClient = (origin?: string): UsersAPIClient => {
  const { getRequest, putRequest } = useBaseAPIClient({ origin });

  const acceptTermsOfService = (): Promise<APIRequestResult> => {
    const url = 'users/accept-terms-of-service';

    return putRequest(url);
  };

  const list = (filter?: string): Promise<APIRequestResult<Array<User>>> => {
    let url = 'users';
    if (filter) {
      url = `${url}?name=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<User>>(url);
  };

  const listByRole = (roleId: string, filter?: string): Promise<APIRequestResult<Array<User>>> => {
    let url = `users/role/${roleId}`;
    if (filter) {
      url = `${url}?name=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<User>>(url);
  };
  return {
    list,
    listByRole,
    acceptTermsOfService,
  };
};

type SocialLink = {
  extractSocialUserLinkedIn: (url: string) => string;
  extractSocialUserGitHub: (url: string) => string;
};

export const useSocialLink = (): SocialLink => {
  const extractSocialUserLinkedIn = (url: string): string => {
    let decodedUrl = decodeURIComponent(url).trim().toLowerCase().split('?')[0];

    if (decodedUrl.endsWith('/')) {
      decodedUrl = decodedUrl.slice(0, -1).trim();
    }

    if (!decodedUrl.includes('/')) return decodedUrl;

    const urlInput = decodedUrl.split('/');

    if (!urlInput.includes('in')) return decodedUrl;

    const inIndex = urlInput.indexOf('in');

    if (inIndex === null) return urlInput[urlInput.length - 1];

    if (urlInput.length < inIndex + 1) return urlInput[urlInput.length - 1];

    return urlInput[inIndex + 1];
  };

  const extractSocialUserGitHub = (url: string): string => {
    if (url.includes('/')) {
      const urlInput = url.split('/');
      if (urlInput[urlInput.length - 1] === '') {
        return urlInput[urlInput.length - 2];
      }
      return urlInput[urlInput.length - 1];
    }
    return decodeURIComponent(url);
  };

  return {
    extractSocialUserLinkedIn,
    extractSocialUserGitHub,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo_wshr_positivo.svg';
import './css/UserNotRegistered.css';

const UserNotRegistered: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id='UserNotRegistered'>
      <div id='InfoContainer'>
        <p className='title'>{t('notLoggedInTitle')}</p>
        <p className='message'>{t('notLoggedInMessage')}</p>
        <img src={logo} alt='Logo' className='ws-logo' />
      </div>
    </div>
  );
};

export default UserNotRegistered;

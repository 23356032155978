import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { useLoggly } from 'react-loggly-jslogger';
import { useTranslation } from 'react-i18next';
import httpMethods from 'http-methods-constants';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '../auth/Auth0Provider';
import { PeriodFilter, TableSortDirection } from '../../components/Common/types';
import dayjs, { Dayjs } from 'dayjs';
import { StatusCodes } from 'http-status-codes';
import { ORIGINS } from './constants';

export type APIRequestResult<T = undefined> = {
  hasError: boolean;
  errorMessage?: string;
  data?: T;
  status: number;
};

export type APIRequestHeader = {
  name: string;
  value?: string;
};

export type APIRequestConfig = {
  tenant?: string;
  baseUrl?: string;
  apiKey?: string;
  reCaptchaToken?: string;
  headers?: Array<APIRequestHeader>;
  ignoreBearerToken?: boolean;
};

type BaseAPIClient = {
  buildPaginatedUrl: (url: string, page?: number, pageSize?: number, requestConfig?: APIRequestConfig) => string;
  buildPeriodQuery: (period?: PeriodFilter) => string | undefined;
  buildQueryString: (queryKey: string, queryValue: Array<string | number>, isFirstURLQuery: boolean) => string;
  getFormatedFromDate: (date?: Dayjs | string) => string | undefined;
  getFormatedToDate: (date?: Dayjs | string) => string | undefined;
  buildSortUrl: (url: string, sortBy: string, sortDirection: TableSortDirection) => string;
  getRequest: <T>(url: string, requestConfig?: APIRequestConfig) => Promise<APIRequestResult<T>>;
  putRequest: <T>(url: string, payload?: any, requestConfig?: APIRequestConfig) => Promise<APIRequestResult<T>>;
  deleteRequest: <T>(url: string, requestConfig?: APIRequestConfig) => Promise<APIRequestResult<T>>;
  postRequest: <T>(url: string, payload?: any, requestConfig?: APIRequestConfig) => Promise<APIRequestResult<T>>;
  uploadFileRequest: <T>(
    url: string,
    file: File | Blob,
    requestConfig?: APIRequestConfig,
  ) => Promise<APIRequestResult<T>>;
};
interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useBaseAPIClient = ({ origin, userCompanyId }: Params = {}): BaseAPIClient => {
  const { getTokenSilently, getCurrentUserTenant } = useAuth0();
  const { error } = useLoggly();
  const { t } = useTranslation();

  const buildPaginatedUrl = (url: string, page?: number, pageSize?: number): string => {
    if (!page) {
      page = 0;
    } else {
      page -= 1;
    }

    if (!pageSize) pageSize = 30;

    return `${url}${url.includes('?') ? '&' : '?'}page=${page}&pageSize=${pageSize}`;
  };

  const buildSortUrl = (url: string, sortBy: string, sortDirection: TableSortDirection): string => {
    const sortDirectionConverter = () => {
      if (sortDirection === TableSortDirection.asc) return 'asc';
      if (sortDirection === TableSortDirection.desc) return 'desc';
    };

    const convertedSortDirection = sortDirectionConverter();

    if (sortBy && convertedSortDirection) {
      return `${url}${url.includes('?') ? '&' : '?'}sortBy=${sortBy}&sortDirection=${convertedSortDirection}`;
    }

    return url;
  };

  const getFormatedFromDate = (date?: Dayjs | string): string | undefined => {
    if (date) {
      date = dayjs(date);
      return date.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm');
    }
  };

  const getFormatedToDate = (date?: Dayjs | string): string | undefined => {
    if (date) {
      date = dayjs(date);
      return date.clone().startOf('day').add(1, 'd').utc().format('YYYY-MM-DD HH:mm');
    }
  };

  const buildPeriodQuery = (period?: PeriodFilter): string | undefined => {
    if (period) {
      return `from=${getFormatedFromDate(period.from)}&to=${getFormatedToDate(period.to)}`;
    }
  };

  const buildQueryString = (queryKey: string, queryValue: Array<string | number>, isFirstURLQuery: boolean) => {
    let url = '';

    queryValue.forEach((s, i) => {
      url += `${i === 0 && isFirstURLQuery ? '?' : '&'}${queryKey}=${encodeURIComponent(s)}`;
    });

    return url;
  };

  const getAxiosInstance = async (requestConfig?: APIRequestConfig): Promise<AxiosInstance> => {
    const token = requestConfig && requestConfig.ignoreBearerToken ? undefined : await getTokenSilently();
    const tenant = requestConfig && requestConfig.tenant ? requestConfig.tenant : getCurrentUserTenant();
    const baseUrl =
      requestConfig && requestConfig.baseUrl
        ? requestConfig.baseUrl
        : `${process.env.REACT_APP_WEBSTARTED_SERVER_URL}/api/`;

    const apiKey =
      requestConfig && requestConfig.apiKey ? requestConfig.apiKey : process.env.REACT_APP_WEBSTARTED_API_KEY;

    const headers = {
      Accept: 'application/json',
      Tenant: tenant,
      Authorization: '',
      reCaptchaToken: '',
    };

    headers['Content-Type'] = 'application/json';
    headers['X-API-KEY'] = apiKey;
    headers['X-REQUEST-ID'] = uuidv4();
    headers['X-REQUEST-ORIGIN'] = origin ?? ORIGINS.webstartedhr_app;

    if (userCompanyId) {
      //LEGACY
      headers['User-Company-Id'] = userCompanyId.toString();
    }

    if (requestConfig && requestConfig.reCaptchaToken) {
      headers.reCaptchaToken = requestConfig.reCaptchaToken;
    }

    if (requestConfig && requestConfig.headers && requestConfig.headers.length > 0) {
      requestConfig.headers.forEach(h => {
        headers[h.name] = h.value;
      });
    }

    headers.Authorization = token ? `Bearer ${token}` : '';

    return axios.create({
      baseURL: baseUrl,
      headers: headers,
    });
  };

  const tryRequest = async function tryRequest<T>(
    func: (u: string, axiosInstance: AxiosInstance) => Promise<AxiosResponse>,
    url: string,
    requestConfig?: APIRequestConfig,
    httpMethod?: string,
  ): Promise<APIRequestResult<T>> {
    try {
      const axiosInstance = await getAxiosInstance(requestConfig);

      if (httpMethod === httpMethods.GET) {
        url = url.indexOf('?') !== -1 ? `${url}&nocache=${uuidv4()}` : `${url}?nocache=${uuidv4()}`;
      }

      const res = await func(url, axiosInstance);

      const dataResult: APIRequestResult<T> = {
        hasError: false,
        data: res.data,
        status: res.status,
      };

      return dataResult;
    } catch (err: any) {
      if (err?.response?.status) {
        if (err.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          error(err, { requestUrl: url, data: err.response.data });
        }

        return {
          hasError: true,
          errorMessage: err?.response?.data,
          data: undefined,
          status: err?.response?.status,
        };
      }

      error(err, { requestUrl: url });

      return {
        hasError: true,
        errorMessage: t('errorMessage'),
        data: undefined,
        status: StatusCodes.INTERNAL_SERVER_ERROR,
      };
    }
  };

  const isFile = (file: File | Blob): file is File => {
    return (file as File).name !== undefined;
  };

  const uploadFileInternal = (url: string, file: File | Blob, axiosInstance: AxiosInstance): Promise<AxiosResponse> => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('contentType', file.type);
    fd.append('fileName', isFile(file) ? file.name : '');

    if (axiosInstance.defaults.headers) {
      axiosInstance.defaults.headers['Content-Type'] =
        'multipart/form-data; boundary=--------------------------075172086242397169703741';
      axiosInstance.defaults.headers.Accept = '*/*';
      // config.headers['Content-Length'] = file.size;
    }

    return axiosInstance.post(url, fd);
  };

  const uploadFileRequest = function uploadFileRequest<T>(
    url: string,
    file: File | Blob,
    requestConfig?: APIRequestConfig,
  ): Promise<APIRequestResult<T>> {
    return tryRequest<T>((u, c) => uploadFileInternal(u, file, c), url, requestConfig, httpMethods.POST);
  };

  const getRequest = function getRequest<T>(
    url: string,
    requestConfig?: APIRequestConfig,
  ): Promise<APIRequestResult<T>> {
    return tryRequest<T>((u, axiosInstance) => axiosInstance.get(u), url, requestConfig, httpMethods.GET);
  };

  const putRequest = function putRequest<T>(
    url: string,
    payload: any,
    requestConfig?: APIRequestConfig,
  ): Promise<APIRequestResult<T>> {
    return tryRequest<T>((u, axiosInstance) => axiosInstance.put(u, payload), url, requestConfig, httpMethods.PUT);
  };

  const deleteRequest = function deleteRequest<T>(
    url: string,
    requestConfig?: APIRequestConfig,
  ): Promise<APIRequestResult<T>> {
    return tryRequest<T>((u, axiosInstance) => axiosInstance.delete(u), url, requestConfig, httpMethods.DELETE);
  };

  const postRequest = function postRequest<T>(
    url: string,
    payload: any,
    requestConfig?: APIRequestConfig,
  ): Promise<APIRequestResult<T>> {
    return tryRequest<T>((u, axiosInstance) => axiosInstance.post(u, payload), url, requestConfig, httpMethods.POST);
  };

  return {
    buildPaginatedUrl,
    buildQueryString,
    buildSortUrl,
    getRequest,
    putRequest,
    deleteRequest,
    postRequest,
    uploadFileRequest,
    buildPeriodQuery,
    getFormatedFromDate,
    getFormatedToDate,
  };
};

import { useBaseAPIClient, APIRequestResult } from './index';
import {
  JobOfferListItem,
  JobOffer,
  UnappliedJobOffer,
  MatchingJobOffer,
  JobApplicationsAndJobOffersTotalCount,
  JobOfferRequirement,
  AdvancedSearchFilters,
  JobOfferCreate,
  MatchesFilters,
  Status,
  JobOfferApplyDetails,
  Question,
} from '../../components/JobOffers/types';
import { KeyValuePair } from '../../components/Common';
import { TableSort } from '../../components/Common/types';

type JobOffersAPIClient = {
  get: (id: number) => Promise<APIRequestResult<JobOffer>>;
  create: (jobOffer: JobOfferCreate) => Promise<APIRequestResult<JobOffer>>;
  duplicate: (id: number) => Promise<APIRequestResult<JobOffer>>;
  update: (id: number, jobOffer: JobOffer) => Promise<APIRequestResult<JobOffer>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<JobOfferListItem>>>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<JobOfferListItem>>>;
  asKeyValues: (
    filter?: string,
    companyIds?: Array<number>,
    status?: Status,
  ) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  statusAsKeyValues: (filter?: string, companyIds?: Array<number>) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  remove: (id: number) => Promise<APIRequestResult>;
  getUnappliedJobOffers: (
    candidateId: number,
    filter?: string,
    status?: Status,
  ) => Promise<APIRequestResult<Array<UnappliedJobOffer>>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getSubStatuses: (statusId: number) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getQuestions: (jobOfferId: number | string) => Promise<APIRequestResult<Array<Question>>>;
  updateStatus: (id: number, status: number, subStatus?: number) => Promise<APIRequestResult>;
  getMatches: (
    candidateId: number,
    filters: MatchesFilters,
    forceRanker: boolean,
  ) => Promise<APIRequestResult<Array<MatchingJobOffer>>>;
  matchesCount: (
    candidateId: number,
    forceRanker: boolean,
    status?: number,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  getMatchesCandidatePrivateProfile: (
    candidateId: number,
    filters: MatchesFilters,
  ) => Promise<APIRequestResult<Array<MatchingJobOffer>>>;
  matchesCountCandidatePrivateProfile: (
    candidateId: number,
    status?: number,
    filter?: string,
  ) => Promise<APIRequestResult<number>>;
  getMatchesWithoutId: (
    mainRoleId: number, 
    languages: Array<number>, 
    technologies: Array<number>, 
    otherSkillsAndTechnologies: string,
  ) => Promise<APIRequestResult<Array<MatchingJobOffer>>>;
  countActive: () => Promise<APIRequestResult<JobApplicationsAndJobOffersTotalCount>>;
  getRequirements: (id: number) => Promise<APIRequestResult<Array<JobOfferRequirement>>>;
  updateAllStatusTo: (companyId: number, status: number) => Promise<APIRequestResult>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  uploadFileFast: (file: File | Blob, companyId: number) => Promise<APIRequestResult<JobOffer>>;
  pinJobOffer: (jobOfferId: number) => Promise<APIRequestResult>;
  unpinJobOffer: (jobOfferId: number) => Promise<APIRequestResult>;
  pauseJobApplications: (jobOfferId: number) => Promise<APIRequestResult>;
  getApplyDetails: (
    id: number,
    tenant: string,
    reCaptchaToken: string,
  ) => Promise<APIRequestResult<JobOfferApplyDetails>>;
  getPublicJobOffers: (
    tenant: string,
    reCaptchaToken: string,
    page: number,
    pageSize?: number,
    filter?: string,
  ) => Promise<APIRequestResult<Array<JobOffer>>>;
};

interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useJobOffersAPIClient = ({ origin, userCompanyId }: Params = {}): JobOffersAPIClient => {
  const { putRequest, postRequest, getRequest, deleteRequest, buildPaginatedUrl, buildSortUrl, uploadFileRequest } =
    useBaseAPIClient({ origin, userCompanyId });

  const get = (id: number): Promise<APIRequestResult<JobOffer>> => getRequest<JobOffer>(`job-offers/${id}`);

  const create = (jobOffer: JobOfferCreate): Promise<APIRequestResult<JobOffer>> =>
    postRequest<JobOffer>(`job-offers`, jobOffer);

  const duplicate = (id: number): Promise<APIRequestResult<JobOffer>> =>
    postRequest<JobOffer>(`job-offers/${id}/duplicate`);

  const update = (id: number, jobOffer: JobOffer): Promise<APIRequestResult<JobOffer>> =>
    putRequest<JobOffer>(`job-offers/${id}`, jobOffer);

  const getRequirements = (id: number): Promise<APIRequestResult<Array<JobOfferRequirement>>> =>
    getRequest<Array<JobOfferRequirement>>(`job-offers/${id}/requirements`);

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<JobOfferListItem>>> => {
    let url = buildPaginatedUrl('job-offers', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<JobOfferListItem>>(url);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<JobOfferListItem>>> => {
    let url = buildPaginatedUrl('job-offers/search', page, pageSize);

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return postRequest<Array<JobOfferListItem>>(url, advancedSearchFilters);
  };

  const asKeyValues = (
    filter?: string,
    companyIds?: Array<number>,
    status?: Status,
  ): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'job-offers/as-key-values?a=a';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (companyIds) {
      url = `${url}${companyIds.map((r, index) => `&companyIds=${encodeURIComponent(r)}`).join('')}`;
    }

    if (status) {
      url = `${url}&status=${encodeURIComponent(status)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const statusAsKeyValues = (
    filter?: string,
    companyIds?: Array<number>,
  ): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'job-offers/status/as-key-values';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (companyIds) {
      url = `${url}${companyIds
        .map((r, index) => `${index === 0 ? '?' : '&'}companyIds=${encodeURIComponent(r)}`)
        .join('')}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>('job-offers/search/count', advancedSearchFilters);

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'job-offers/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }
    return getRequest<number>(url);
  };

  const remove = (id: number): Promise<APIRequestResult> => deleteRequest(`job-offers/${id}`);

  const getUnappliedJobOffers = (
    candidateId: number,
    filter?: string,
    status?: Status,
  ): Promise<APIRequestResult<Array<UnappliedJobOffer>>> => {
    let url = `job-offers/candidate/${candidateId}/unapplied?a=a`;

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (status) {
      url = `${url}&status=${encodeURIComponent(status)}`;
    }

    return getRequest<Array<UnappliedJobOffer>>(url);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('job-offers/statuses');

  const getSubStatuses = (statusId: number): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>(`job-offers/status/${statusId}/sub-statuses`);

  const getQuestions = (jobOfferId: number | string): Promise<APIRequestResult<Array<Question>>> => {
    return getRequest<Array<Question>>(`job-offers/${jobOfferId}/questions`);
  };

  const updateStatus = (id: number, status: number, subStatusId?: number): Promise<APIRequestResult> =>
    putRequest(`job-offers/${id}/status/${status}`, { subStatusId });

  const getMatches = (
    candidateId: number,
    filters: MatchesFilters,
    forceRanker: boolean,
  ): Promise<APIRequestResult<Array<MatchingJobOffer>>> => {
    return postRequest<Array<MatchingJobOffer>>(
      `job-offers/candidate/${candidateId}/match/unapplied?forceRanker=${forceRanker}`,
      filters,
    );
  };

  const matchesCount = (
    candidateId: number,
    forceRanker: boolean,
    status?: number,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const matchFilters = {
      status,
      filter,
    };
    return postRequest<number>(
      `job-offers/candidate/${candidateId}/match/unapplied/count?forceRanker=${forceRanker}`,
      matchFilters,
    );
  };

  const getMatchesCandidatePrivateProfile = (
    candidateId: number,
    filters: MatchesFilters,
  ): Promise<APIRequestResult<Array<MatchingJobOffer>>> => {
    return postRequest<Array<MatchingJobOffer>>(`job-offers/candidate-private-profile/${candidateId}/match`, filters);
  };

  const matchesCountCandidatePrivateProfile = (
    candidateId: number,
    status?: number,
    filter?: string,
  ): Promise<APIRequestResult<number>> => {
    const matchFilters = {
      status,
      filter,
    };
    return postRequest<number>(`job-offers/candidate-private-profile/${candidateId}/match/count`, matchFilters);
  };

  const getMatchesWithoutId = (
    mainRoleId: number, 
    languages: Array<number>, 
    technologies: Array<number>, 
    otherSkillsAndTechnologies: string,
  ): Promise<APIRequestResult<Array<MatchingJobOffer>>> => {
    const body = {
      mainRoleId: mainRoleId,
      languages: languages,
      technologies: technologies,
      otherSkillsAndTechnologies: otherSkillsAndTechnologies,
    };
    return postRequest<Array<MatchingJobOffer>>(`job-offers/candidates/match`, body);
  };

  const countActive = (): Promise<APIRequestResult<JobApplicationsAndJobOffersTotalCount>> =>
    getRequest<JobApplicationsAndJobOffersTotalCount>('job-offers/active/count');

  const updateAllStatusTo = (companyId: number, status: number, subStatusId?: number): Promise<APIRequestResult> => {
    return putRequest(`job-offers/company/${companyId}/status/${status}`, { subStatusId });
  };

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> =>
    uploadFileRequest(`job-offers/file`, file);

  const uploadFileFast = (file: File | Blob, companyId: number): Promise<APIRequestResult<JobOffer>> =>
    uploadFileRequest(`job-offers/company/${companyId}/fast-create`, file);

  const pinJobOffer = (jobOfferId: number): Promise<APIRequestResult> => postRequest(`job-offers/${jobOfferId}/pin`);

  const unpinJobOffer = (jobOfferId: number): Promise<APIRequestResult> =>
    postRequest(`job-offers/${jobOfferId}/unpin`);

  const pauseJobApplications = (jobOfferId: number): Promise<APIRequestResult> => {
    return putRequest(`job-offers/${jobOfferId}/job-applications/pause`);
  };

  const getApplyDetails = (
    id: number,
    tenant: string,
    reCaptchaToken: string,
  ): Promise<APIRequestResult<JobOfferApplyDetails>> => {
    const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
    };

    return getRequest<JobOfferApplyDetails>(`job-offers/${id}/apply-details`, config);
  };

  const getPublicJobOffers = (
    tenant: string,
    reCaptchaToken: string,
    page: number,
    pageSize = 1000,
    filter?: string,
  ): Promise<APIRequestResult<Array<JobOffer>>> => {
    const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
    };

    let url = `job-offers/public?page=${page}&pageSize=${pageSize}`;

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }
    return getRequest<Array<JobOffer>>(url, config);
  };
  return {
    get,
    create,
    duplicate,
    update,
    list,
    search,
    asKeyValues,
    statusAsKeyValues,
    count,
    searchCount,
    remove,
    getUnappliedJobOffers,
    getStatuses,
    getSubStatuses,
    getQuestions,
    updateStatus,
    getMatches,
    getMatchesCandidatePrivateProfile,
    matchesCount,
    matchesCountCandidatePrivateProfile,
    getMatchesWithoutId,
    countActive,
    getRequirements,
    updateAllStatusTo,
    uploadFile,
    uploadFileFast,
    pinJobOffer,
    unpinJobOffer,
    pauseJobApplications,
    getApplyDetails,
    getPublicJobOffers,
  };
};

import { useBaseAPIClient, APIRequestResult } from './index';
import { Activity, ActivityCreate } from '../../components/Common';

type ActivitiesAPIClient = {
  createActivity: (id: number, activity: ActivityCreate) => Promise<APIRequestResult<Activity>>;
  getActivities: (id: number, types?: Array<number>) => Promise<APIRequestResult<Array<Activity>>>;
};

export const useActivitiesAPIClient = (baseUrl: string, origin?: string): ActivitiesAPIClient => {
  const { postRequest, getRequest } = useBaseAPIClient({ origin });

  const createActivity = (id: number, activity: ActivityCreate): Promise<APIRequestResult<Activity>> =>
    postRequest(`${baseUrl}/${id}/activities`, activity);

  const getActivities = (id: number, types?: Array<number>): Promise<APIRequestResult<Array<Activity>>> => {
    let url = `${baseUrl}/${id}/activities`;

    if (types) {
      url += '?';
      types.forEach((type, index) => {
        url += index === 0 ? `types=${type}` : `&types=${type}`;
      });
    }

    return getRequest(url);
  };

  return {
    createActivity,
    getActivities,
  };
};

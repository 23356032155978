import React, { useState, useEffect } from 'react';
import { Upload, message, Modal, UploadFile } from 'antd';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { APIRequestResult } from '../../hooks/api';
import { useErrorMessageHandler } from '../../hooks';

import './css/ImagesUpload.css';

type ImagesUploadProps = {
  onUploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  onRemoveFile?: () => void;
  onChange?: (value?: Array<string>) => void;
  acceptedFormats?: Array<string>;
  maxCount?: number;
  maxSize?: { width: number; height: number };
  ratio?: Ratio;
  value?: Array<string>;
  title: string;
};

type ImageTrack = {
  name: string;
  url: string;
};

type PreviewFile = {
  previewVisible: boolean;
  url: string;
  type: string;
};

export enum Ratio {
  '1/1' = 1,
  '3/2' = 1.5,
  '5/4' = 1.25,
}

export const ImagesUpload: React.FC<ImagesUploadProps> = props => {
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { confirm } = Modal;
  const [filesTrack, setFilesTrack] = useState<Array<ImageTrack>>([]);
  const [filesList, setFilesList] = useState<Array<UploadFile> | null>(null);
  const [previewFile, setPreviewFile] = useState<PreviewFile>({
    previewVisible: false,
    url: '',
    type: '',
  });

  const getImageName = (imageUrl: string): string => {
    const lastPart = imageUrl.split('/').pop();
    if (!lastPart) return imageUrl;

    const fileNamePart = lastPart.split('_');
    const [, ...fileName] = fileNamePart;
    return fileName.join('_');
  };

  useEffect(() => {
    if (props.value) {
      const il = props.value.map(f => {
        return {
          uid: f,
          size: 0,
          type: '',
          name: getImageName(f),
          status: 'done',
          url: f,
          thumbUrl: f,
        } as UploadFile;
      });

      setFilesList(il);

      const it = props.value.map(f => {
        return {
          name: getImageName(f),
          url: f,
        } as ImageTrack;
      });

      setFilesTrack(it);
      return;
    }

    setFilesTrack([]);
    setFilesList([]);
  }, []);

  const validateAspectRatio = (width: number, height: number, ratio: Ratio): boolean => {
    const calculatedAspectRatio = width / height;
    return calculatedAspectRatio === ratio;
  };

  const getImageSize = (file: RcFile): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.addEventListener('load', event => {
        const _loadedImageUrl = event?.target?.result;
        const image = document.createElement('img');

        image.src = _loadedImageUrl as string;

        image.addEventListener('load', () => {
          const { width, height } = image;
          resolve({ width, height });
        });

        image.addEventListener('error', error => {
          reject(error);
        });
      });

      reader.addEventListener('error', error => {
        reject(error);
      });
    });
  };

  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    const isImage = file.type.includes('image');
    if (isImage) {
      const isLt1M = file.size / 1024 / 1024 < 1.1;

      if (!isLt1M) {
        message.error(`${t('imageSizeMessage')}!`);
        return false;
      }

      if (props?.maxSize || props?.ratio) {
        const { width, height } = await getImageSize(file);
        if (props?.maxSize && (props?.maxSize.width < width || props?.maxSize.height < height)) {
          message.error(t('imageSizeExceeded'));
          return false;
        }

        if (props?.ratio && !validateAspectRatio(width, height, props?.ratio)) {
          message.error(t('wrongRatio'));
          return false;
        }
      }

      if (props?.acceptedFormats) {
        const isValidFormat = props?.acceptedFormats.some(format => `image/${format}` === file.type);
        if (!isValidFormat) {
          message.error(t('wrongImageFormat'));
        }
      }

      return true;
    } else {
      const isPdfOrDoc =
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      if (!isPdfOrDoc) {
        message.error(`${t('uploadDocumentMessage')}!`);
        return false;
      }

      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isLt5M) {
        message.error(`${t('documentSizeMessage')}!`);
        return false;
      }

      return true;
    }
  };

  const customRequest = async (options): Promise<void> => {
    const res = await props.onUploadFile(options.file as Blob);
    if (res.hasError) {
      errorMessageHandler(res);
    }

    const filesUrls = filesTrack.map(it => it.url);

    if (res.data) {
      if (props.onChange) {
        props.onChange([res.data, ...filesUrls]);
      }

      setFilesTrack([{ name: res.data, url: res.data }, ...filesTrack]);
    }

    if (options.onSuccess) {
      options.onSuccess(res.data, new XMLHttpRequest());
    }
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewFile({ url: file.url || file.preview, previewVisible: true, type: file.type });
  };

  const onRemove = (file: UploadFile): void => {
    const currentFileTracks = filesTrack.filter(x => (file.response ? x.name !== file.response : x.name !== file.name));

    const filesUrls = currentFileTracks.map(it => it.url);

    if (props.onChange) {
      props.onChange(filesUrls);
    }

    props?.onRemoveFile && props.onRemoveFile();
    setFilesTrack(currentFileTracks);
  };

  const handleRemove = (file: UploadFile): Promise<boolean> => {
    return new Promise(resolve => {
      confirm({
        title: t('confirmDelete'),
        content: `${t(file.type?.includes('image') ? 'confirmDeleteImageMessage' : 'confirmDeleteFileMessage')}?`,
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk() {
          resolve(true);
          onRemove(file);
        },
        okText: t('yes'),
        cancelText: t('no'),
      });
    });
  };

  const handleCancel = () => setPreviewFile({ url: '', previewVisible: false, type: '' });

  const uploadButton = (
    <div className='upload-button'>
      <UploadOutlined style={{ fontSize: 20 }} />
      <div style={{ fontSize: 18 }}>{t('upload')}</div>
    </div>
  );

  return (
    <>
      {filesList === null ? (
        <></>
      ) : (
        <>
          <div id='ImagesUpload'>
            <div className='image-uploader'>
              <Upload
                name='imageUploader'
                accept='.pdf,.doc,.docx,image/*'
                listType='picture-card'
                maxCount={props?.maxCount}
                customRequest={options => {
                  customRequest(options);
                }}
                className='upload-list-inline'
                multiple={false}
                beforeUpload={beforeUpload}
                defaultFileList={filesList}
                onRemove={handleRemove}
                onPreview={handlePreview}
              >
                {props?.maxCount && filesTrack.length >= props?.maxCount ? null : uploadButton}
              </Upload>
            </div>
          </div>
          <Modal
            visible={previewFile.previewVisible}
            footer={null}
            onCancel={handleCancel}
            className='modal-preview-file'
          >
            {previewFile.type.includes('image') ? (
              <img alt='image-preview' style={{ width: '100%' }} src={previewFile.url} />
            ) : (
              <iframe src={previewFile.url} width='100%' height='500px' />
            )}
          </Modal>
        </>
      )}
    </>
  );
};

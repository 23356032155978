import { Locale } from 'antd/es/locale';
import enUS from 'antd/locale/en_US';
import esES from 'antd/locale/es_ES';

type UseBrowserLanguage = {
  getBrowserLanguage: () => string;
  getBrowserLocale: () => Locale;
};

export const useBrowserLanguage = (): UseBrowserLanguage => {
  const browserLang = navigator.language;

  const getBrowserLanguage = (): string => {
    if (browserLang.toLowerCase().includes('es')) return 'es';

    return 'en';
  };

  const getBrowserLocale = (): Locale => {
    if (browserLang.toLowerCase().includes('es')) return esES;

    return enUS;
  };

  return { getBrowserLanguage, getBrowserLocale };
};

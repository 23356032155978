import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo_wshr_positivo.svg';

import './css/AccessDenied.css';

export const AccessDenied: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <div id='AccessDenied' className='access-denied-container'>
      <div>{translate('accesDeniedMessage')}</div>
      <img src={logo} alt='Logo' className='ws-logo' />
    </div>
  );
};

export default AccessDenied;

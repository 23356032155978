import React from 'react'
import Flag, { FlagProps } from 'react-world-flags'

export const FlagWS: React.FC<FlagProps> = (props) => {

  const CustomFlag = Flag as React.FC;
  return (
    <CustomFlag {...props} />
  )
}

import { Badge, Col, Row } from 'antd';
import React from 'react';
import logo from '../../../../img/footer-logo.svg';
import { Link } from 'react-router-dom';
import { LinkedinFilled, MailFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import './css/Footer.css';

const Footer: React.FC = () => {
  const currentYear = dayjs().year();

  return (
    <div id='FooterHome'>
      <Row justify='space-between' align='middle' style={{ height: '90%' }}>
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link target='_blank' to={`${process.env.REACT_APP_WSHOME_URL}`}>
            <img src={logo} alt='logo' className='ws-logo' />
          </Link>
          <p style={{ marginTop: '15px', fontWeight: 100, marginBottom: 0 }}>By Webstarted.com</p>
        </Col>
        <Col className='social-link-container'>
          <Link target='_blank' to='https://linkedin.com/company/webstartedhr/' className='social-link'>
            <LinkedinFilled />
            <p style={{ margin: '0px 10px' }}>webstartedhr</p>
          </Link>
          <a href='mailto:hi@webstartedhr.com' className='social-link'>
            <MailFilled />
            <p style={{ margin: '0px 10px' }}>hi@webstartedhr.com</p>
          </a>
        </Col>
        <Col className='home-redirection-links-container'>
          <Link to='/features' className='text-link'>
            Features
          </Link>
          <Link to='/resources' className='text-link'>
            Resources
          </Link>
          <Link to='/pricing' className='text-link'>
            Pricing
          </Link>
          <Badge className='badge-line'>-</Badge>
          <Link to='/terms-of-service' className='text-link'>
            Terms of Service
          </Link>
          <Link to='/privacy-policy' className='text-link'>
            Privacy Policy
          </Link>
        </Col>
      </Row>
      <Row className='copyright' justify='center' align='middle'>
        <p>©{currentYear} WEBSTARTED - ALL RIGHTS RESERVED</p>
      </Row>
    </div>
  );
};

export default Footer;

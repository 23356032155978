import { useBaseAPIClient, APIRequestResult } from './index';
import {
  ProspectClient,
  ProspectClientCreate,
  ProspectClientUpdate,
  ProspectClientListItem,
  ProspectClientWithCompanyCreate,
  ProspectClientByLinkedInProfile,
  ProspectClientLinkedInUpdate,
} from '../../components/ProspectClients/types';
import { KeyValuePair } from '../../components/Common';
import { TableSort } from '../../components/Common/types';
import { AdvancedSearchFilters } from '../../components/ProspectClients/types';
type ProspectClientsAPIClient = {
  get: (id: number) => Promise<APIRequestResult<ProspectClient>>;
  getByCompany: (companyId: number) => Promise<APIRequestResult<Array<ProspectClient>>>;
  create: (client: ProspectClientCreate) => Promise<APIRequestResult<ProspectClient>>;
  createWithCompany: (client: ProspectClientWithCompanyCreate) => Promise<APIRequestResult<ProspectClient>>;
  addImage: (imageURI) => Promise<APIRequestResult>;
  update: (id: number, client: ProspectClientUpdate) => Promise<APIRequestResult<ProspectClient>>;
  getByLinkedInProfile: (linkedInProfile: string) => Promise<APIRequestResult<ProspectClientByLinkedInProfile>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<ProspectClientListItem>>>;
  poolList: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<ProspectClientListItem>>>;
  asKeyValues: (filter?: string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  poolCount: (filter?: string) => Promise<APIRequestResult<number>>;
  remove: (id: number) => Promise<APIRequestResult>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getSubStatuses: (statusId: number) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (id: number, status: number, subStatus?: number) => Promise<APIRequestResult>;
  updateFromLinkedInExtension: (
    prospectClientId: number,
    value: ProspectClientLinkedInUpdate,
  ) => Promise<APIRequestResult<ProspectClient>>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  uploadCSV: (file: File | Blob) => Promise<APIRequestResult<string>>;
  contact: (prospectClientId: number) => Promise<APIRequestResult>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectClientListItem>>>;
  poolSearch: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectClientListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  poolSearchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  pin: (prospectClientId: number) => Promise<APIRequestResult>;
  unpin: (prospectClientId: number) => Promise<APIRequestResult>;
  updateImportantComments: (prospectClientId: number, value: { value: string }) => Promise<APIRequestResult<void>>;
};

export const useProspectClientsAPIClient = (origin?: string): ProspectClientsAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    buildSortUrl,
    uploadFileRequest,
    getFormatedFromDate,
    getFormatedToDate,
  } = useBaseAPIClient({ origin });

  const formatAdvancedSearchFilters = (advancedSearchFilters: AdvancedSearchFilters): AdvancedSearchFilters => {
    const formatedAdvancedSearchFilters = { ...advancedSearchFilters };

    formatedAdvancedSearchFilters.createdAtFrom = getFormatedFromDate(advancedSearchFilters.createdAtFrom);
    formatedAdvancedSearchFilters.createdAtTo = getFormatedToDate(advancedSearchFilters.createdAtTo);
    formatedAdvancedSearchFilters.updatedAtFrom = getFormatedFromDate(advancedSearchFilters.updatedAtFrom);
    formatedAdvancedSearchFilters.updatedAtTo = getFormatedToDate(advancedSearchFilters.updatedAtTo);
    formatedAdvancedSearchFilters.ownedAtFrom = getFormatedFromDate(advancedSearchFilters.ownedAtFrom);
    formatedAdvancedSearchFilters.ownedAtTo = getFormatedToDate(advancedSearchFilters.ownedAtTo);

    return formatedAdvancedSearchFilters;
  };

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<ProspectClientListItem>>> => {
    let url = buildPaginatedUrl('prospect-clients', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<ProspectClientListItem>>(url);
  };

  const poolList = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<ProspectClientListItem>>> => {
    let url = buildPaginatedUrl('prospect-clients/status/pool', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<ProspectClientListItem>>(url);
  };

  const getByLinkedInProfile = (linkedInProfile: string): Promise<APIRequestResult<ProspectClientByLinkedInProfile>> =>
    getRequest<ProspectClientByLinkedInProfile>(`prospect-clients/linkedInProfile/${linkedInProfile}`);

  const addImage = (imageURI): Promise<APIRequestResult> => postRequest('prospect-clients/file/uri', { imageURI });

  const asKeyValues = (filter?: string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'prospect-clients/askeyvalues';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-clients/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<number>(url);
  };

  const poolCount = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-clients/status/pool/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<number>(url);
  };

  const get = (id: number): Promise<APIRequestResult<ProspectClient>> =>
    getRequest<ProspectClient>(`prospect-clients/${id}`);

  const getByCompany = (companyId: number): Promise<APIRequestResult<Array<ProspectClient>>> =>
    getRequest<Array<ProspectClient>>(`prospect-clients/prospect-company/${companyId}`);

  const create = (prospectClient: ProspectClientCreate): Promise<APIRequestResult<ProspectClient>> =>
    postRequest<ProspectClient>('prospect-clients', prospectClient);

  const createWithCompany = (prospectClient: ProspectClientWithCompanyCreate) =>
    postRequest<ProspectClient>('prospect-clients/with-company', prospectClient);

  const remove = (id: number): Promise<APIRequestResult> => deleteRequest(`prospect-clients/${id}`);

  const update = (id: number, client: ProspectClientUpdate): Promise<APIRequestResult<ProspectClient>> =>
    putRequest<ProspectClient>(`prospect-clients/${id}`, client);

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('prospect-clients/statuses');

  const getSubStatuses = (statusId: number): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>(`prospect-clients/status/${statusId}/sub-statuses`);

  const updateStatus = (id: number, status: number, subStatus?: number): Promise<APIRequestResult> =>
    putRequest(`prospect-clients/${id}/status/${status}`, { subStatus });

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> =>
    uploadFileRequest('prospect-clients/file', file);

  const uploadCSV = (file: File | Blob): Promise<APIRequestResult<string>> =>
    uploadFileRequest('prospect-clients/csv/upload', file);

  const contact = (id: number): Promise<APIRequestResult> => {
    return putRequest(`/prospect-clients/${id}/contact`);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectClientListItem>>> => {
    return postRequest<Array<ProspectClientListItem>>(
      buildPaginatedUrl('prospect-clients/search', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const poolSearch = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectClientListItem>>> => {
    return postRequest<Array<ProspectClientListItem>>(
      buildPaginatedUrl('prospect-clients/search/status/pool', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>('prospect-clients/search/count', formatAdvancedSearchFilters(advancedSearchFilters));

  const poolSearchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>(
      'prospect-clients/search/status/pool/count',
      formatAdvancedSearchFilters(advancedSearchFilters),
    );

  const pin = (prospectClientId: number): Promise<APIRequestResult> =>
    postRequest(`prospect-clients/${prospectClientId}/pin`);

  const unpin = (prospectClientId: number): Promise<APIRequestResult> =>
    postRequest(`prospect-clients/${prospectClientId}/unpin`);

  const updateFromLinkedInExtension = (
    prospectClientId: number,
    value: ProspectClientLinkedInUpdate,
  ): Promise<APIRequestResult<ProspectClient>> =>
    putRequest(`prospect-clients/${prospectClientId}/linked-in-extension`, value);

  const updateImportantComments = (
    prospectClientId: number,
    value: { value: string },
  ): Promise<APIRequestResult<void>> => {
    return putRequest(`prospect-clients/${prospectClientId}/important-comments`, value);
  };

  return {
    list,
    count,
    get,
    getByCompany,
    asKeyValues,
    getByLinkedInProfile,
    create,
    createWithCompany,
    addImage,
    remove,
    update,
    getStatuses,
    getSubStatuses,
    updateStatus,
    updateFromLinkedInExtension,
    uploadFile,
    uploadCSV,
    contact,
    search,
    searchCount,
    poolList,
    poolCount,
    poolSearch,
    poolSearchCount,
    pin,
    unpin,
    updateImportantComments,
  };
};

import React, { ReactNode, useEffect } from 'react';
import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';
import { Modules, Pages } from '../../../routerTypes';
import { Roles } from '../../../hooks/auth/types';
import './css/Layout.css';

const HomeLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated, userIsInRole, loading } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (userIsInRole(Roles.companyAdministrator)) {
        navigate(`${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}`);
      } else if (userIsInRole(Roles.salesAgent)) {
        navigate(Pages[Modules.prospectCompanies].path);
      } else if (userIsInRole(Roles.talentNetworkCandidate)) {
        navigate(`${Pages[Modules.talentNetwork].path}/profile`);
      } else {
        navigate(Pages[Modules.candidates].path);
      }
    }
  }, [loading, isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (loading || isAuthenticated) {
    return;
  }

  return (
    <div id='LayoutHome'>
      <NavBar />
      <Content style={{ paddingTop: '80px', minHeight: '100vh' }}>{children}</Content>
      <Footer />
    </div>
  );
};

export default HomeLayout;

import { Environment } from '../components/Common/types';

type UseEnvironment = {
  isProduction: () => boolean;
  isDevelopment: () => boolean;
  isStaging: () => boolean;
};

export const useEnvironment = (): UseEnvironment => {
  const isProduction = (): boolean => {
    return process.env.REACT_APP_ENVIRONMENT === Environment.production;
  };

  const isStaging = (): boolean => {
    return process.env.REACT_APP_ENVIRONMENT === Environment.staging;
  };

  const isDevelopment = (): boolean => {
    return process.env.REACT_APP_ENVIRONMENT === Environment.development;
  };

  return { isDevelopment, isStaging, isProduction };
};

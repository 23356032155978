import React from 'react';

type ViewportContextProps = {
  width: number;
  height: number;
  isMobile: () => boolean;
  isTablet: () => boolean;
  isDesktop: () => boolean;
  isBreakpoint: (b: number) => boolean;
};

const viewportContext = React.createContext<ViewportContextProps>({
  width: 0,
  height: 0,
  isMobile: () => false,
  isTablet: () => false,
  isDesktop: () => true,
  isBreakpoint: () => true,
});

export const ViewportProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  function isMobile(): boolean {
    return width <= 480;
  }

  function isTablet(): boolean {
    return width <= 768;
  }

  function isDesktop(): boolean {
    return width >= 992;
  }

  function isBreakpoint(breakpointWidthLocal: number): boolean {
    return width > breakpointWidthLocal;
  }

  return (
    <viewportContext.Provider value={{ width, height, isMobile, isTablet, isDesktop, isBreakpoint }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = (): ViewportContextProps => {
  const { width, height, isMobile, isTablet, isDesktop, isBreakpoint } = React.useContext(viewportContext);
  return { width, height, isMobile, isTablet, isDesktop, isBreakpoint };
};

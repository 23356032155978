import { InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, BadgeProps, Calendar, CalendarProps, Col, Divider, Row, Skeleton, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { InterviewType } from '../../Interviews/types';
import { Modules, Pages } from '../../../routerTypes';
import { SelectForEnum } from '../../Common';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';
import { useProspectClientMeetingsAPIClient } from '../../../hooks/api/ProspectClientMeetingsAPIClient';
import { MeetingType, ProspectClientMeetingListItem } from '../../ProspectClients/Meetings/types';

import '../css/InterviewsSummary.css';

interface MeetingsSummaryProps {
  openCalendar: boolean;
}

const MeetingsSummary: React.FC<MeetingsSummaryProps> = ({ openCalendar }) => {
  const [meetings, setMeetings] = useState<Array<ProspectClientMeetingListItem>>();
  const [meeting, setMeeting] = useState<Array<ProspectClientMeetingListItem>>();
  const [showMyMeetings, setShowMyMeetings] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(dayjs());
  const [selectedType, setSelectedType] = useState<InterviewType | undefined>(undefined);
  const [meetingsPerDayCount, setMeetingsPerDayCount] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth0();
  const { t } = useTranslation();

  const prospectClientsMeetingsPIClient = useProspectClientMeetingsAPIClient();

  const getListData = (value: Dayjs) => {
    let listData;

    const data = meetings?.find(int => dayjs(int.scheduledAt).format('DD-MM') === value.format('DD-MM'));

    if (data) {
      listData = [{ type: 'success', content: '' }];
    }
    return listData || [];
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className='modal-interview-event'>
        {listData.map(item => (
          <li key={item.content} className='modal-interview-event-item'>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const getMeetings = async (from: Dayjs, to: Dayjs) => {
    setIsLoading(true);

    const res = await prospectClientsMeetingsPIClient.search(1, 1000, {
      statuses: [],
      prospectClientStatus: [],
      source: [],
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
    });

    if (res.data) {
      const data = res.data;
      setMeetings(data);
      setIsLoading(false);
      if (selectedDay) {
        const day = selectedDay.format('YYYY-MM-DD');
        setMeeting(data?.filter(int => dayjs(int.scheduledAt).format('YYYY-MM-DD') === day) || undefined);
      }
    }
  };

  const countMeetingsPerDay = async () => {
    const res = await prospectClientsMeetingsPIClient.searchCount({
      statuses: [],
      prospectClientStatus: [],
      source: [],
      from: selectedDay?.format('YYYY-MM-DD'),
      to: selectedDay?.format('YYYY-MM-DD'),
      organizerUserId: showMyMeetings ? user.email : undefined,
    });

    return setMeetingsPerDayCount(Number(res.data));
  };

  useEffect(() => {
    countMeetingsPerDay();
    getMeetings(selectedMonth.startOf('month'), selectedMonth.endOf('month'));
  }, [selectedDay, showMyMeetings, selectedType]);

  useEffect(() => {
    if (openCalendar && !meetings) {
      getMeetings(selectedMonth.startOf('month'), selectedMonth.endOf('month'));
    }
  }, [openCalendar]);

  const disabledDate = (current: Dayjs) => {
    return current.month() !== selectedMonth.month();
  };

  const getFilteredMeetings = (
    showMyInterviews?: boolean,
    meetingType?: number,
  ): Array<ProspectClientMeetingListItem> => {
    if (!meeting || meeting.length === 0) return [];

    const filteredByOwner = meeting.filter(meeting => {
      const isInterviewer = meeting.organizerUserId?.includes(user.email);
      return showMyInterviews ? isInterviewer : meeting;
    });

    if (meetingType) {
      return filteredByOwner.filter(int => int.type === meetingType);
    }

    return filteredByOwner;
  };

  return (
    <>
      {meetings ? (
        <Calendar
          cellRender={cellRender}
          fullscreen={false}
          onSelect={day => {
            setSelectedDay(day);
            const dayFormat = day.format('YYYY-MM-DD');
            setMeeting(meeting?.filter(int => dayjs(int.scheduledAt).format('YYYY-MM-DD') === dayFormat) || undefined);
          }}
          mode='month'
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const month = value.month();
            const currentMonth = dayjs().month();

            return (
              <Row align='middle' justify='space-between'>
                <Col>
                  <p className='month-title'>
                    {value.format('MMMM')} {value.format('YYYY')}
                  </p>
                </Col>
                <Col>
                  <Row gutter={20}>
                    <Col>
                      <LeftOutlined
                        size={26}
                        style={{ color: month === currentMonth ? '#d2d2d2' : undefined }}
                        disabled={month === currentMonth}
                        onClick={
                          month !== currentMonth
                            ? () => {
                                const prevMonth = value.clone().subtract(1, 'month');
                                getMeetings(prevMonth.startOf('month'), prevMonth.endOf('month'));
                                setSelectedMonth(prevMonth);
                                onChange(prevMonth);
                              }
                            : undefined
                        }
                      />
                    </Col>
                    <Col>
                      <RightOutlined
                        size={26}
                        onClick={() => {
                          const nextMonth = value.clone().add(1, 'month');
                          getMeetings(nextMonth.startOf('month'), nextMonth.endOf('month'));
                          setSelectedMonth(nextMonth);
                          onChange(nextMonth);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
          disabledDate={disabledDate}
        />
      ) : (
        <Skeleton />
      )}
      <div className='list-container'>
        <Row justify='space-between' align='middle'>
          <Col>
            {!showMyMeetings && (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className='circle-owner'></span>
                <p style={{ margin: 0, fontWeight: 700 }}>{t('owner')}</p>
              </div>
            )}
          </Col>
          <Col>
            <Row gutter={12} align='middle'>
              <Col>
                <Switch value={showMyMeetings} onChange={v => setShowMyMeetings(v)} />
              </Col>
              <Col>
                <span style={{ marginRight: 5, fontWeight: 700 }}>{t('myMeetings')}</span>
                <Tooltip title={t('myMeetingsMessage')}>
                  <InfoCircleOutlined style={{ marginRight: 10 }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify='end'>
          <SelectForEnum
            style={{ width: '170px', marginTop: '10px' }}
            placeholder={`${t('select')}`}
            enumType={MeetingType}
            onChange={type => setSelectedType(Number(type))}
          />
        </Row>

        <Divider style={{ margin: '10px 0 15px 0' }} />
        {meetingsPerDayCount && meetingsPerDayCount > 0 ? (
          <p>{`${meetingsPerDayCount} ${meetingsPerDayCount > 1 ? t('meetings') : t('meeting')}`}</p>
        ) : (
          ''
        )}

        {isLoading ? (
          <Skeleton />
        ) : getFilteredMeetings(showMyMeetings, selectedType)?.length ? (
          <ul style={{ listStyle: 'none', padding: 0, height: 200 }}>
            {getFilteredMeetings(showMyMeetings, selectedType)?.map(meet => (
              <li
                key={meet.id}
                className='int-item'
                style={{ border: meet.organizerUserId?.includes(user.email) ? '2px solid #28b6e387' : '' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div className='day'>{dayjs(meet.scheduledAt).format('D')}</div>
                  <div className='month'>{dayjs(meet.scheduledAt).format('MMM')}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: '150px',
                    paddingLeft: '5px',
                  }}
                >
                  <div style={{ fontWeight: 600 }}>
                    <a
                      href={`${Pages[Modules.prospectClients].path}/${meet.prospectClientId}/edit`}
                      style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {meet.prospectClientName}
                    </a>
                  </div>
                  <div style={{ fontWeight: 200 }}>{dayjs(meet.scheduledAt).format('HH:mm')}Hs</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    borderLeft: '1px solid #c6c6c6',
                    paddingLeft: '15px',
                    minWidth: 250,
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: 500,
                        color: 'rgb(33,148,184)',
                      }}
                    >
                      {t('prospectClient')}:
                    </span>{' '}
                    {meet?.prospectClientName ? meet?.prospectClientName : '-'}
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 500,
                        color: 'rgb(33,148,184)',
                      }}
                    >
                      {t('organizer')}:{' '}
                    </span>
                    {meet.organizerUserName?.length ? meet.organizerUserName : '-'}
                  </div>
                  <div>
                    <span
                      style={{
                        fontWeight: 500,
                        color: 'rgb(33,148,184)',
                      }}
                    >
                      {t('ownedBy')}:{' '}
                    </span>
                    {meet.prospectClientOwnedByUserName ? meet.prospectClientOwnedByUserName : '-'}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80%',
            }}
          >
            <p className='no-meetings'>No upcoming meetings</p>
            <span style={{ fontSize: 40 }}>&#127881;</span>
          </div>
        )}
      </div>
    </>
  );
};

export default MeetingsSummary;

import { useBaseAPIClient, APIRequestResult } from './BaseAPIClient';
import {
  AdvancedSearchFilters,
  Interview,
  InterviewCreate,
  InterviewSession,
  EndInterviewSession,
  UpdateInterviewSession,
  InterviewListItem,
  InterviewTechnologyCount,
  Template,
  TemplateCreate,
} from '../../components/Interviews/types';
import { KeyValuePair } from '../../components/Common/types';
import { PageDefaultValues } from '../../constants/DefaultTheme';

type InterviewsAPIClient = {
  list: (
    candidateId: number,
    page: number,
    pageSize: number,
    filter?: string,
  ) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  count: (candidateId: number, filter?: string) => Promise<APIRequestResult<number>>;
  getNextList: (page: number, pageSize: number, filter?: string) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  countNext: (filter?: string) => Promise<APIRequestResult<number>>;
  getPastList: (page: number, pageSize: number, filter?: string) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  countPast: (filter?: string) => Promise<APIRequestResult<number>>;
  get: (id: number) => Promise<APIRequestResult<Interview>>;
  create: (interview: InterviewCreate) => Promise<APIRequestResult<Interview>>;
  remove: (id: number) => Promise<APIRequestResult>;
  update: (id: number, interview: Interview) => Promise<APIRequestResult<Interview>>;
  updateStatus: (id: number, statusId: number) => Promise<APIRequestResult>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getNextTechnologiesCount: () => Promise<APIRequestResult<Array<InterviewTechnologyCount>>>;
  getPastTechnologiesCount: () => Promise<APIRequestResult<Array<InterviewTechnologyCount>>>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  searchPast: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  searchNext: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<InterviewListItem>>>;
  searchPastCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  searchNextCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  startSession: (id: number) => Promise<APIRequestResult>;
  getSession: (id: number) => Promise<APIRequestResult<InterviewSession>>;
  endSession: (id: number, session: EndInterviewSession) => Promise<APIRequestResult>;
  updateSession: (id: number, session: UpdateInterviewSession) => Promise<APIRequestResult<InterviewSession>>;
  updateFinishedSession: (
    id: number,
    session: { notes?: string; score: number },
  ) => Promise<APIRequestResult<InterviewSession>>;
  getTemplatesList: (page: number, pageSize: number, filter?: string) => Promise<APIRequestResult<Array<Template>>>;
  getTemplate: (id: number) => Promise<APIRequestResult<Template>>;
  updateTemplate: (id: number, template: TemplateCreate) => Promise<APIRequestResult<Template>>;
  createTemplate: (template: TemplateCreate) => Promise<APIRequestResult<Template>>;
  deleteTemplate: (id: number) => Promise<APIRequestResult<Template>>;
  getKeyValueTemplates: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  countTemplate: () => Promise<APIRequestResult<number>>;
  pinTemplate: (id: number) => Promise<APIRequestResult<void>>;
  unpinTemplate: (id: number) => Promise<APIRequestResult<void>>;
};

interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useInterviewsAPIClient = ({ origin, userCompanyId }: Params = {}): InterviewsAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    getFormatedFromDate,
    getFormatedToDate,
  } = useBaseAPIClient({ origin, userCompanyId });

  const formatAdvancedSearchFilters = (advancedSearchFilters: AdvancedSearchFilters) => {
    const formatedAdvancedSearchFilters = { ...advancedSearchFilters };

    formatedAdvancedSearchFilters.from = getFormatedFromDate(advancedSearchFilters.from);
    formatedAdvancedSearchFilters.to = getFormatedToDate(advancedSearchFilters.to);

    return formatedAdvancedSearchFilters;
  };

  const list = (
    candidateId: number,
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    let url = buildPaginatedUrl(`interviews/candidate/${candidateId}`, page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const count = (candidateId: number, filter?: string): Promise<APIRequestResult<number>> => {
    let url = `interviews/candidate/${candidateId}/count`;

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const getNextList = (
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    let url = buildPaginatedUrl('interviews/next', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const countNext = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'interviews/next/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const getPastList = (
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    let url = buildPaginatedUrl('interviews/past', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    return postRequest<Array<InterviewListItem>>(
      buildPaginatedUrl('interviews/search', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchCount = (
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    return postRequest<Array<InterviewListItem>>(
      buildPaginatedUrl('interviews/search/count'),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchPast = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    return postRequest<Array<InterviewListItem>>(
      buildPaginatedUrl('interviews/search/past', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchNext = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<InterviewListItem>>> => {
    return postRequest<Array<InterviewListItem>>(
      buildPaginatedUrl('interviews/search/next', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const countPast = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'interviews/past/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const searchPastCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>('interviews/search/past/count', formatAdvancedSearchFilters(advancedSearchFilters));

  const searchNextCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>('interviews/search/next/count', formatAdvancedSearchFilters(advancedSearchFilters));

  const get = (id: number): Promise<APIRequestResult<Interview>> => {
    return getRequest<Interview>(`interviews/${id}`);
  };

  const create = (interview: InterviewCreate): Promise<APIRequestResult<Interview>> => {
    return postRequest<Interview>('interviews', interview);
  };

  const remove = (id: number): Promise<APIRequestResult> => {
    return deleteRequest(`interviews/${id}`);
  };

  const update = (id: number, interview: Interview): Promise<APIRequestResult<Interview>> => {
    return putRequest<Interview>(`interviews/${id}`, interview);
  };

  const updateStatus = (id: number, statusId: number): Promise<APIRequestResult> => {
    return putRequest(`interviews/${id}/status/${statusId}`);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>('interviews/statuses');
  };

  const getNextTechnologiesCount = (): Promise<APIRequestResult<Array<InterviewTechnologyCount>>> => {
    return getRequest<Array<InterviewTechnologyCount>>('interviews/next/technologies/count');
  };

  const getPastTechnologiesCount = (): Promise<APIRequestResult<Array<InterviewTechnologyCount>>> => {
    return getRequest<Array<InterviewTechnologyCount>>('interviews/past/technologies/count');
  };

  const getSession = (id: number): Promise<APIRequestResult<InterviewSession>> => {
    return getRequest(`interviews/${id}/session`);
  };

  const startSession = (id: number): Promise<APIRequestResult> => {
    return putRequest(`interviews/${id}/session/start`);
  };

  const updateSession = (id: number, session: UpdateInterviewSession): Promise<APIRequestResult<InterviewSession>> => {
    return putRequest(`interviews/${id}/session`, session);
  };

  const updateFinishedSession = (
    id: number,
    session: { notes?: string; score: number },
  ): Promise<APIRequestResult<InterviewSession>> => {
    const payload = { notes: session.notes, score: session.score };
    return putRequest(`interviews/${id}/session/finished`, payload);
  };

  const endSession = (id: number, session: EndInterviewSession): Promise<APIRequestResult> => {
    return putRequest(`interviews/${id}/session/end`, session);
  };

  const getTemplatesList = (
    page: number,
    pageSize: number,
    filter?: string,
  ): Promise<APIRequestResult<Array<Template>>> => {
    let url = buildPaginatedUrl('interviews/templates', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }
    return getRequest(url);
  };

  const getTemplate = (id: number): Promise<APIRequestResult<Template>> => {
    return getRequest(`interviews/templates/${id}`);
  };

  const updateTemplate = (id: number, template: TemplateCreate): Promise<APIRequestResult<Template>> => {
    return putRequest(`interviews/templates/${id}`, template);
  };

  const createTemplate = (template: TemplateCreate): Promise<APIRequestResult<Template>> => {
    return postRequest('interviews/templates', template);
  };

  const deleteTemplate = (id: number): Promise<APIRequestResult<Template>> => {
    return deleteRequest(`interviews/templates/${id}`);
  };

  const getKeyValueTemplates = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest('interviews/templates/as-key-values');
  };

  const countTemplate = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'interviews/templates/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const pinTemplate = (id: number): Promise<APIRequestResult<void>> => {
    return postRequest(`interviews/templates/${id}/pin`);
  };

  const unpinTemplate = (id: number): Promise<APIRequestResult<void>> => {
    return postRequest(`interviews/templates/${id}/unpin`);
  };

  return {
    list,
    count,
    getNextList,
    countNext,
    getPastList,
    countPast,
    get,
    create,
    remove,
    update,
    updateStatus,
    getStatuses,
    getNextTechnologiesCount,
    getPastTechnologiesCount,
    search,
    searchCount,
    searchPast,
    searchNext,
    searchPastCount,
    searchNextCount,
    startSession,
    endSession,
    updateSession,
    updateFinishedSession,
    getSession,
    getTemplatesList,
    getTemplate,
    updateTemplate,
    createTemplate,
    deleteTemplate,
    getKeyValueTemplates,
    countTemplate,
    pinTemplate,
    unpinTemplate,
  };
};

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    whitelist: ['en', 'es'],
    load: 'unspecific',
    lng: 'en',
    backend: {
      loadPath: `/assets/i18n/{{ns}}/{{lng}}.json?cb=${
        process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : new Date().getTime()
      }`,
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
});

dayjs.updateLocale('es', {
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
});

export default i18n;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo_wshr_positivo.svg';
import { Button, Col, Drawer, Row } from 'antd';
import { SolutionOutlined, SwapOutlined } from '@ant-design/icons';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { PrimaryButton } from '../Common';
import './css/TenantDisabled.css';

export const TenantDisabled: React.FC = () => {
  const { t: translate } = useTranslation();
  const { getUserTenantsInfo, getCurrentUserTenant, userIsAllowed, setCurrentUserTenant } = useAuth0();

  const [tenantsVisible, setTenantsVisible] = useState<boolean>(false);
  const [userTenants, setUserTenants] = useState<Array<{ customer: string; tenant: string; plan: string }>>([]);
  const [currentTenant, setCurrentTenant] = useState<string | null>(null);

  useEffect(() => {
    const ct = getCurrentUserTenant();

    if (ct) {
      setCurrentTenant(ct);
    }

    const userTenantsInfo = getUserTenantsInfo();

    if (userTenantsInfo) {
      setUserTenants(userTenantsInfo);
    }
  }, []);

  const showTenants = (): void => {
    setTenantsVisible(true);
  };

  const closeTenants = (): void => {
    setTenantsVisible(false);
  };

  const onTenantSwitch = (tenant: string): void => {
    setCurrentUserTenant(tenant);
    setTenantsVisible(false);
    window.sessionStorage.clear();

    window.location.href = '/';
  };

  return (
    <div id='TenantDisabled' className='tenant-disabled-container'>
      <div>
        <p className='msg-tenant'>{translate('tenantDisabledMessage')}.</p>
        <p className='please-contact'>
          {translate('pleaseContact')}{' '}
          <a className='please-contact-link' href={'mailto:support@webstartedhr.com'}>
            {'support@webstartedhr.com.'}
          </a>
        </p>
      </div>
      {userIsAllowed() && userTenants?.length > 1 && (
        <Row justify='center' style={{ marginTop: '1rem' }}>
          <Col>
            <PrimaryButton className='switch-tenant' onClick={() => showTenants()} icon={<SwapOutlined />}>
              {translate('switchTenant')}
            </PrimaryButton>
          </Col>
          <Drawer
            title='Switch tenant'
            placement='right'
            closable={false}
            onClose={closeTenants}
            visible={tenantsVisible}
            getContainer='.tenant-drawer-container'
            style={{ position: 'absolute', overflowX: 'hidden' }}
          >
            <ul key={currentTenant}>
              {userTenants
                .filter(t => t.tenant !== currentTenant)
                .map(t => (
                  <li key={t.tenant}>
                    <Button type='link' className='switch-tenant' onClick={() => onTenantSwitch(t.tenant)}>
                      <div>
                        <SolutionOutlined /> {t.customer}
                      </div>
                    </Button>
                  </li>
                ))}
            </ul>
          </Drawer>
        </Row>
      )}
      <img src={logo} alt='Logo' className='ws-logo' />
    </div>
  );
};

export default TenantDisabled;

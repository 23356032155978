import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { LogglyProvider } from 'react-loggly-jslogger';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import AppRouter from './router';
import { useAuth0 } from './hooks/auth/Auth0Provider';
import './utils/i18n';
import { WSHRSplash } from './components/Common';
import { ViewportProvider } from './hooks/UseViewport';
import { SharedContextProvider } from './hooks/UseSharedContext';
import { NotificationsContextProvider } from './hooks/UseNotificationsContext';
import { useBrowserLanguage } from './hooks/UseBrowserLanguage';
import { clarity } from 'react-microsoft-clarity';
import 'dayjs/locale/es';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import TagManager from 'react-gtm-module';
import { useEnvironment } from './hooks/UseEnvironment';

dayjs.extend(weekday);
dayjs.extend(localeData);

import './css/App.less';

export const App: React.FC = () => {
  const { getBrowserLanguage, getBrowserLocale } = useBrowserLanguage();
  const lang = getBrowserLanguage();
  const locale = getBrowserLocale();
  const { isProduction } = useEnvironment();

  const { loading, user, isAuthenticated } = useAuth0();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.location.hostname.startsWith('www.')) {
      window.location.href = window.location.href.replace('www.', '');
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
    dayjs.locale(lang);

    if (isProduction()) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      }

      if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
        };

        TagManager.initialize(tagManagerArgs);
      }

      if (process.env.REACT_APP_CLARITY_ID) {
        clarity.init(process.env.REACT_APP_CLARITY_ID);

        if (clarity.hasStarted()) {
          clarity.identify(user?.email ? user.email : 'NON_LOGGED_USER', { userProperty: 'email' });
        }
      }
    }
  }, []);

  if (loading) {
    return <WSHRSplash />;
  }

  const options = {};

  const providers = {
    user: (instance, key): any => {
      return {
        [key]: user?.email,
      };
    },
  };

  return (
    <div id='main'>
      <LogglyProvider options={options} providers={providers}>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: '#28b6e3',
              colorFillSecondary: '#56CCF2',
              colorTextSecondary: '#FFF',
            },
          }}
        >
          <SharedContextProvider>
            <ViewportProvider>
              <NotificationsContextProvider>
                <AppRouter isAuthenticated={isAuthenticated} />
              </NotificationsContextProvider>
            </ViewportProvider>
          </SharedContextProvider>
        </ConfigProvider>
      </LogglyProvider>
    </div>
  );
};

import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';

type TooltipForEllipsisProps = {
  title: string;
  style?: React.CSSProperties;
};

export const TooltipForEllipsis: React.FC<TooltipForEllipsisProps> = ({ title, style }) => {
  const [ellipsis, setEllipsis] = useState(false);
  const { Paragraph } = Typography;

  const getParagraph = () => (
    <Paragraph style={style} ellipsis={{ onEllipsis: setEllipsis }}>
      {title}
    </Paragraph>
  );

  return (
    <>
      {ellipsis ? (
        <Tooltip title={title} placement='topLeft'>
          {getParagraph()}
        </Tooltip>
      ) : (
        getParagraph()
      )}
    </>
  );
};

export default TooltipForEllipsis;

import { useTranslation } from 'react-i18next';
import { NumberInput } from '.';

interface ExperienceYearsInputProps {
  value?: number;
  readOnly?: boolean;
  onChange?: (value: string | number | null) => void | undefined;
}

export const ExperienceYearsInput: React.FC<ExperienceYearsInputProps> = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  const getDecimalExperienceYears = (v): string => {
    if (!v) return '';

    const stringValue = v.toString();

    if (stringValue.endsWith('.')) {
      const split = stringValue.split('.');

      if (split.length > 2) {
        return `${split[0]}.${split[1]}`;
      }

      return stringValue;
    }

    const numberValue = Number(stringValue);

    if (Number.isNaN(numberValue)) return '';

    if (numberValue === 0) return '';

    return stringValue && Number.isInteger(numberValue) ? numberValue.toString() : numberValue.toFixed(1).toString();
  };

  return (
    <NumberInput
      placeholder={t('yoe')}
      min={0.5}
      formatter={v => getDecimalExperienceYears(v)}
      precision={1}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default ExperienceYearsInput;

import { useAuth0 } from './auth/Auth0Provider';

type RTMTenantHandler = {
  handleRTMTenant: (payload: RTMPayload, callback: (data) => any) => void;
};

type RTMPayload = {
  tenant: string;
  data: any;
};

const useRTMTenantHandler = (): RTMTenantHandler => {
  const { getCurrentUserTenant } = useAuth0();
  const currentTenant = getCurrentUserTenant();

  const handleRTMTenant = (payload: RTMPayload, callback: (data) => any): void => {
    if (!payload.tenant || payload.tenant === currentTenant) {
      return callback(payload.data);
    }
    return;
  };
  return { handleRTMTenant };
};

export default useRTMTenantHandler;

import { useCallback } from 'react';

interface SalaryFormatterProps {
  salaryRangeFormat: (value: string | number | undefined) => string | number | undefined;
}

export const useSalaryFormatter = (): SalaryFormatterProps => {
  const salaryRangeFormat = useCallback((value: string | number | undefined) => {
    if (!value) {
      return;
    }

    const num = typeof value === 'string' ? parseInt(value) : value;

    return num > 999 ? `${(num / 1000).toFixed(1).replace(/\.?0+$/, '')}k` : value;
  }, []);

  return { salaryRangeFormat };
};

import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const usePagination = (customPageSize?: number) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(customPageSize ?? 30);
  const [pageReseted, setPageReseted] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const setPage = (page: number) => {
    setPageNumber(page);
    setPageQuery(page);
  };

  const resetPage = () => {
    setPage(1);
    setPageReseted(v => !v);
  };

  const initPagination = (page?: number) => {
    if (page) {
      setPage(page);
      setPageReseted(v => !v);
    } else {
      const qs = queryString.parse(location.search);
      const qsPage = qs.page?.toString();

      if (qsPage) {
        setPage(parseInt(qsPage));
        setPageReseted(v => !v);
      } else {
        resetPage();
      }
    }
  };

  const setPageQuery = (page: number) => {
    const params = new URLSearchParams(location.search);

    params.delete('page');

    if (params.toString().length > 0) {
      navigate(`${location.pathname}?${params}&page=${page}`, { replace: true });
    } else {
      navigate(`${location.pathname}?page=${page}`, { replace: true });
    }
  };

  return { pageSize, pageNumber, pageReseted, setPage, resetPage, initPagination };
};

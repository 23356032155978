import React, { useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Avatar, Modal, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import InterviewsSummary from './InterviewsSummary';
import MeetingsSummary from './MeetingsSummary';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';
import { PermissionType, TargetType } from '../../../routerTypes';

const CalendarComponent: React.FC = () => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const { userHasPermission } = useAuth0();
  const hasInterviewsPermission = userHasPermission(`${PermissionType.read}:${TargetType.interviews}`);
  const hasProspectClientPermission = userHasPermission(`${PermissionType.read}:${TargetType.prospectClients}`);

  const items: TabsProps['items'] = [
    { key: '1', label: 'Interviews', children: <InterviewsSummary openCalendar={openCalendar} /> },
    { key: '2', label: 'Meetings', children: <MeetingsSummary openCalendar={openCalendar} /> },
  ];

  const onShowItems = (): TabsProps['items'] => {
    if (hasProspectClientPermission && !hasInterviewsPermission) {
      return [{ key: '1', label: 'Meetings', children: <MeetingsSummary openCalendar={openCalendar} /> }];
    }
    if (hasInterviewsPermission && !hasProspectClientPermission) {
      return [{ key: '1', label: 'Interviews', children: <InterviewsSummary openCalendar={openCalendar} /> }];
    }

    return items;
  };

  return (
    <div id='InterviewsSummary'>
      <Avatar
        shape='square'
        className='avatar-calendar'
        size='large'
        onClick={() => setOpenCalendar(true)}
        icon={<CalendarOutlined style={{ fontSize: '20px', color: '#f2f2f2' }} />}
      />
      <Modal
        className='interviews-summary-modal'
        open={openCalendar}
        onCancel={() => setOpenCalendar(false)}
        footer={null}
      >
        <Tabs defaultActiveKey='1' items={onShowItems()} />
      </Modal>
    </div>
  );
};

export default CalendarComponent;

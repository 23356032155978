import { Button, Col, Drawer, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useViewport } from '../../../../hooks';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import logo from '../../../../img/logo_wshr_positivo.svg';
import NavBarMenu from './NavBarMenu/NavBarMenu';
import './css/NavBar.css';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { width } = useViewport();

  useEffect(() => {
    width > 908 && setVisible(false);
  }, [width]);

  const onClose = (): void => {
    setVisible(false);
  };

  const onShow = (): void => {
    setVisible(true);
  };

  return (
    <>
      <nav id='MenuBar'>
        <Row className='menuBarContainer'>
          <Col flex='150px'>
            <div className='logoNav'>
              <Link to='/'>
                <img src={logo} alt='Logo' className='logoNav' />
              </Link>
            </div>
          </Col>
          <Col flex='auto'>
            <div className='mainMenu'>
              <NavBarMenu mode='horizontal' />
            </div>
          </Col>
          <Col flex='40px' id='navBar-menu-button'>
            <Button className='barsMenu' onClick={onShow}>
              <span className='barsBtn'>
                <MenuOutlined />
              </span>
            </Button>
          </Col>
        </Row>
      </nav>
      <Drawer
        style={{ zIndex: 9999 }}
        placement='right'
        closable={false}
        onClose={onClose}
        open={visible}
        className='mobileMenuDrawer'
      >
        <CloseOutlined className='closeDrawerIcon' onClick={onClose} />
        <NavBarMenu mode='vertical' />
      </Drawer>
    </>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { Menu, Badge, Dropdown, Avatar } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useNotificationsContext } from '../../hooks';
import NotificationList from './NotificationList';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { Plans } from '../../hooks/auth/types';
import './css/Notifications.css';

export const Notifications: React.FC = () => {
  const [notificationsVisible, setNotificationsVisible] = useState<boolean>(false);

  const { userIsInPlan } = useAuth0();
  const { notificationsCount } = useNotificationsContext();

  const onOpenNotifications = (visible: boolean): void => {
    setNotificationsVisible(visible);

    if (!visible) return;
  };

  const menu = (
    <Menu className='menu' key='NotificationsMenu'>
      <div className='dropdown-container'>
        <NotificationList onReadAllNotifications={() => setNotificationsVisible(false)} />
      </div>
    </Menu>
  );

  if (userIsInPlan(Plans.silver)) {
    return <></>;
  }

  return (
    <div id='NotificationsCount'>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement='bottom'
        className='dropdown'
        onVisibleChange={onOpenNotifications}
        visible={notificationsVisible}
      >
        <a onClick={(e): void => e.preventDefault()} href='#self'>
          <Badge color='#28b6e3' style={{ boxShadow: 'none' }} count={notificationsCount} offset={[-3, 7]}>
            <Avatar
              shape='square'
              className='avatar-notifications'
              size='large'
              icon={<BellOutlined style={{ fontSize: '20px', color: '#f2f2f2' }} />}
            />
          </Badge>
        </a>
      </Dropdown>
    </div>
  );
};

export default Notifications;

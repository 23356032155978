import React from 'react';
import { List } from 'antd';

import './css/IconPicker.css';
import CustomIcon from './CustomIcon';

const iconsArray = [
  { name: 'Send' },
  { name: 'FolderView' },
  { name: 'Rocket' },
  { name: 'Meh' },
  { name: 'Laptop' },
  { name: 'Frown' },
  { name: 'Fire' },
  { name: 'Dollar' },
  { name: 'Environment' },
  { name: 'CustomerService' },
  { name: 'Crown' },
  { name: 'Dashboard' },
  { name: 'Experiment' },
  { name: 'Coffee' },
  { name: 'Compass' },
  { name: 'Clear' },
  { name: 'Car' },
  { name: 'Smile' },
  { name: 'Bug' },
  { name: 'Bulb' },
  { name: 'Audio' },
  { name: 'Heart' },
  { name: 'Hourglass' },
  { name: 'Rest' },
  { name: 'Robot' },
  { name: 'ShoppingCart' },
  { name: 'Sound' },
  { name: 'Tool' },
  { name: 'Trophy' },
  { name: 'Wallet' },
];

type IconPickerProps = {
  value?: string;
  onChange?(value: string): void;
};

export const IconPicker: React.FC<IconPickerProps> = ({ value, onChange, ...rest }) => {
  return (
    <div id='IconPicker'>
      <List
        grid={{ gutter: 2, column: 8 }}
        bordered
        dataSource={iconsArray}
        renderItem={item => (
          <List.Item
            onClick={() => onChange && onChange(item.name)}
            className={value && item.name === value ? 'selected' : 'unselected'}
          >
            <CustomIcon type={item.name} />
          </List.Item>
        )}
        {...rest}
      />
    </div>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { useLoggly } from 'react-loggly-jslogger';
import { useNavigate } from 'react-router-dom';
import { Plans } from './types';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const { error } = useLoggly();
  const navigate = useNavigate();

  const tenantsFullInfoNamespace = 'http://schemas.webstartedhr.com/identity/claims/tenants';
  const rolesNamespace = 'http://schemas.webstartedhr.com/identity/claims/roles';
  const metadataNamespace = 'http://schemas.webstartedhr.com/identity/claims/app_metadata';

  const onRedirectCallback = appState => {
    navigate(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const clientData = {
        domain: initOptions.domain,
        clientId: initOptions.client_id,
        authorizationParams: {
          redirect_uri: initOptions.redirect_uri,
          audience: initOptions.audience,
          onRedirect: initOptions.onRedirectCallback,
        },
      };
      const auth0FromHook = await createAuth0Client(clientData);
      setAuth0(auth0FromHook);

      if (
        window.location.href.includes('?callback') &&
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        // Get permissions, metadata and roles from decodedToken
        const decodedToken = await getDecodedToken(auth0FromHook);

        user.metadata = decodedToken[metadataNamespace];

        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const getDecodedToken = async client => {
    const jwtToken = await client.getTokenSilently();
    const jwtData = jwtToken.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);

    return JSON.parse(decodedJwtJsonData);
  };

  const getCurrentTenantName = () => {
    try {
      const userTenants = user[tenantsFullInfoNamespace];
      let tenant = localStorage.getItem('current_tenant');
      const tenantInfo = userTenants.find(t => t.tenant === tenant);

      if (tenant && tenantInfo) {
        return tenantInfo.customer;
      }

      return null;
    } catch (err) {
      error(err);
    }
  };

  const getCurrentTenantFullInfo = () => {
    try {
      const tenantsFullInfo = user[tenantsFullInfoNamespace];

      if (!tenantsFullInfo) return null;

      const currentUserTenant = getCurrentUserTenant();
      const currentTenantFullInfo = tenantsFullInfo.filter(t => t.name === currentUserTenant);

      if (!currentTenantFullInfo) return null;

      return currentTenantFullInfo[0];
    } catch (err) {
      return null;
    }
  };

  const getUserPlan = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      return currentTenantFullInfo?.plan ?? Plans.silver;
    } catch (err) {
      error(err);
    }
  };

  const getUserRoles = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      if (currentTenantFullInfo && currentTenantFullInfo.roles) return currentTenantFullInfo.roles;

      return [];
    } catch (err) {
      error(err);
    }
  };

  const getUserAcceptedTermsOfService = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      return currentTenantFullInfo?.acceptedTermsOfService ?? false;
    } catch (err) {
      error(err);
      return false;
    }
  };

  const getUserId = () => {
    try {
      return user.email;
    } catch (err) {
      error(err);
    }
  };

  const isUserActive = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      return currentTenantFullInfo?.userIsActive ?? true;
    } catch (err) {
      error(err);
      return true;
    }
  };

  const isTenantActive = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      return currentTenantFullInfo?.tenantIsActive ?? true;
    } catch (err) {
      error(err);
      return true;
    }
  };

  const isUserRegistered = () => {
    const userPermissions = getUserPermissions();

    if (userPermissions?.length > 0) {
      return true;
    }

    const userRoles = getUserRoles();

    return userRoles?.length > 0;
  };

  const userIsInRole = role => {
    try {
      if (Array.isArray(role)) {
        return role.some(userIsInRole);
      }

      const roles = getUserRoles();

      if (roles && roles.length > 0 && role) return roles.includes(role);

      return false;
    } catch (err) {
      error(err);
      return false;
    }
  };

  const userIsInPlan = plan => {
    try {
      if (Array.isArray(plan)) {
        return plan.some(userIsInPlan);
      }

      const plans = getUserPlan();

      if (plans && plans.length > 0 && plan) return plans.includes(plan);

      return false;
    } catch (err) {
      error(err);
      return false;
    }
  };

  const userIsAllowed = () => {
    try {
      const roles = getUserRoles();

      if (roles && roles.length > 0) return true;

      return false;
    } catch (err) {
      error(err);
      return false;
    }
  };

  const getUserPermissions = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      if (currentTenantFullInfo && currentTenantFullInfo.permissions) return currentTenantFullInfo.permissions;

      return [];
    } catch (err) {
      error(err);
    }
  };

  const getAppMetadata = () => {
    try {
      return user.metadata;
    } catch (err) {
      error(err);
    }
  };

  const getUserTenants = () => {
    try {
      const tenantsFullInfo = user[tenantsFullInfoNamespace];
      if (tenantsFullInfo) return tenantsFullInfo.map(t => t.name);

      const appMetadata = getAppMetadata();
      if (appMetadata && appMetadata.tenants) return appMetadata.tenants;
      return [];
    } catch (err) {
      error(err);
    }
  };

  const getUserTenantsInfo = () => {
    try {
      const tenantsFullInfo = user[tenantsFullInfoNamespace];
      if (tenantsFullInfo) return tenantsFullInfo.map(t => ({ customer: t.customer, tenant: t.tenant, plan: t.plan }));
      return [];
    } catch (err) {
      error(err);
    }
  };

  const setCurrentUserTenant = tenant => {
    try {
      const userTenants = getUserTenants();
      if (userTenants.some(t => t === tenant)) {
        localStorage.setItem('current_tenant', tenant);
      }
    } catch (err) {
      error(err);
    }
  };

  const getCurrentUserTenant = () => {
    try {
      const userTenants = getUserTenants();
      let tenant = localStorage.getItem('current_tenant');

      if (tenant && userTenants.some(t => t === tenant)) {
        return tenant;
      }

      if (userTenants.length > 0) {
        [tenant] = userTenants;
        setCurrentUserTenant(tenant);
        return tenant;
      }

      return null;
    } catch (err) {
      error(err);
    }
  };

  const isWebStartedTenant = () => {
    const currentTenant = getCurrentUserTenant();

    return currentTenant === 'webstarted' || currentTenant === 'webstartedHR';
  };

  const getCurrentUserCompanyId = () => {
    try {
      const currentTenantFullInfo = getCurrentTenantFullInfo();

      if (currentTenantFullInfo) return currentTenantFullInfo.companyId;

      return null;
    } catch (err) {
      error(err);
    }
  };

  const userHasPermission = permission => {
    try {
      const permissions = getUserPermissions();
      return permissions.some(p => p === permission);
    } catch (err) {
      error(err);
    }
  };

  const userHasPermissions = () => {
    try {
      const permissions = getUserPermissions();
      return permissions.length > 0;
    } catch (err) {
      error(err);
    }
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (err) {
      error(err);
    } finally {
      setPopupOpen(false);
    }

    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const getTokenSilently = params => {
    try {
      if (!isAuthenticated) return null;

      if (!params) {
        params = {};
      }

      params.access_type = 'offline';
      params.scope = 'offline_access';
      params.approval_prompt = 'force';

      return auth0Client.getTokenSilently(params);
    } catch (err) {
      error(err);
    }
  };

  const loginWithRedirect = params => {
    try {
      params.access_type = 'offline';
      params.scope = 'offline_access';
      params.approval_prompt = 'force';
      return auth0Client.loginWithRedirect(params);
    } catch (err) {
      error(err);
    }
  };

  const userHasAccess = (permissions, roles, excludedRoles, plans, excludedPlans, webstartedTenant) => {
    if (!isAuthenticated) return false;

    if (excludedPlans && excludedPlans.length > 0) {
      const userPlan = getUserPlan();

      if (userPlan) {
        const isExcluded = excludedPlans.some(p => p === userPlan);

        if (isExcluded) return false;
      }
    }

    if (excludedRoles && excludedRoles.length > 0) {
      const userRoles = getUserRoles();

      if (userRoles && userRoles.length > 0) {
        const isExcluded = excludedRoles.some(r => userRoles.indexOf(r) >= 0);

        if (isExcluded) return false;
      }
    }

    if (plans && plans.length > 0) {
      const userPlan = getUserPlan();

      if (userPlan) {
        const hasPlan = plans.some(p => p === userPlan);

        if (!hasPlan) return false;
      }
    }

    if (webstartedTenant) {
      const isWSTenant = isWebStartedTenant();
      if (isWSTenant === false) {
        return false;
      }
    }

    if (!permissions && !roles) return true;

    if (roles && roles.length > 0) {
      const userRoles = getUserRoles();

      if (userRoles && userRoles.length > 0) {
        const hasRoles = roles.some(r => userRoles.indexOf(r) >= 0);

        if (hasRoles) return true;
      }
    }

    if (permissions && permissions.length > 0) {
      const userPermissions = getUserPermissions();

      if (userPermissions && userPermissions.length > 0) {
        const hasPermission = permissions.some(r => userPermissions.indexOf(r) >= 0);

        if (hasPermission) return true;
      }
    }

    return false;
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setIsAuthenticated(true);
    setUser(user);
    setLoading(false);
  };

  const logout = async (params = {}) => {
    setLoading(true);
    setIsAuthenticated(false);
    localStorage.removeItem('current_tenant');
    window.sessionStorage.clear();
    const logoutParams = {
      returnTo: process.env.REACT_APP_AUTH_CONFIG_LOGOUT_REDIRECTURL,
    };
    params.logoutParams = logoutParams;
    await auth0Client.logout(params);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getUserAcceptedTermsOfService: () => getUserAcceptedTermsOfService(),
        isUserRegistered: () => isUserRegistered(),
        getUserPlan: () => getUserPlan(),
        getUserRoles: () => getUserRoles(),
        userIsAllowed: () => userIsAllowed(),
        userIsInRole: (...p) => userIsInRole(...p),
        userIsInPlan: (...p) => userIsInPlan(...p),
        userHasAccess: (...p) => userHasAccess(...p),
        getUserPermissions: () => getUserPermissions(),
        getCurrentUserCompanyId: () => getCurrentUserCompanyId(),
        getAppMetadata: () => getAppMetadata(),
        getCurrentTenantName: () => getCurrentTenantName(),
        getUserTenants: () => getUserTenants(),
        getUserTenantsInfo: () => getUserTenantsInfo(),
        setCurrentUserTenant: (...p) => setCurrentUserTenant(...p),
        getCurrentUserTenant: () => getCurrentUserTenant(),
        isWebStartedTenant: () => isWebStartedTenant(),
        getUserId: () => getUserId(),
        userHasPermission: (...p) => userHasPermission(...p),
        userHasPermissions: () => userHasPermissions(),
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => loginWithRedirect(...p),
        getTokenSilently: (...p) => getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        isUserActive: () => isUserActive(),
        isTenantActive: () => isTenantActive(),
        logout: (...p) => logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

const getDocumentName = (documentUrl: string): string => {
  const decodedUrl = decodeURIComponent(documentUrl);

  const lastPart = decodedUrl.split('/').pop();
  if (!lastPart) return decodedUrl;

  const fileNamePart = lastPart.split('_');
  const [, ...fileName] = fileNamePart;
  return fileName.join('_');
};

export default getDocumentName;

import { useCallback } from 'react';
import { Tag, Tooltip } from 'antd';
import { TechLogoSize } from './types';

type TechnologiesLogoProps = {
  techName: string;
  logoSize?: TechLogoSize;
  onClick: () => void;
};

export enum TechType {
  android = 'Android',
  angular = 'Angular',
  amazonwebservices = 'AWS',
  azure = 'Azure',
  css = 'CSS',
  csharp = 'C#',
  cplusplus = 'C++',
  dotnet = '.NET',
  dotnetcore = '.NET Core',
  flutter = 'Flutter',
  googlecloud = 'Google Cloud',
  go = 'GO Lang',
  html = 'HTML',
  ios = 'iOS',
  java = 'Java',
  javascript = 'Javascript',
  kotlin = 'Kotlin',
  kubernetes = 'Kubernetes',
  laravel = 'Laravel',
  reactjs = 'React.js',
  ruby = 'Ruby',
  magento = 'Magento',
  mongo = 'MongoDB',
  mysql = 'MySQL',
  nextjs = 'NextJS',
  nodejs = 'Node.JS',
  php = 'Php',
  python = 'Python',
  salesforce = 'Salesforce',
  scala = 'Scala',
  unity = 'Unity3D',
  vuejs = 'Vue.js',
  woocommerce = 'WooCommerce',
  wordpress = 'WordPress',
}

export const TechnologiesLogo: React.FC<TechnologiesLogoProps> = ({ techName, logoSize, onClick }) => {
  const techSwitcher = useCallback(tech => {
    switch (tech.toLowerCase().trim()) {
      case TechType.android.toLowerCase():
        return 'android-plain-wordmark';
      case TechType.angular.toLowerCase():
        return 'angularjs-plain';
      case TechType.amazonwebservices.toLowerCase():
        return 'amazonwebservices-plain-wordmark';
      case TechType.azure.toLowerCase():
        return 'azure-plain';
      case TechType.csharp.toLowerCase():
        return 'csharp-plain';
      case TechType.css.toLowerCase():
        return 'css3-plain-wordmark';
      case TechType.flutter.toLowerCase():
        return 'flutter-plain';
      case TechType.googlecloud.toLowerCase():
        return 'googlecloud-plain-wordmark';
      case TechType.go.toLowerCase():
        return 'go-plain';
      case TechType.html.toLowerCase():
        return 'html5-plain-wordmark';
      case TechType.ios.toLowerCase():
        return 'apple-original';
      case TechType.java.toLowerCase():
        return 'java-plain-wordmark';
      case TechType.javascript.toLowerCase():
        return 'javascript-plain';
      case TechType.kotlin.toLowerCase():
        return 'kotlin-plain';
      case TechType.kubernetes.toLowerCase():
        return 'kubernetes-plain-wordmark';
      case TechType.laravel.toLowerCase():
        return 'laravel-plain-wordmark';
      case TechType.cplusplus.toLowerCase():
        return 'cplusplus-plain-wordmark';
      case TechType.dotnet.toLowerCase():
        return 'dot-net-plain-wordmark';
      case TechType.dotnetcore.toLowerCase():
        return 'dotnetcore-plain';
      case TechType.reactjs.toLowerCase():
        return 'react-original-wordmark';
      case TechType.ruby.toLowerCase():
        return 'ruby-plain-wordmark';
      case TechType.magento.toLowerCase():
        return 'magento-original';
      case TechType.mongo.toLowerCase():
        return 'mongodb-plain-wordmark';
      case TechType.mysql.toLowerCase():
        return 'mysql-plain-wordmark';
      case TechType.nextjs.toLowerCase():
        return 'nextjs-original-wordmark';
      case TechType.nodejs.toLowerCase():
        return 'nodejs-plain';
      case TechType.php.toLowerCase():
        return 'php-plain';
      case TechType.python.toLowerCase():
        return 'python-plain-wordmark';
      case TechType.salesforce.toLowerCase():
        return 'salesforce-plain';
      case TechType.scala.toLowerCase():
        return 'scala-plain-wordmark';
      case TechType.unity.toLowerCase():
        return 'unity-original-wordmark';
      case TechType.vuejs.toLowerCase():
        return 'vuejs-plain-wordmark';
      case TechType.woocommerce.toLowerCase():
        return 'woocommerce-plain-wordmark';
      case TechType.wordpress.toLowerCase():
        return 'wordpress-plain-wordmark';
    }
  }, []);

  const techLogo = techSwitcher(techName);

  const getLogoSize = useCallback((size?: TechLogoSize) => {
    if (size === TechLogoSize.large) return '42px';
    if (size === TechLogoSize.small) return '32px';
    return '38px';
  }, []);

  return techLogo ? (
    <Tooltip placement='top' title={techName}>
      <i
        className={`devicon-${techLogo} colored tech-logo`}
        onClick={onClick}
        style={{ fontSize: getLogoSize(logoSize) }}
      ></i>
    </Tooltip>
  ) : (
    <Tag onClick={onClick} className='technologies-tag'>
      {techName}
    </Tag>
  );
};

export default TechnologiesLogo;

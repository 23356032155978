import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NavBarMenuProps {
  mode: 'horizontal' | 'vertical';
}

const NavBarMenu: React.FC<NavBarMenuProps> = ({ mode }) => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  let currentRoute;
  if (pathname) {
    currentRoute = pathname.split('/')[1];
  }

  return (
    <Menu mode={mode}>
      <Menu.Item key='requestDemo'>
        <Link to='/contact-us' style={{ color: currentRoute === 'contact-us' ? '#28b6e3' : '#4F4F4F' }}>
          {t('requestADemo', { lng: 'en' })}
        </Link>
      </Menu.Item>
      <Menu.Item key='features'>
        <Link to='/features' style={{ color: currentRoute === 'features' ? '#28b6e3' : '#4F4F4F' }}>
          {t('features', { lng: 'en' })}
        </Link>
      </Menu.Item>
      <Menu.Item key='resources'>
        <Link to='/resources' style={{ color: currentRoute === 'resources' ? '#28b6e3' : '#4F4F4F' }}>
          {t('resources', { lng: 'en' })}
        </Link>
      </Menu.Item>
      <Menu.Item key='pricing'>
        <Link to='/pricing' style={{ color: currentRoute === 'pricing' ? '#28b6e3' : '#4F4F4F' }}>
          {t('pricing', { lng: 'en' })}
        </Link>
      </Menu.Item>
      <Menu.Item key='logIn'>
        <Link to='/login' style={{ color: '#4F4F4F' }}>
          {t('logIn', { lng: 'en' })}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default NavBarMenu;

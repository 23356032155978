import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import './css/ImportantCommentIcon.css';

interface ImportantCommentIconProps {
  importantComment?: string;
  size?: number;
}

export const ImportantCommentIcon: React.FC<ImportantCommentIconProps> = ({ importantComment, size }) => {
  return (
    <>
      {importantComment && (
        <Tooltip placement='top' title={importantComment}>
          <ExclamationCircleOutlined style={{ fontSize: `${size ?? 23}px` }} className='importantComment-icon' />
        </Tooltip>
      )}
    </>
  );
};

export default ImportantCommentIcon;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface DateFormatter {
  toLocalShortDate: (date: string) => string;
  toLocalDateTime: (date: string) => string;
  toLocalDay: (date: string) => string;
  toShortLocalDateTime: (date: string) => string;
  capitalizeFirstLetter: (text: string) => string;
}

export const useDateFormatter = (): DateFormatter => {
  const toLocalShortDate = (date?: string): string => {
    if (!date) return '';

    return capitalizeFirstLetter(dayjs(date).local().format('MMM DD YYYY'));
  };

  const toLocalDateTime = (date?: string): string => {
    if (!date) return '';

    return capitalizeFirstLetter(dayjs(date).local().format('MMMM DD YYYY | HH:mm'));
  };

  const toShortLocalDateTime = (date?: string): string => {
    if (!date) return '';

    return capitalizeFirstLetter(dayjs(date).local().format('MMM DD YYYY | HH:mm'));
  };

  const toLocalDay = (date?: string): string => {
    if (!date) return '';

    return capitalizeFirstLetter(dayjs(date).local().format('MMMM DD'));
  };

  const capitalizeFirstLetter = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return { toLocalShortDate, toLocalDateTime, toLocalDay, toShortLocalDateTime, capitalizeFirstLetter };
};

import {
  CandidateLanguage,
  CandidateResume,
  CandidateTechnology,
  CandidateSoftSkills,
  TechnologyFilter,
} from '../Candidates/types';
import { Assignation, Seniority } from '../Common/types';

export enum Status {
  scheduled = 1,
  rescheduled,
  inProcess,
  finished,
  noShow,
  canceled,
}

export enum InterviewType {
  firstMeeting = 1,
  meeting,
  techDemo,
  sync,
  dataRevalidation,
}

export enum Source {
  internal = 1,
  client,
  external,
}

export enum RouteStatus {
  next = 'next',
  past = 'past',
  templates = 'templates',
}

export type InterviewCreate = {
  candidateId: number;
  status: number;
  jobApplicationId: number;
  interviewerUserIds: Array<string>;
  type: number;
  source: number;
  clientInterviewers: string;
  score: number;
  notes: string;
  scheduledAt: string;
};

export type Interview = {
  id: number;
  candidateId: number;
  candidateName?: string;
  jobApplicationId?: number;
  assignations?: Array<Assignation>;
  jobOfferTitle?: string;
  clientInterviewers?: string;
  source: number;
  sourceName?: string;
  status: number;
  statusName?: string;
  type: number;
  typeName?: string;
  score: number;
  notes?: string;
  scheduledAt: string;
  time: string;
  interviewerUserIds: Array<string>;
  interviewerUserNames: Array<string>;
  candidateOwnedByUserId?: string;
  createdAt: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
};

export type InterviewSession = {
  id: number;
  candidateAssignations: Array<Assignation>;
  candidateId: number;
  candidateName?: string;
  candidateBirthDate?: string;
  candidatePicture?: string;
  candidateExpectedSalaryMin?: number;
  candidateExpectedSalaryMax?: number;
  candidateStatus: number;
  candidateCountry?: string;
  candidateCountryName?: string;
  candidateTechnologies: Array<CandidateTechnology>;
  candidateResumes?: Array<CandidateResume>;
  candidateStatusName: string;
  candidateSeniorityName?: string;
  candidateOwnedByUserId?: string;
  candidateYearsOfExperience?: number;
  candidateLanguages: Array<CandidateLanguage>;
  candidateLinkedInProfile?: string;
  candidateOwnedByUserName?: string;
  candidateHighestDegreeLevelName?: string;
  candidateHighestDegreeTitle?: string;
  candidateSoftSkills: CandidateSoftSkills;
  candidateEmail?: string;
  candidateSeniority?: Seniority;
  companyLogo?: string;
  jobApplicationId?: number;
  jobOfferTitle?: string;
  assignations?: Array<Assignation>;
  clientInterviewers?: string;
  source: number;
  sourceName?: string;
  status: number;
  statusName?: string;
  type: number;
  typeName?: string;
  score: number;
  notes?: string;
  interviewNotes?: string;
  scheduledAt: string;
  time: string;
  interviewerUserIds: Array<string>;
  interviewerUserNames: Array<string>;
  createdAt: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
};

export type InterviewListItem = {
  id: number;
  candidateId: number;
  candidateName: string;
  candidateStatusName: string;
  companyLogo?: string;
  jobApplicationId?: number;
  jobOfferTitle?: string;
  clientInterviewers?: string;
  source: number;
  sourceName?: string;
  canceledOrRescheduledCount?: number;
  picture: string;
  technologies: Array<CandidateTechnology>;
  candidateCountry?: string;
  candidateCountryName?: string;
  candidateExpectedSalaryMin: number;
  candidateExpectedSalaryMax: number;
  candidateOwnedByUserName?: string;
  status: number;
  statusName?: string;
  type: number;
  typeName?: string;
  interviewerUserIds: Array<string>;
  interviewerUserNames: Array<string>;
  score: number;
  notes: string;
  scheduledAt: string;
  time: string;
  createdAt: string;
  createdByUserName: string;
  dataQualityValue: number;
  jobOfferBestMatchId?: number;
  jobOfferBestMatchScorePercentage?: number;
};

export type InterviewTechnologyCount = {
  count: number;
  technologyName: string;
  technologyId: number;
};

export type AdvancedSearchFilters = {
  candidateFirstName?: string;
  candidateLastName?: string;
  candidateOwnedByUserIds?: Array<string>;
  interviewerUserIds?: Array<string>;
  mainRolesIds?: Array<number>;
  technologies?: Array<TechnologyFilter>;
  from?: string;
  to?: string;
  source?: number;
  statuses?: Array<number>;
  companyIds?: Array<number>;
  jobOfferId?: number;
  type?: number;
};

export type UpdateInterviewSession = {
  notes: string;
  candidateSoftSkills: CandidateSoftSkills;
  candidateTechnologies?: Array<CandidateTechnology>;
};

export type EndInterviewSession = {
  status: Status;
  score: number;
  notes: string;
  candidateSoftSkills: CandidateSoftSkills;
};

export type Template = {
  id: number;
  name?: string;
  mainRoleId?: number;
  mainRoleName?: string;
  languageId?: string;
  isPublic: boolean;
  templateBody?: string;
  createdAt: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
  pinned: boolean;
};

export type TemplateCreate = {
  name: string;
  mainRoleId: number;
  languageId: string;
  isPublic: boolean;
  templateBody?: string;
};

import React, { ReactNode } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectProps } from 'antd';
import { KeyValuePair } from './types';

export interface SelectWithSearchProps extends SelectProps<string | number | Array<number | string> | undefined> {
  onChange?(value: number | string | Array<number | string> | undefined): void;
  children?: Array<ReactNode> | ReactNode;
  optionValues?: Array<KeyValuePair>;
  readOnly?: boolean;
}

export const SelectWithSearch = React.forwardRef<HTMLSelectElement, SelectWithSearchProps>(
  ({ optionValues, placeholder, value, notFoundContent, allowClear, children, showSearch, onChange, ...rest }, ref) => {
    const { t } = useTranslation();

    const { Option } = Select;

    const handleValue = (
      child?: Array<ReactNode> | ReactNode,
    ): string | number | Array<number | string> | null | undefined => {
      return (Array.isArray(child) && child.length > 0) || (!Array.isArray(child) && child) ? value : undefined;
    };

    return (
      <Select
        ref={ref as any}
        value={handleValue(children)}
        placeholder={placeholder || t('select')}
        className={rest?.readOnly ? 'readOnly' : undefined}
        open={rest?.readOnly ? false : undefined}
        onChange={!rest?.readOnly ? onChange : undefined}
        allowClear={allowClear !== undefined ? allowClear : true}
        showArrow
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => {
          if (option && option.children) {
            if (typeof option.children !== 'string') {
              return option.children.some(e => {
                if (typeof e !== 'string') return false;

                const s = e as string;

                return s.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              });
            }

            const c = option.children as string;
            return c.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        notFoundContent={notFoundContent || t('noDataMessage')}
        onClick={e => e.stopPropagation()}
        {...rest}
      >
        {optionValues
          ? optionValues.map(data => (
              <Option key={data.key} value={data.key}>
                {data.value}
              </Option>
            ))
          : children}
      </Select>
    );
  },
);

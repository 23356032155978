import React, { ReactNode, useEffect, useState } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { Loading } from '.';
import { TableSort, TableSortDirection } from './types';
import { useViewport } from '../../hooks';

interface PaginatedTableProps {
  isLoading: boolean;
  dataSource: Array<any>;
  currentPage: number;
  pageSize: number;
  color?: string;
  columns: ColumnsType<any>;
  totalCount?: number;
  hideOnSinglePage?: boolean;
  scrollY?: number;
  rowSelection?: TableRowSelection<any>;
  showHeader?: boolean;
  showSorterTooltip?: boolean;
  onPageChange: (page: number) => void;
  onSortChange?: (s: TableSort) => void;
  expandedRowRender?: (record: any, index: number, indent: number, expanded: boolean) => ReactNode;
  rowExpandable?: (record: any) => boolean;
  rowClassName?: (record: any) => string;
  onRowClick?: (record: any) => void;
}

export const PaginatedTable: React.FC<PaginatedTableProps> = ({
  isLoading,
  columns,
  dataSource,
  currentPage,
  hideOnSinglePage,
  pageSize,
  totalCount,
  rowSelection,
  showHeader,
  showSorterTooltip,
  onPageChange,
  onSortChange,
  expandedRowRender,
  rowExpandable,
  rowClassName,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useViewport();
  const [distance, setDistance] = useState<number>();

  const tableLoading = {
    spinning: isLoading,
    indicator: <Loading />,
  };

  const sortDirection = (tableSortDirection): TableSortDirection => {
    if (tableSortDirection === 'ascend') return TableSortDirection.asc;
    if (tableSortDirection === 'descend') return TableSortDirection.desc;

    return TableSortDirection.noOrder;
  };

  const handleTableChange = (pagination: TablePaginationConfig, filters, sorter): void => {
    if (currentPage !== pagination.current) {
      onPageChange(pagination.current ?? 1);
    } else {
      onSortChange && onSortChange({ sortBy: sorter.columnKey, sortDirection: sortDirection(sorter.order) });
    }
  };

  useEffect(() => {
    const tableWrapper = document.getElementsByClassName('ant-table-wrapper')[0];
    const headerHeight = (document.getElementsByClassName('ant-table-thead')[0] as HTMLElement)?.offsetHeight || 0;
    const paginationHeight = (document.getElementsByClassName('ant-pagination')[0] as HTMLElement)?.offsetHeight || 10;
    const dist = tableWrapper?.getBoundingClientRect().top;
    setDistance(dist + headerHeight + paginationHeight);
  });

  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            onRowClick && onRowClick(record);
          },
        };
      }}
      loading={tableLoading}
      rowSelection={!isTablet ? rowSelection : undefined}
      tableLayout='fixed'
      showSorterTooltip={showSorterTooltip}
      columns={columns}
      rowKey={record => record.id}
      dataSource={dataSource}
      onChange={handleTableChange}
      pagination={{
        current: currentPage,
        hideOnSinglePage: hideOnSinglePage ?? true,
        showLessItems: true,
        position: ['bottomRight'],
        size: 'small',
        pageSize,
        showSizeChanger: false,
        total: totalCount,
        showTotal: total => `${total} ${t('records')}`,
      }}
      scroll={{ y: distance ? `calc(100vh - ${distance}px)` : undefined, scrollToFirstRowOnChange: true, x: 900 }}
      showHeader={showHeader ?? false}
      expandedRowRender={expandedRowRender}
      expandable={{ rowExpandable: rowExpandable, columnWidth: '5%' }}
      rowClassName={rowClassName}
    />
  );
};

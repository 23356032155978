import { useAuth0 } from './auth/Auth0Provider';

interface GetUserCompanyInfo {
  getUserCompanyId: () => number;
}

export const useGetUserCompanyInfo = (companyId: number | string): GetUserCompanyInfo => {
  const { getCurrentUserCompanyId } = useAuth0();

  const tokenCompanyId = getCurrentUserCompanyId();

  const getUserCompanyId = () => {
    if (tokenCompanyId) return tokenCompanyId;

    return companyId;
  };

  return { getUserCompanyId };
};

import defaultLogo from '../../../img/logo-wshr-180.png';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';

interface TenantIconProps {
  tenant?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const TenantIcon: React.FC<TenantIconProps> = ({ tenant, style, className }) => {
  const { getCurrentUserTenant } = useAuth0();

  const getImageUrl = () => {
    const t = tenant ?? getCurrentUserTenant();

    let v = localStorage.getItem(`${t}-logo-version`);

    if (!v) {
      v = performance.now().toString();
      localStorage.setItem(`${t}-logo-version`, v);
    }

    if (t) {
      return `${process.env.REACT_APP_WEBSTARTED_SERVER_URL}/tenants/${t}/logo?v=${v}`;
    }

    return defaultLogo;
  };

  return (
    <img
      src={getImageUrl()}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = defaultLogo;
      }}
      style={{ ...style, width: style?.width || 'auto', height: style?.height || '100%' }}
      className={className}
      alt='ATS tenant icon'
    />
  );
};

export default TenantIcon;

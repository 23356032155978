import React, { CSSProperties } from 'react';
import logo from '../../img/wshr-skyblue-logo.png';

const wshrSplashStyle: CSSProperties = {
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
};

const overlayStyle: CSSProperties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  zIndex: 2,
};

const logoStyle: CSSProperties = {
  width: '80%',
  height: '80%',
  borderRadius: '20px',
};

export const WSHRSplash: React.FC = () => {
  return (
    <div style={overlayStyle}>
      <div style={wshrSplashStyle}>
        <img src={logo} alt='img' style={logoStyle} />
      </div>
    </div>
  );
};

import { useBaseAPIClient, APIRequestResult } from './index';

import {
  Assignation,
  AssignationHistory,
  AssignationStatus,
  NewAssignation,
  RequestAssignation,
} from '../../components/Common/types';

type AssignationsAPIClient = {
  assign: (entityId: number, assignment: NewAssignation) => Promise<APIRequestResult<Assignation>>;
  requestAssignation: (entityId: number, assignment: RequestAssignation) => Promise<APIRequestResult<Assignation>>;
  unassign: (entityId: number, assignedUserId: string) => Promise<APIRequestResult>;
  approveRequestedAssignation: (assignationId: number) => Promise<APIRequestResult<Assignation>>;
  rejectRequestedAssignation: (assignationId: number) => Promise<APIRequestResult<Assignation>>;
  getAssignedToUser: (
    assignedUserId: string,
    page: number,
    pageSize: number,
    status?: AssignationStatus,
  ) => Promise<APIRequestResult<Array<Assignation>>>;
  getAssignationsById: (entityId: number) => Promise<APIRequestResult<Array<Assignation>>>;
  getAssignationsHistory: (entityId: number) => Promise<APIRequestResult<Array<AssignationHistory>>>;
  getAssignations: (
    page: number,
    pageSize: number,
    status?: AssignationStatus,
  ) => Promise<APIRequestResult<Array<Assignation>>>;
  countAssignedToUser: (assignedUserId: string, status?: AssignationStatus) => Promise<APIRequestResult<number>>;
  countEntityAssignations: (entityId: number) => Promise<APIRequestResult<number>>;
  countAssignations: (status?: AssignationStatus) => Promise<APIRequestResult<number>>;
};

export const useAssignationsAPIClient = (baseUrl: string, origin?: string): AssignationsAPIClient => {
  const { putRequest, postRequest, getRequest, buildPaginatedUrl } = useBaseAPIClient({ origin });

  const assign = (entityId: number, assignment: NewAssignation): Promise<APIRequestResult<Assignation>> =>
    postRequest(`${baseUrl}/${entityId}/assign`, assignment);

  baseUrl = baseUrl.toLowerCase() === 'joboffers' ? 'job-offers' : baseUrl;

  const requestAssignation = (
    entityId: number,
    assignment: RequestAssignation,
  ): Promise<APIRequestResult<Assignation>> => postRequest(`${baseUrl}/${entityId}/assignations/request`, assignment);

  const approveRequestedAssignation = (assignationId: number): Promise<APIRequestResult<Assignation>> =>
    putRequest(`${baseUrl}/assignations/approve/${assignationId}`);

  const rejectRequestedAssignation = (assignationId: number): Promise<APIRequestResult<Assignation>> =>
    putRequest(`${baseUrl}/assignations/reject/${assignationId}`);

  const getAssignedToUser = (
    assignedUserId: string,
    page: number,
    pageSize = 30,
    status?: AssignationStatus,
  ): Promise<APIRequestResult<Array<Assignation>>> => {
    let url = buildPaginatedUrl(`${baseUrl}/userid/${assignedUserId}/assignations`, page, pageSize);

    if (status) {
      url = `${url}&status=${encodeURIComponent(status)}`;
    }

    return getRequest<Array<Assignation>>(url);
  };

  const getAssignationsHistory = (entityId: number): Promise<APIRequestResult<Array<AssignationHistory>>> =>
    getRequest<Array<AssignationHistory>>(`${baseUrl}/${entityId}/assignations/history`);

  const getAssignationsById = (entityId: number): Promise<APIRequestResult<Array<Assignation>>> =>
    getRequest<Array<Assignation>>(`${baseUrl}/${entityId}/assignations`);

  const getAssignations = (
    page: number,
    pageSize = 30,
    status?: AssignationStatus,
  ): Promise<APIRequestResult<Array<Assignation>>> => {
    let url = buildPaginatedUrl(`${baseUrl}/assignations`, page, pageSize);

    if (status) {
      url = `${url}&status=${encodeURIComponent(status)}`;
    }

    return getRequest<Array<Assignation>>(url);
  };

  const countAssignedToUser = (
    assignedUserId: string,
    status?: AssignationStatus,
  ): Promise<APIRequestResult<number>> => {
    let url = `${baseUrl}/userid/${assignedUserId}/assignations/count`;

    if (status) {
      url = `${url}?status=${encodeURIComponent(status)}`;
    }

    return getRequest<number>(url);
  };

  const countEntityAssignations = (entityId: number): Promise<APIRequestResult<number>> =>
    getRequest<number>(`${baseUrl}/${entityId}/assignations/count`);

  const countAssignations = (status?: AssignationStatus): Promise<APIRequestResult<number>> => {
    let url = `${baseUrl}/assignations/count`;

    if (status) {
      url = `${url}?status=${encodeURIComponent(status)}`;
    }

    return getRequest<number>(url);
  };

  const unassign = (entityId: number, assignedUserId: string): Promise<APIRequestResult> =>
    postRequest(`${baseUrl}/${entityId}/unassign`, { assignedUserId });

  return {
    assign,
    requestAssignation,
    getAssignationsHistory,
    getAssignedToUser,
    getAssignationsById,
    getAssignations,
    countAssignedToUser,
    countEntityAssignations,
    countAssignations,
    unassign,
    approveRequestedAssignation,
    rejectRequestedAssignation,
  };
};

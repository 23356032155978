import { useBaseAPIClient, APIRequestResult } from './index';
import { EntityStatusHistory } from '../../components/Common/types';

type StatusHistoryAPIClient = {
  getStatusHistory: (id: number) => Promise<APIRequestResult<Array<EntityStatusHistory>>>;
};

export const useStatusHistoryAPIClient = (baseUrl: string, origin?: string): StatusHistoryAPIClient => {
  const { getRequest } = useBaseAPIClient({ origin });

  const getStatusHistory = (id: number): Promise<APIRequestResult<Array<EntityStatusHistory>>> =>
    getRequest<Array<EntityStatusHistory>>(`${baseUrl}/${id}/status/history`);

  return {
    getStatusHistory,
  };
};

import { useState } from 'react';
import { useLoggly } from 'react-loggly-jslogger';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (s: T) => void] {
  const { error } = useLoggly();

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      error(err);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      error(err);
    }
  };

  return [storedValue, setValue];
}

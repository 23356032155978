import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { APIRequestResult } from './api/BaseAPIClient';
import { useErrorMessageHandler } from '.';

export enum ActionType {
  accept = 'accept',
  reject = 'reject',
  delete = 'delete',
}

interface UseOnItemActionType {
  (
    action: ActionType,
    setIsLoading: (value: boolean) => void,
    apiCallback: (id: number) => Promise<APIRequestResult<any>>,
    afterApiCall?: () => void,
  ): OnItemAction;
}

interface OnItemAction {
  (itemId: number, label?: string): void;
}

export const useOnItemAction: UseOnItemActionType = (action, setIsLoading, apiCallback, afterApiCall) => {
  const { confirm } = Modal;
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();

  const title = () => {
    switch (action) {
      case ActionType.accept:
        return t('confirmAccept');
      case ActionType.reject:
        return t('confirmReject');
      case ActionType.delete:
        return t('confirmDelete');
      default:
        return 'empty title';
    }
  };

  const content = () => {
    switch (action) {
      case ActionType.accept:
        return t('confirmAcceptMessage');
      case ActionType.reject:
        return t('confirmRejectMessage');
      case ActionType.delete:
        return t('confirmDeleteMessage');
      default:
        return 'empty message';
    }
  };

  const onItemAction: OnItemAction = (itemId, label) => {
    confirm({
      title: title(),
      content: `${content()} ${label}?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        onConfirm(itemId);
      },
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
    });
  };

  const onConfirm = async (itemId: number) => {
    setIsLoading(true);

    const res = await apiCallback(itemId);

    if (res.hasError) {
      errorMessageHandler(res);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    if (afterApiCall) {
      afterApiCall();
    }
  };

  return onItemAction;
};

import { KeyValuePair, TableSort } from '../../components/Common/types';
import {
  AdvancedSearchFilters,
  ProspectClientMeeting,
  ProspectClientMeetingCreate,
  ProspectClientMeetingListItem,
  ProspectClientMeetingUpdate,
} from '../../components/ProspectClients/Meetings/types';
import { PageDefaultValues } from '../../constants/DefaultTheme';
import { APIRequestResult, useBaseAPIClient } from './BaseAPIClient';

type ProspectClientsMeetingsAPIClient = {
  get: (id: number) => Promise<APIRequestResult<ProspectClientMeeting>>;
  create: (client: ProspectClientMeetingCreate) => Promise<APIRequestResult<ProspectClientMeeting>>;
  update: (id: number, client: ProspectClientMeetingUpdate) => Promise<APIRequestResult<ProspectClientMeeting>>;
  remove: (id: number) => Promise<APIRequestResult>;
  list: (
    prospectClientId: number,
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (id: number, statusId: number) => Promise<APIRequestResult>;
  getNextList: (
    page: number,
    pageSize: number,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  countNext: (filter?: string) => Promise<APIRequestResult<number>>;
  getPastList: (
    page: number,
    pageSize: number,
    filter?: string,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  countPast: (filter?: string) => Promise<APIRequestResult<number>>;
  searchPast: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  searchNext: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  searchPastCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  searchNextCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  countByProspectClient: (prospectClientId: number) => Promise<APIRequestResult<number>>;
  getListByProspectClient: (
    prospectClientId: number,
    page: number,
    pageSize: number,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
  countByProspectCompany: (prospectCompanyId: number) => Promise<APIRequestResult<number>>;
  getListByProspectCompany: (
    prospectCompanyId: number,
    page: number,
    pageSize: number,
  ) => Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>>;
};

export const useProspectClientMeetingsAPIClient = (origin?: string): ProspectClientsMeetingsAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    getFormatedFromDate,
    getFormatedToDate,
  } = useBaseAPIClient({ origin });

  const formatAdvancedSearchFilters = (advancedSearchFilters: AdvancedSearchFilters) => {
    const formatedAdvancedSearchFilters = { ...advancedSearchFilters };

    formatedAdvancedSearchFilters.from = getFormatedFromDate(advancedSearchFilters.from);
    formatedAdvancedSearchFilters.to = getFormatedToDate(advancedSearchFilters.to);

    return formatedAdvancedSearchFilters;
  };

  const list = (
    prospectClientId: number,
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    let url = buildPaginatedUrl(`prospect-clients/meetings/${prospectClientId}`, page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const get = (id: number): Promise<APIRequestResult<ProspectClientMeeting>> => {
    return getRequest<ProspectClientMeeting>(`prospect-clients/meetings/${id}`);
  };

  const create = (meeting: ProspectClientMeetingCreate): Promise<APIRequestResult<ProspectClientMeeting>> => {
    return postRequest<ProspectClientMeeting>('prospect-clients/meetings', meeting);
  };

  const remove = (id: number): Promise<APIRequestResult> => {
    return deleteRequest(`prospect-clients/meetings/${id}`);
  };

  const update = (
    id: number,
    meeting: ProspectClientMeetingUpdate,
  ): Promise<APIRequestResult<ProspectClientMeeting>> => {
    return putRequest<ProspectClientMeeting>(`prospect-clients/meetings/${id}`, meeting);
  };

  const updateStatus = (id: number, statusId: number): Promise<APIRequestResult> => {
    return putRequest(`prospect-clients/meetings/${id}/status/${statusId}`);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>('prospect-clients/meetings/statuses');
  };

  const getNextList = (
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    let url = buildPaginatedUrl('prospect-clients/meetings/next', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const countNext = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-clients/meetings/next/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const getPastList = (
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
    filter?: string,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    let url = buildPaginatedUrl('prospect-clients/meetings/past', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    return postRequest<Array<ProspectClientMeetingListItem>>(
      buildPaginatedUrl('prospect-clients/meetings/search/', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchPast = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    return postRequest<Array<ProspectClientMeetingListItem>>(
      buildPaginatedUrl('prospect-clients/meetings/search/past', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchNext = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    return postRequest<Array<ProspectClientMeetingListItem>>(
      buildPaginatedUrl('prospect-clients/meetings/search/next', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>('prospect-clients/meetings/search/count', formatAdvancedSearchFilters(advancedSearchFilters));

  const countPast = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'prospect-clients/meetings/past/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const searchPastCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>(
      'prospect-clients/meetings/search/past/count',
      formatAdvancedSearchFilters(advancedSearchFilters),
    );

  const searchNextCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> =>
    postRequest<number>(
      'prospect-clients/meetings/search/next/count',
      formatAdvancedSearchFilters(advancedSearchFilters),
    );

  const countByProspectClient = (prospectClientId: number): Promise<APIRequestResult<number>> => {
    return getRequest(`prospect-clients/meetings/prospect-client/${prospectClientId}/count`);
  };

  const countByProspectCompany = (prospectCompanyId: number): Promise<APIRequestResult<number>> => {
    return getRequest(`prospect-clients/meetings/prospect-company/${prospectCompanyId}/count`);
  };

  const getListByProspectClient = (
    prospectClientId: number,
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    const url = buildPaginatedUrl(`prospect-clients/meetings/prospect-client/${prospectClientId}`, page, pageSize);

    return getRequest(url);
  };

  const getListByProspectCompany = (
    prospectCompanyId: number,
    page: number,
    pageSize = PageDefaultValues.defaultPageValue,
  ): Promise<APIRequestResult<Array<ProspectClientMeetingListItem>>> => {
    const url = buildPaginatedUrl(`prospect-clients/meetings/prospect-company/${prospectCompanyId}`, page, pageSize);

    return getRequest(url);
  };

  return {
    get,
    create,
    remove,
    update,
    getNextList,
    countNext,
    getPastList,
    countPast,
    searchPast,
    searchNext,
    searchPastCount,
    searchNextCount,
    searchCount,
    updateStatus,
    getStatuses,
    list,
    search,
    countByProspectClient,
    countByProspectCompany,
    getListByProspectClient,
    getListByProspectCompany,
  };
};
